import { AppDispatch } from '@src/store/store'
import { coursesApi } from '@src/api/courses-api'
import {
  setCoursesList, setCoursesMeta, setCoursesTargets, setIsLoading,
} from '@src/store/ducks/courses/reducer'

export const getCourses = (page: number, target?: string, type?: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  try {
    const result = await coursesApi.getCourses(page, target, type)
    dispatch(setCoursesList(result.items))
    dispatch(setCoursesTargets(result.targets))
    dispatch(setCoursesMeta(result.meta))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}
