import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import cn from 'classnames'

import { ReactComponent as Arrow } from '@src/assets/arrow.svg'
import {
  setIsOpenRightSitebarMobile,
  setTypeRightSitebar,
  TypeRightSitebarEnum,
} from '@src/store/ducks/app/reducer'
import { AppDispatch } from '@src/store/store'
import { Preloader } from '@src/components/common/Preloader/Preloader'
import {
  selectChatDialogs,
  selectChatIsLoading,
  selectChatOnlineUsers,
} from '@src/store/ducks/chat/selectors'
import { getInitials } from '@src/lib/getInitials'
import { setDialogId } from '@src/store/ducks/chat/reducer'
import style from './Dialogs.module.scss'

export const Dialogs = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(selectChatIsLoading)
  const dialogs = useSelector(selectChatDialogs)
  const onlineUsers = useSelector(selectChatOnlineUsers)

  const handleDialog = (id: number) => {
    dispatch(setDialogId(id))
    dispatch(setTypeRightSitebar(TypeRightSitebarEnum.CHAT))
  }

  const handleClose = () => {
    dispatch(setTypeRightSitebar(TypeRightSitebarEnum.CURATOR))
    dispatch(setIsOpenRightSitebarMobile(false))
  }

  return (
    <div className={style.container}>
      <button
        type="button"
        className="right_sitebar_back"
        onClick={handleClose}
      >
        <Arrow />
        {t('dialogs.title')}
      </button>
      <Preloader loading={isLoading}>
        <div className={style.dialogs}>
          {dialogs.map((item) => (
            <button type="button" className={style.dialogs_item} key={item.id} onClick={() => handleDialog(item.id)}>
              <div
                className={cn(
                  style.dialogs_item_photo,
                  onlineUsers.some((id) => id === item.userIdTo) && style.dialogs_item_photo_online,
                )}
              >
                {item.photo ? (
                  <img className={style.dialogs_item_photo_item} src={item.photo} alt={item.name} />
                ) : (
                  <div className={style.dialogs_item_photo_item}>{getInitials(item.name)}</div>
                )}
              </div>
              <div className={style.dialogs_item_content}>
                <div className={style.dialogs_item_name}>{item.name}</div>
                <div className={style.dialogs_item_message}>{item.lastMessage}</div>
              </div>
              <div>
                {item.lastMessageTime && (
                  <div className={style.dialogs_item_date}>{moment(item.lastMessageTime).format('DD.MM')}</div>
                )}
                {!!item.notReadCount && (
                  <div className={style.dialogs_item_notReadCount}>{item.notReadCount}</div>
                )}
              </div>
            </button>
          ))}
        </div>
      </Preloader>
    </div>
  )
}
