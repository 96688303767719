import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FullCalendar from '@fullcalendar/react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { CloseOutlined } from '@ant-design/icons'
import { EventDropArg } from '@fullcalendar/common'
import ruLocale from '@fullcalendar/core/locales/ru'
import frLocale from '@fullcalendar/core/locales/fr'
import { useParams } from 'react-router-dom'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { DateClickArg, EventResizeDoneArg } from '@fullcalendar/interaction'
import { Button, Popconfirm } from 'antd'

import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { reset } from '@src/store/ducks/teachers/reducer'
import {
  createTeacherSchedule,
  deleteTeacherSchedule,
  getTeacherSchedule, scheduleTeacherExport,
  updateTeacherSchedule,
} from '@src/store/ducks/teachers/thunks'
import {
  selectTeachersIsLoadingExport,
  selectTeachersName,
  selectTeachersSchedule,
} from '@src/store/ducks/teachers/selectors'
import style from './TeachersSchedule.module.scss'

export const TeachersSchedule = () => {
  const params = useParams<{ id: string }>()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const events = useSelector(selectTeachersSchedule)
  const name = useSelector(selectTeachersName)
  const isLoadingExport = useSelector(selectTeachersIsLoadingExport)

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { url: '/', title: t('controlPanel.title') },
      { url: '/teachers', title: t('teachers.title') },
      { title: t('teachersSchedule.title') },
    ]))
  }, [dispatch, t])

  useEffect(() => {
    dispatch(getTeacherSchedule(+params.id))

    return () => { dispatch(reset()) }
  }, [dispatch, params.id])

  const dateClick = useCallback((dateArg: DateClickArg) => {
    dispatch(createTeacherSchedule(
      +params.id, moment(dateArg.date).utc().format(), moment(dateArg.date).add(30, 'minute').utc().format(),
    ))
  }, [dispatch, params.id])

  const eventResize = useCallback((event: EventResizeDoneArg) => {
    dispatch(updateTeacherSchedule(
      +params.id,
      event.event.extendedProps.id,
      moment(event.event.start).utc().format(),
      moment(event.event.end).utc().format(),
    ))
  }, [dispatch, params.id])

  const eventDrop = useCallback((event: EventDropArg) => {
    dispatch(updateTeacherSchedule(
      +params.id,
      event.event.extendedProps.id,
      moment(event.event.start).utc().format(),
      moment(event.event.end).utc().format(),
    ))
  }, [dispatch, params.id])

  return (
    <div>
      <h1 className="page_title">
        {t('teachersSchedule.title')}
        {' '}
        {name}
      </h1>

      <div className={style.export}>
        <Button
          onClick={() => {
            dispatch(scheduleTeacherExport(+params.id))
          }}
          type="primary"
          loading={isLoadingExport}
        >
          Экспорт
        </Button>
      </div>

      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        events={events}
        allDaySlot={false}
        height="auto"
        locales={[ruLocale, frLocale]}
        locale={i18n.language}
        editable
        eventOverlap={false}
        eventClick={(e) => console.log('click', e)}
        eventResize={eventResize}
        eventDrop={eventDrop}
        dateClick={dateClick}
        eventContent={(content) => (
          <div className={style.schedule}>
            {content.timeText}
            <div>-----</div>
            {content.event.extendedProps.lessons?.map((lesson: any) => (
              <div style={{ backgroundColor: 'cadetblue' }}>
                {moment(lesson.start).format('HH:mm')}
                {' - '}
                {moment(lesson.end).format('HH:mm')}
                {' - '}
                {`${lesson.type} lesson`}
              </div>
            ))}
            <Popconfirm
              title={t('teachersSchedule.delete.title')}
              onConfirm={() => { dispatch(deleteTeacherSchedule(content.event.extendedProps.id)) }}
              okText={t('teachersSchedule.delete.yes')}
              cancelText={t('teachersSchedule.delete.no')}
              placement="bottom"
            >
              <button type="button" className={style.schedule_delete}>
                <CloseOutlined />
              </button>
            </Popconfirm>
          </div>
        )}
      />
    </div>
  )
}
