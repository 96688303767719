import io from 'socket.io-client'
import { keysToCamelCase } from '@src/lib/utils'

export enum SubscribePresenceCallback {
  JOINING = 1,
  SUBSCRIBED = 2,
  LAVING = 3
}

class SocketApi {
  // eslint-disable-next-line no-undef
  private readonly socket: SocketIOClient.Socket

  constructor() {
    console.log('start')
    this.socket = io(process.env.REACT_APP_SOCKET_URL, {
      path: process.env.REACT_APP_SOCKET_PATH,
    })
  }

  public disconnect() {
    this.socket.disconnect()
  }

  public connect() {
    console.log('connect')
    this.socket.connect()
  }

  public subscribePrivate(channel: string) {
    this.socket.emit('subscribe', {
      channel: `private-${channel}`,
    })

    return () => this.unsubscribePrivate(channel)
  }

  public subscribePresence(channel: string, callback: (members: any, type: SubscribePresenceCallback) => void) {
    this.socket.emit('subscribe', {
      channel: `presence-${channel}`,
    })

    const joiningOff = this.on('presence:joining', (members: { userId: number }) => {
      callback(members.userId, SubscribePresenceCallback.JOINING)
    })
    const subscribedOff = this.on('presence:subscribed', (members: Array<{ userId: number }>) => {
      callback(members.map((item) => item.userId), SubscribePresenceCallback.SUBSCRIBED)
    })
    const leavingOff = this.on('presence:leaving', (members: { userId: number }) => {
      callback(members.userId, SubscribePresenceCallback.LAVING)
    })

    return () => {
      joiningOff()
      subscribedOff()
      leavingOff()
      this.unsubscribePrivate(channel)
    }
  }

  private unsubscribePrivate(channel: string) {
    this.socket.emit('unsubscribe', {
      channel: `private-${channel}`,
    })
  }

  public on(event: string, fn: Function) {
    this.socket.on(event, (_: any, e: any) => {
      fn(keysToCamelCase(e))
    })
    return () => this.socket.off(event)
  }
}

export const socketApi = new SocketApi()
