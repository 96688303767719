import { FC } from 'react'
import { Breadcrumb } from 'antd'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import style from './Breadcrumbs.module.scss'

type BreadcrumbsProps = {
    options: Array<{
        url?: string
        title: string
    }>
}

export const Breadcrumbs:FC<BreadcrumbsProps> = ({ options }) => (
  <Breadcrumb separator=">">
    {options.map((item, i) => (
      <Breadcrumb.Item className={cn(style.item, options.length - 1 === i && style.last)} key={item.title}>
        {item.url ? <NavLink to={item.url}>{item.title}</NavLink> : item.title}
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
)
