import { FC, useMemo } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import { useFormikContext } from 'formik'

import './react-phone-input-2.css'
import style from '@src/components/common/FieldFormik/FieldFormik.module.scss'
import { getObjectValue } from '@src/lib/utils'

type PhoneFieldFormikProps = {
  name: string,
  placeholder?: string
  country?: string
  onlyCountries?: string[]
  preferredCountries?: string[]
}

export const PhoneFieldFormik: FC<PhoneFieldFormikProps> = ({
  name, country, onlyCountries, preferredCountries,
}) => {
  const {
    setFieldValue, errors, touched, values,
  } = useFormikContext<any>()
  const value = useMemo(() => getObjectValue(name.split('.'), values), [name, values])
  const error = useMemo(() => getObjectValue(name.split('.'), errors), [name, errors])
  const isTouched = useMemo(() => getObjectValue(name.split('.'), touched), [name, touched])
  const isError = useMemo(() => error && isTouched, [error, isTouched])

  return (
    <div className={style.wrapper}>
      <ReactPhoneInput
        inputClass="ant-input"
        country={country || 'ru'}
        onlyCountries={onlyCountries}
        preferredCountries={preferredCountries}
        onChange={(_, data, event, formattedValue) => {
          setFieldValue(name, formattedValue)
        }}
        value={value}
      />
      {isError && <div className={style.error}>{error}</div>}
    </div>
  )
}
