import moment from 'moment-timezone'
import i18n from 'i18next'
import { saveAs } from 'file-saver'
import { notification } from 'antd'

import { AppDispatch } from '@src/store/store'
import { scheduleApi } from '@src/api/schedule-api'
import { LessonTypeEnum } from '@src/types/lessons'
import { studentsApi } from '@src/api/user/students-api'
import { coursesApi } from '@src/api/courses-api'
import { EventType } from './types'
import {
  addEvent,
  deleteEvent,
  setCalendars,
  setCourses,
  setCreateBackError,
  setEvents,
  setFreeDay,
  setIsLoading,
  setIsLoadingCreateData,
  setIsLoadingDelete,
  setIsLoadingExport,
  setIsLoadingFilters,
  setScheduleFilters,
  setScheduleMeta,
  setTeachers,
  setTeachersFreeTime,
  updateEvent,
} from './reducer'

const mapEvents = (events: EventType[]) => events.map((event) => {
  const result = {
    id: `group${event.id}`,
    start: moment(event.start).format(),
    end: moment(event.end).format(),
    title: event.calendar.course.name,
    extendedProps: event,
  }

  if (event.student) {
    result.id = `individual${event.id}`
  }

  return result
})

export const getSchedule = (
  startDate: string, endDate: string, isPaginate: boolean, page: number | null, filters?: any,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    const result = await scheduleApi.getSchedule(startDate, endDate, isPaginate, page, filters)
    dispatch(setEvents(mapEvents(result.items)))
    dispatch(setScheduleMeta(result.meta))
  } catch (err) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description:
        `${i18n.t('schedule.errors.schedule')} --- ${err?.response?.data?.message ?? err.message}`,
    })
  } finally {
    dispatch(setIsLoading(false))
  }
}

type DeleteLessonArg = (
  id: number,
  type: LessonTypeEnum,
  closeModal: () => void
) => (dispatch: AppDispatch) => Promise<void>
export const deleteLesson: DeleteLessonArg = (id, type, closeModal) => async (dispatch) => {
  try {
    dispatch(setIsLoadingDelete(true))
    await scheduleApi.deleteLesson(id)
    dispatch(deleteEvent({ id, type }))
    closeModal()
  } catch (err) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description:
        `${i18n.t('schedule.errors.delete')} --- ${err?.response?.data?.message ?? err.message}`,
    })
  } finally {
    dispatch(setIsLoadingDelete(false))
  }
}

export const getScheduleCourses = (dayStart: string, dayEnd: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingCreateData(true))
    const result = await scheduleApi.getCourses(dayStart, dayEnd)
    dispatch(setCourses(result))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingCreateData(false))
  }
}

export const getScheduleCalendars = (
  courseId: number, dayStart: string, dayEnd: string,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingCreateData(true))
    const result = await scheduleApi.getCalendars(courseId, dayStart, dayEnd)
    dispatch(setCalendars(result))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingCreateData(false))
  }
}

export const getScheduleTeachers = (
  calendarId: number, dayStart: string, dayEnd: string, lessonId?: number,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingCreateData(true))
    const result = await scheduleApi.getTeachers(calendarId, dayStart, dayEnd, lessonId)
    dispatch(setTeachers(result))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingCreateData(false))
  }
}

export const getScheduleTeachersFreeTime = (
  calendarId: number, teacherId: number, dayStart: string, dayEnd: string, lessonId?: number, studentId?: number,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingCreateData(true))
    const result = await scheduleApi.getTeachersFreeTime(calendarId, teacherId, dayStart, dayEnd, lessonId, studentId)
    dispatch(setTeachersFreeTime(result))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingCreateData(false))
  }
}
export const getScheduleFreeDay = (
  calendarId: number, lessonType: LessonTypeEnum, lessonId: number,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingCreateData(true))
    const result = await scheduleApi.getFreeDay(calendarId, lessonType, lessonId)
    dispatch(setFreeDay(result))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingCreateData(false))
  }
}

export const createGroupLesson = (data: any, closeModal: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setCreateBackError(''))
    dispatch(setIsLoadingCreateData(true))
    const result = await scheduleApi.createGroupLesson(data)
    dispatch(addEvent(mapEvents([result])[0]))
    closeModal()
  } catch (err) {
    console.error(err)
    if (err.response.status === 400) {
      dispatch(setCreateBackError(err.response.data.error))
    }
  } finally {
    dispatch(setIsLoadingCreateData(false))
  }
}

export const updateLesson = (data: any, closeModal: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setCreateBackError(''))
    dispatch(setIsLoadingCreateData(true))
    const result = await scheduleApi.updateLesson(data)
    dispatch(updateEvent(mapEvents([result])[0]))
    closeModal()
  } catch (err) {
    console.error(err)
    if (err.response.status === 400) {
      dispatch(setCreateBackError(err.response.data.error))
    }
  } finally {
    dispatch(setIsLoadingCreateData(false))
  }
}

export const getFilters = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingFilters(true))
    const result = await scheduleApi.getFilters()
    dispatch(setScheduleFilters(result))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingFilters(false))
  }
}

export const getStudentsFilter = (term: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await studentsApi.find(term)
    dispatch(setScheduleFilters({ students: result }))
  } catch (err) {
    console.error(err)
  }
}

export const getCalendarsFilter = (term: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await coursesApi.calendarsFind(term)
    dispatch(setScheduleFilters({ calendars: result }))
  } catch (err) {
    console.error(err)
  }
}

export const scheduleExport = (dayStart: string, dayEnd: string, filters?: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingExport(true))
    const result = await scheduleApi.export(dayStart, dayEnd, filters)
    saveAs(result, `schedule-${moment(dayStart).format('DD.MM.YYYY')}-${moment(dayEnd).format('DD.MM.YYYY')}.xlsx`)
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingExport(false))
  }
}
