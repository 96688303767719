import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authReducer } from '@src/store/ducks/auth/reducer'
import { userReducer } from '@src/store/ducks/user/reducer'
import { appReducer } from '@src/store/ducks/app/reducer'
import { notificationReducer } from '@src/store/ducks/notification/reducer'
import { materialsReducer } from '@src/store/ducks/materials/reducer'
import { curatorReducer } from '@src/store/ducks/curator/reducer'
import { chatReducer } from '@src/store/ducks/chat/reducer'
import { profileReducer } from '@src/store/ducks/profile/reducer'
import { coursesReducer } from '@src/store/ducks/courses/reducer'
import { paymentHistoryReducer } from '@src/store/ducks/paymentHistory/reducer'
import { lessonsReducer } from '@src/store/ducks/lessons/reducer'
import { studentsReducer } from '@src/store/ducks/students/reducer'
import { scheduleReducer } from '@src/store/ducks/schedule/reducer'
import { teachersReducer } from '@src/store/ducks/teachers/reducer'
import { organizersReducer } from '@src/store/ducks/organizers/reducer'
import { secretaryReducer } from '@src/store/ducks/secretary/reducer'
import { invoicesReducer } from '@src/store/ducks/invoices/reducer'

const combinedReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  app: appReducer,
  notification: notificationReducer,
  lessons: lessonsReducer,
  materials: materialsReducer,
  curator: curatorReducer,
  chat: chatReducer,
  profile: profileReducer,
  courses: coursesReducer,
  paymentHistory: paymentHistoryReducer,
  students: studentsReducer,
  schedule: scheduleReducer,
  teachers: teachersReducer,
  secretary: secretaryReducer,
  organizers: organizersReducer,
  invoices: invoicesReducer,
})

const rootReducer = (state: any, action: any) => {
  let tmpState = state
  if (action.type === 'auth/logoutAC') {
    tmpState = undefined
  }
  return combinedReducer(tmpState, action)
}

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
