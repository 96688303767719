import { FC } from 'react'
import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import { useCountries } from '@src/hooks/useCountries'

type CountrySelectProps = {
  name: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
}

export const CountrySelect: FC<CountrySelectProps> = (props) => {
  const selectOptions = useCountries()

  return (
    <SelectFormik
      {...props}
      options={selectOptions.map((item) => ({ value: item.alpha3, label: item.name }))}
      showSearch
    />
  )
}
