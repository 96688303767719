import { useEffect, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { JoinContainer } from '@src/components/JoinContainer/JoinContainer'
import { AppDispatch } from '@src/store/store'
import { selectAuthBackError, selectAuthIsLoading, selectResetEmailSuccess } from '@src/store/ducks/auth/selectors'
import { resetState } from '@src/store/ducks/auth/reducer'
import { sendEmail } from '@src/store/ducks/auth/thunks'
import style from './ForgotPassword.module.scss'

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(selectAuthIsLoading)
  const resetEmailSuccess = useSelector(selectResetEmailSuccess)
  const backError = useSelector(selectAuthBackError)

  useEffect(() => () => {
    dispatch(resetState())
  }, [dispatch])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      email: Yup.string()
        .email(t('form.errors.email'))
        .required(t('form.errors.required')),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    email: '',
  }), [])

  if (resetEmailSuccess) {
    return (
      <JoinContainer title={t('forgotPassword.email.title')}>
        <div className={style.text}>
          {t('forgotPassword.email.success')}
        </div>
        <NavLink to="/login">
          <Button type="primary" className={cn('login-form-button', style.btn)}>
            {t('forgotPassword.email.successBtn')}
          </Button>
        </NavLink>

      </JoinContainer>
    )
  }

  return (
    <JoinContainer title={t('forgotPassword.email.title')}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({ email }) => dispatch(sendEmail(email))}
      >
        {() => (
          <Form>
            <FieldFormik Prefix={UserOutlined} name="email" placeholder={t('forgotPassword.email.email')} />

            {backError && <div className={style.error}>{backError}</div>}

            <Button type="primary" htmlType="submit" className={cn('login-form-button', style.btn)} loading={isLoading}>
              {t('forgotPassword.email.submitBtn')}
            </Button>

            <div className={style.login}>
              <NavLink to="/login">{t('forgotPassword.email.loginLink')}</NavLink>
            </div>
          </Form>
        )}
      </Formik>
    </JoinContainer>
  )
}
