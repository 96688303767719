import { AppDispatch, RootState } from '@src/store/store'

import { notificationApi } from '@src/api/notification-api'
import {
  setCount, setIsLoadingFetch, setIsLoadingMore,
  addNotifications, setSaveCount, setIsError,
  addLoadingDelete, removeLoadingDelete, removeNotification,
} from '@src/store/ducks/notification/reducer'

export const getNotificationCount = () => async (dispatch: AppDispatch) => {
  try {
    const result = await notificationApi.getNotificationCount()
    dispatch(setCount(result))
  } catch (err) {
    console.error(err)
  }
}

export const getNotifications = () => async (dispatch: AppDispatch) => {
  dispatch(setIsError(false))
  dispatch(setIsLoadingFetch(true))
  dispatch(setSaveCount())
  try {
    const result = await notificationApi.getNotifications()
    dispatch(addNotifications(result))
  } catch (err) {
    console.error(err)
    dispatch(setIsError(true))
  } finally {
    dispatch(setIsLoadingFetch(false))
  }
}

export const moreNotifications = (lastId: number) => async (dispatch: AppDispatch) => {
  dispatch(setIsError(false))
  dispatch(setIsLoadingMore(true))
  try {
    const result = await notificationApi.getNotifications(lastId)
    dispatch(addNotifications(result))
  } catch (err) {
    console.error(err)
    dispatch(setIsError(true))
  } finally {
    dispatch(setIsLoadingMore(false))
  }
}

export const deleteNotification = (id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(addLoadingDelete(id))

  try {
    await notificationApi.deleteNotification(id)
    const { notification } = getState()
    if (notification.loadingDelete.length === 1) await dispatch(getNotificationCount())
    dispatch(removeNotification(id))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(removeLoadingDelete(id))
  }
}
