import { DialogsType, MessageType } from '@src/store/ducks/chat/types'
import { api } from './api'

export const chatApi = {
  getDialogs() {
    return api.get<DialogsType[]>('/v1/messages/dialogs')
  },
  getMessages(dialogId: number, lastId?: number) {
    return api.get<{ data: MessageType[], isLast: boolean }>('/v1/messages/dialog-messages', { dialogId, lastId })
  },
  sendMessage(message: string, dialogId: number, parentId?: number, attachments?: string[]) {
    return api.post('/v1/messages/send', {
      message, dialogId, parentId, attachments,
    })
  },
  setRead(data: number[], dialogId: number) {
    return api.post('/v1/messages/set-read', { data, dialogId })
  },
  storeFile(form: any) {
    return api.post<string>('/v1/messages/store-file', form)
  },
}
