import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import FullCalendar from '@fullcalendar/react'
import ruLocale from '@fullcalendar/core/locales/ru'
import frLocale from '@fullcalendar/core/locales/fr'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import style from '@src/pages/Schedule/Schedule.module.scss'
import { ReactComponent as GroupIcon } from '@src/assets/group.svg'
import { ReactComponent as UserIcon } from '@src/assets/user.svg'
import { ReactComponent as TeacherIcon } from '@src/assets/teacher.svg'
import { selectScheduleEvents } from '@src/store/ducks/schedule/selectors'
import { EventClickArg } from '@fullcalendar/common'
import { ScheduleModalEnum } from '@src/types/schedule'

type ScheduleCalendarProps = {
  dates: {
    start: string
    end: string
  }
  setDates: (data: { start: string, end: string }) => void
  dateClick: (e: DateClickArg) => void
  setModalEvent: (data: any) => void
  setModalType: (type: ScheduleModalEnum) => void
  setIsOpenModal: (state: boolean) => void
}

export const ScheduleCalendar: FC<ScheduleCalendarProps> = ({
  dates, setDates, dateClick, setModalEvent, setModalType, setIsOpenModal,
}) => {
  const { t, i18n } = useTranslation()
  const events = useSelector(selectScheduleEvents)
  const history = useHistory()

  const eventClick = useCallback((e: EventClickArg) => {
    const target = e.jsEvent.target as Element
    const eventLink = target.closest('.event_link') as HTMLElement | undefined
    if (eventLink) {
      const { url } = eventLink.dataset
      if (url && url[0] === '/') {
        history.push(url)
      } else if (url) {
        window.open(url)
      }
    } else {
      setModalEvent(e.event)
      setModalType(ScheduleModalEnum.INFO)
      setIsOpenModal(true)
    }
  }, [history, setIsOpenModal, setModalEvent, setModalType])

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="timeGridFourDay"
      allDaySlot={false}
      height="auto"
      locales={[ruLocale, frLocale]}
      locale={i18n.language}
      eventSources={[
        {
          events,
        },
        {
          events(fetchInfo, successCallback) {
            if (dates.start !== fetchInfo.startStr || dates.end !== fetchInfo.endStr) {
              setDates({ start: fetchInfo.startStr, end: fetchInfo.endStr })
            }
            successCallback([])
          },
        },
      ]}
      views={{
        timeGridFourDay: {
          type: 'timeGrid',
          duration: { days: 4 },
          buttonText: '4 day',
        },
      }}
      headerToolbar={{
        left: 'today prev,next',
        center: 'title',
        right: 'timeGridDay,timeGridFourDay,timeGridWeek',
      }}
      eventContent={(content) => (
        <div
          className={style.event}
          style={{ background: content.event.extendedProps.calendar.course.category.color }}
        >
          <div>
            {`${moment(content.event.extendedProps.start).format('HH:mm')} -
              ${moment(content.event.extendedProps.end).format('HH:mm')}`}
          </div>
          <div
            className={cn(style.event_title, 'event_link')}
            data-url={`${process.env.REACT_APP_SITE_URL}/courses/${content.event.extendedProps.calendar.course.slug}`}
          >
            {content.event.title}
          </div>
          <div
            className={cn(style.event_item, 'event_link')}
            data-url={`${process.env.REACT_APP_SITE_URL}/courses/${content.event.extendedProps.calendar.course.slug}`}
          >
            <GroupIcon />
            <span>
              {t('schedule.flow')}
              {' '}
              {content.event.extendedProps.calendar.title || content.event.extendedProps.calendar.id}
            </span>
          </div>
          {content.event.extendedProps.student && (
          <div
            className={cn(style.event_item, 'event_link')}
            data-url={`/profile/${content.event.extendedProps.student.userId}`}
          >
            <UserIcon />
            <div>
              <span>
                {`${content.event.extendedProps.student.surname} ${content.event.extendedProps.student.name}`}
              </span>
            </div>
          </div>
          )}
          <div
            className={cn(style.event_item, 'event_link')}
            data-url={`/profile/${content.event.extendedProps.teacher.userId}`}
          >
            <TeacherIcon />
            <span>
              {`${content.event.extendedProps.teacher.surname} ${content.event.extendedProps.teacher.name}`}
            </span>
          </div>
        </div>
      )}
      eventClick={eventClick}
      dateClick={dateClick}
    />
  )
}
