import { RootState } from '@src/store/store'
import { AppStateType, initialState } from './reducer'

export const selectApp = (state: RootState): AppStateType => state.app || initialState

export const selectAuthIsInitialized = (state: RootState): boolean => selectApp(state).isInitialized
export const selectAppBreadcrumbs = (state: RootState) => selectApp(state).breadcrumbs
export const selectAppTypeRightSitebar = (state: RootState) => selectApp(state).typeRightSitebar
export const selectAppIsOpenLeftSitebarMobile = (state: RootState) => selectApp(state).isOpenLeftSitebarMobile
export const selectAppIsOpenRightSitebarMobile = (state: RootState) => selectApp(state).isOpenRightSitebarMobile
