import { AppDispatch } from '@src/store/store'
import { setCourses } from '@src/store/ducks/curator/reducer'
import { curatorApi } from '@src/api/curator-api'

export const getCourses = () => async (dispatch: AppDispatch) => {
  try {
    const result = await curatorApi.getCourses()
    dispatch(setCourses(result))
  } catch (err) {
    console.error(err)
  }
}
