import moment from 'moment-timezone'

import { api, GetItemsType } from '@src/api/api'
import { formatMinute } from '@src/lib/time'
import {
  EventType,
  ScheduleCalendarTeachers, ScheduleCalendarTeachersFreeTime,
  ScheduleCalendarType,
  ScheduleCourseType,
} from '@src/store/ducks/schedule/types'
import { LessonTypeEnum } from '@src/types/lessons'
import { ScheduleFiltersType } from '@src/types/schedule'

export type CreateGroupLessonReq = {
  teacherId: string
  calendarId: string
  start: string
  title: string
  titleFr: string
  link?: string
}

export const scheduleApi = {
  getSchedule(dayStart: string, dayEnd: string, isPaginate: boolean, page: number | null, filters?: any) {
    return api.get<GetItemsType<EventType>>('/v1/schedule', {
      dayStart, dayEnd, isPaginate, page, filters,
    })
  },
  deleteLesson(lessonId: number) {
    return api.del('/v1/schedule/delete', { lessonId })
  },
  getCourses(dayStart: string, dayEnd: string) {
    return api.get<ScheduleCourseType[]>('/v1/schedule/courses', { dayStart, dayEnd })
  },
  getCalendars(courseId: number, dayStart: string, dayEnd: string) {
    return api.get<ScheduleCalendarType[]>('/v1/calendars/on-date', { courseId, dayStart, dayEnd })
  },
  getTeachers(calendarId: number, dayStart: string, dayEnd: string, lessonId?: number) {
    return api.get<ScheduleCalendarTeachers[]>('/v1/teacher/calendar-teacher', {
      calendarId, dayStart, dayEnd, lessonId,
    })
  },
  getTeachersFreeTime(
    calendarId: number, teacherId: number, dayStart: string, dayEnd: string, lessonId?: number, studentId?: number,
  ) {
    return api.get<ScheduleCalendarTeachersFreeTime[]>('/v1/teacher/free-time-period', {
      calendarId, teacherId, dayStart, dayEnd, lessonId, studentId,
    })
  },
  createGroupLesson(data: CreateGroupLessonReq) {
    return api.post<EventType>('/v1/lessons/create-group-lesson', data)
  },
  getFreeDay(calendarId: number, lessonType: LessonTypeEnum, lessonId: number) {
    return api.get<Array<string>>('/v1/calendars/free-schedule', {
      calendarId, lessonType, lessonId, timezone: formatMinute(moment.tz(moment.tz.guess(true)).format('Z')),
    })
  },
  updateLesson(data: any) {
    return api.put<EventType>('/v1/lessons/update', data)
  },
  getFilters() {
    return api.get<ScheduleFiltersType>('/v1/schedule/filters')
  },
  export(dayStart: string, dayEnd: string, filters?: any) {
    return api.get('/v1/export/schedule', { dayStart, dayEnd, filters }, {}, 'blob')
  },
}
