export enum InvoicesTypeEnum {
  PROFORMA = 1,
  PREPAYMENT = 2,
  CHECK = 3,
  CREDIT = 4
}

export enum InvoiceStatusEnum {
  PAID = 1,
  PENDING = 2,
  EXPIRED = 3,
  ATTEMPTSLIMIT = 4
}
