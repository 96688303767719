import { useTranslation } from 'react-i18next'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Popover, Table } from 'antd'
import { NavLink } from 'react-router-dom'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment-timezone'
import cn from 'classnames'
import { ScheduleOutlined } from '@ant-design/icons'
import { TablePaginationConfig } from 'antd/lib/table/interface'

import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { AppDispatch } from '@src/store/store'
import { getTeachers, updateStatus } from '@src/store/ducks/teachers/thunks'
import { selectTeachersIsLoading, selectTeachersList, selectTeachersMeta } from '@src/store/ducks/teachers/selectors'
import { TeacherItemType } from '@src/store/ducks/teachers/types'
import { ReactComponent as Pencil } from '@src/assets/pencil.svg'
import { SearchInput } from '@src/components/common/SearchInput/SearchInput'
import { AllowedTo } from '@src/components/common/AllowedTo/AllowedTo'
import { Role } from '@src/types/user'
import { selectUser } from '@src/store/ducks/user/selectors'
import style from './Teachers.module.scss'

export const Teachers = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const me = useSelector(selectUser)
  const teachers = useSelector(selectTeachersList)
  const isLoading = useSelector(selectTeachersIsLoading)
  const paginationMeta = useSelector(selectTeachersMeta)
  const [editStatusOpen, setEditStatusOpen] = useState<null | number>(null)
  const [params, setParams] = useState({
    searchValue: '',
    page: 1,
    // filters: {},
  })

  useEffect(() => {
    dispatch(setBreadcrumbs([{ url: '/', title: t('controlPanel.title') }, { title: t('teachers.title') }]))
  }, [dispatch, t])

  useEffect(() => {
    dispatch(getTeachers(params.page, params.searchValue))
  }, [dispatch, params])

  const handleStatus = useCallback((id: number, status: string) => {
    dispatch(updateStatus(id, status))
    setEditStatusOpen(null)
  }, [dispatch])

  const onVisibleStatusChange = useCallback((id: number, visible: boolean) => {
    if ([Role.ADMIN, Role.ORGANIZER].includes(me.roleId)) {
      setEditStatusOpen(visible ? id : null)
    }
  }, [me.roleId])

  const columns = useMemo<ColumnProps<TeacherItemType>[]>(() => [
    {
      title: t('teachers.columns.name'),
      dataIndex: 'fullName',
      key: 'surname',
      className: 'blue',
      width: '30%',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('teachers.columns.date'),
      dataIndex: 'createdAt',
      key: 'created_at',
      render: (_, record) => moment(record.user.createdAt).format('DD MMMM YYYY'),
    },
    {
      title: t('students.columns.status'),
      dataIndex: 'status',
      key: 'student_status_id',
      sorter: { multiple: 1 },
      render: (_, record) => (
        <Popover
          content={(
            <div className={style.status_select}>
              <button
                type="button"
                onClick={() => handleStatus(record.user.id, 'active')}
              >
                {t('user.statuses.active')}
              </button>
              <button
                type="button"
                onClick={() => handleStatus(record.user.id, 'notActive')}
              >
                {t('user.statuses.notActive')}
              </button>
            </div>
          )}
          trigger="click"
          placement="bottom"
          visible={editStatusOpen === record.id}
          onVisibleChange={(visible) => onVisibleStatusChange(record.id, visible)}
        >
          <div className={`user_status user_status--${record.user.status.code}`}>{record.user.status.title}</div>
        </Popover>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          <NavLink className={cn(style.actions_item)} to={`profile/${record.user.id}`}>
            <Pencil />
          </NavLink>
          <NavLink className={cn(style.actions_item)} to={`teachers/schedule/${record.id}`}>
            <ScheduleOutlined />
          </NavLink>
          {/*          <button
            className={cn(style.actions_item, style.actions_item__delete)}
            aria-label="delete"
            type="button"
           // onClick={() => { dispatch(deleteStudent([record.user.id])) }}
          >
            <DeleteOutlined />
          </button> */}
        </div>
      ),
    },
  ], [editStatusOpen, handleStatus, onVisibleStatusChange, t])

  const handleTableChange = useCallback((
    pagination: TablePaginationConfig,
    // _: any,
    //   sorter: SorterResult<TeacherItemType> | SorterResult<TeacherItemType>[],
  ) => {
    setParams((prevState) => ({
      ...prevState,
      page: pagination.current || 1,
    }))
  }, [])

  // поиск
  const handleSearch = useCallback((value: string) => {
    setParams((prevState) => ({
      ...prevState,
      searchValue: value,
      page: 1,
    }))
  }, [])

  return (
    <div>
      <div className={style.header}>
        <h1 className="page_title">{t('teachers.title')}</h1>
        <AllowedTo roles={[Role.ADMIN, Role.ORGANIZER]}>
          <NavLink to="/teachers/create">
            <Button type="primary">{t('teachers.create')}</Button>
          </NavLink>
        </AllowedTo>
      </div>

      <SearchInput
        placeholder={t('secretary.search')}
        name="term"
        onChange={handleSearch}
        className={style.search}
      />

      <Table<TeacherItemType>
        className={style.table}
        dataSource={teachers}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
        pagination={{
          total: paginationMeta.totalCount,
          current: paginationMeta.currentPage,
          pageSize: paginationMeta.perPage,
          showSizeChanger: false,
        }}
      />
    </div>
  )
}
