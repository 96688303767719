import { RootState } from '@src/store/store'
import { initialState, InvoicesStateType } from './reducer'

export const selectInvoices = (state: RootState): InvoicesStateType => state.invoices || initialState

export const selectInvoicesList = (state: RootState) => selectInvoices(state).list
export const selectInvoicesIsLoading = (state: RootState) => selectInvoices(state).isLoading
export const selectInvoicesMeta = (state: RootState) => selectInvoices(state).meta
export const selectInvoicesFormData = (state: RootState) => selectInvoices(state).formData
export const selectInvoicesIsLoadingFilters = (state: RootState) => selectInvoices(state).isLoadingFilters
export const selectInvoicesFilters = (state: RootState) => selectInvoices(state).filters
export const selectInvoicesIsLoadingExport = (state: RootState) => selectInvoices(state).isLoadingExport
