import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CoursesType = {
  title: string
  individualCount: number
}

export const initialState = {
  courses: [] as CoursesType[],
}

const curatorSlice = createSlice({
  name: 'curator',
  initialState,
  reducers: {
    setCourses(state, { payload }: PayloadAction<CoursesType[]>) {
      state.courses = payload
    },
  },
})

export type CuratorStateType = typeof initialState

export const { setCourses } = curatorSlice.actions

export const curatorReducer = curatorSlice.reducer
