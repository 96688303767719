import { RootState } from '@src/store/store'
import { initialState } from './reducer'

const selectProfile = (state: RootState) => state.profile || initialState

export const selectProfileUser = (state: RootState) => selectProfile(state).user
export const selectProfileIsLoading = (state: RootState) => selectProfile(state).isLoading
export const selectProfileIsLoadingSave = (state: RootState) => selectProfile(state).isLoadingSave
export const selectProfileError = (state: RootState) => selectProfile(state).error
export const selectProfileIsEdit = (state: RootState) => selectProfile(state).isEdit
export const selectProfileBackError = (state: RootState) => selectProfile(state).backError
export const selectProfileCurators = (state: RootState) => selectProfile(state).curators
export const selectProfileIsLoadingUpdateCurator = (state: RootState) => selectProfile(state).isLoadingUpdateCurator
