import { Key } from 'antd/lib/table/interface'

import { AppDispatch } from '@src/store/store'
import { studentsApi } from '@src/api/user/students-api'
import {
  setIsLoading, setIsLoadingExport, setIsLoadingFilters, setStudentsFilters, setStudentsList,
  setStudentsMeta, setUpdateLoading, updateName, updateStudentStatus,
} from '@src/store/ducks/students/reducer'
import { userApi } from '@src/api/user/user-api'
import { saveAs } from 'file-saver'

export const getStudents = (page: number, filters?: any, term?: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    const result = await studentsApi.getList(page, filters, term)
    dispatch(setStudentsList(result.items))
    dispatch(setStudentsMeta(result.meta))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const updateStudent = (id: number, data: { fullName: string }) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setUpdateLoading({ id, isLoading: true, field: 'fullName' }))
    console.log(123)
    await setTimeout(() => {
      // await studentsApi.update(id, data)
      dispatch(updateName({ id, name: data.fullName }))

      dispatch(setUpdateLoading({ id, isLoading: false, field: 'fullName' })) // todo remove
    }, 2000)
  } catch (err) {
    console.error(err)
  } finally {
    // dispatch(setUpdateLoading({ id, isLoading: false, field: 'fullName' }))
  }
}

export const deleteStudent = (userIds: Key[]) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    await userApi.delete(userIds)
    dispatch(getStudents(1))
  } catch (err) {
    console.error(err)
  }
}

export const getFiltersStudents = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingFilters(true))
    const result = await studentsApi.getFilters()
    dispatch(setStudentsFilters(result))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingFilters(false))
  }
}

export const updateStatus = (userId: number, statusCode: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await userApi.updateStatus(userId, statusCode)
    dispatch(updateStudentStatus({ userId, status: result }))
  } catch (err) {
    console.error(err)
  }
}

export const studentsExport = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingExport(true))
    const result = await userApi.export()
    saveAs(result, 'students.xlsx')
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingExport(false))
  }
}
