import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'

import { AppDispatch } from '@src/store/store'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { RegistrationForm } from '@src/pages/Registration/components/RegistrationForm/RegistrationForm'
import { UserType } from '@src/types/user'
import { createUser } from '@src/store/ducks/user/thunks'
import { selectUserBackError, selectUserIsLoadingCreate } from '@src/store/ducks/user/selectors'
import { resetCreate } from '@src/store/ducks/user/reducer'

export const OrganizersCreate = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const formRef = useRef<any>(null)
  const history = useHistory()
  const isLoadingCreate = useSelector(selectUserIsLoadingCreate)
  const backError = useSelector(selectUserBackError)

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { url: '/', title: t('controlPanel.title') },
      { url: '/organizers', title: t('organizers.title') },
      { title: t('organizersCreate.title') },
    ]))
  }, [dispatch, t])

  useEffect(() => () => { dispatch(resetCreate()) })

  useEffect(() => {
    if (backError) {
      formRef.current?.setErrors(backError)
    }
  }, [backError])

  const successCallback = useCallback(() => {
    history.push('/organizers')
  }, [history])

  return (
    <div>
      <h1 style={{ marginBottom: 20 }} className="page_title">{t('organizersCreate.title')}</h1>

      <RegistrationForm
        formType={UserType.ORGANIZER}
        ref={formRef}
        onSubmit={(e) => { dispatch(createUser(e, successCallback)) }}
        isCreate
      />

      <Button
        loading={isLoadingCreate}
        onClick={() => formRef.current?.submitForm()}
        type="primary"
      >
        {t('studentCreate.save')}
      </Button>
    </div>
  )
}
