import moment from 'moment-timezone'
import { AppDispatch } from '@src/store/store'
import {
  addTeachersSchedule,
  deleteTeacherScheduleAC,
  rollbackTeachersSchedule,
  setIsLoading,
  setTeacherName, setTeachersList, setTeachersMeta, setTeachersSchedule, updateTeachersSchedule, updateTeacherStatus,
  setIsLoadingExport,
} from '@src/store/ducks/teachers/reducer'
import { teachersApi } from '@src/api/user/teachers-api'
import { TeacherScheduleCalendarType, TeacherScheduleType } from '@src/store/ducks/teachers/types'
import { HttpStatus } from '@src/types/httpStatus'
import { notification } from 'antd'
import i18n from 'i18next'
import { userApi } from '@src/api/user/user-api'
import { saveAs } from 'file-saver'

const mapSchedule = (events: TeacherScheduleType[]): TeacherScheduleCalendarType[] => events.map((event) => ({
  id: `${event.id}`,
  start: moment(event.start).format(),
  end: moment(event.end).format(),
  title: '',
  extendedProps: event,
}))

export const getTeachers = (page: number, term?: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    const result = await teachersApi.getList(page, term)
    dispatch(setTeachersList(result.items))
    dispatch(setTeachersMeta(result.meta))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getTeacherSchedule = (teacherId: number) => async (dispatch: AppDispatch) => {
  try {
    const result = await teachersApi.getSchedule(teacherId)
    dispatch(setTeachersSchedule(mapSchedule(result.schedule)))
    dispatch(setTeacherName(result.name))
  } catch (err) {
    console.error(err)
  }
}

export const createTeacherSchedule = (
  teacherId: number, start: string, end: string,
) => async (dispatch: AppDispatch) => {
  try {
    const result = await teachersApi.createSchedule(teacherId, start, end)
    dispatch(addTeachersSchedule(mapSchedule([result])[0]))
  } catch (err) {
    console.error(err)
    if (err?.response?.status === HttpStatus.BAD_REQUEST) {
      notification.error({
        message: i18n.t('errorTitle'),
        description: err.response.data.error,
      })
    }
  }
}

export const updateTeacherSchedule = (
  teacherId: number, dayId: number, start: string, end: string,
) => async (dispatch: AppDispatch) => {
  try {
    const result = await teachersApi.updateSchedule(teacherId, dayId, start, end)
    dispatch(updateTeachersSchedule(mapSchedule([result])[0]))
  } catch (err) {
    console.error(err)
    dispatch(rollbackTeachersSchedule())
    if (err.response.status === HttpStatus.BAD_REQUEST) {
      notification.error({
        message: i18n.t('errorTitle'),
        description: err.response.data.error,
      })
    }
  }
}

export const deleteTeacherSchedule = (dayId: number) => async (dispatch: AppDispatch) => {
  try {
    console.log(dayId)
    await teachersApi.deleteSchedule(dayId)
    dispatch(deleteTeacherScheduleAC(`${dayId}`))
  } catch (err) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description: err?.response?.data?.message,
    })
  }
}

export const updateStatus = (userId: number, statusCode: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await userApi.updateStatus(userId, statusCode)
    dispatch(updateTeacherStatus({ userId, status: result }))
  } catch (err) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description: err?.response?.data?.error,
    })
  }
}

export const scheduleTeacherExport = (teacherId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingExport(true))
    const result = await teachersApi.exportSchedule(teacherId)
    saveAs(result, `teacher-schedule-${teacherId}.xlsx`)
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingExport(false))
  }
}
