import { useEffect, useMemo } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import cn from 'classnames'
import { Form, Formik } from 'formik'
import { UnlockOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'

import useQuery from '@src/hooks/useQuery'
import { JoinContainer } from '@src/components/JoinContainer/JoinContainer'
import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import {
  selectAuthIsLoading,
  selectAuthIsLoadingCheckToken,
  selectAuthIsRestTokenError,
  selectAuthResetPasswordSuccess,
} from '@src/store/ducks/auth/selectors'
import { resetState } from '@src/store/ducks/auth/reducer'
import { Preloader } from '@src/components/common/Preloader/Preloader'
import { checkResetToken, resetPassword } from '@src/store/ducks/auth/thunks'
import style from './ResetPassword.module.scss'

export const ResetPassword = () => {
  const token = useQuery('token')
  const email = useQuery('email')
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoading = useSelector(selectAuthIsLoading)
  const isLoadingCheckToken = useSelector(selectAuthIsLoadingCheckToken)
  const isRestTokenError = useSelector(selectAuthIsRestTokenError)
  const resetPasswordSuccess = useSelector(selectAuthResetPasswordSuccess)

  useEffect(() => {
    if (token && email) {
      dispatch(checkResetToken(token, email))
    }
  }, [dispatch, email, token])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      password: Yup.string()
        .min(6, t('form.errors.min', '', { count: 6 }))
        .required(t('form.errors.required')),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], t('form.errors.passMismatch'))
        .required(t('form.errors.required')),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    password: '',
    passwordConfirmation: '',
  }), [])

  useEffect(() => () => {
    dispatch(resetState())
  }, [dispatch])

  useEffect(() => {
    if (resetPasswordSuccess) history.push('/')
  }, [history, resetPasswordSuccess])

  if (!token || !email || isRestTokenError) {
    return (
      <JoinContainer title={t('forgotPassword.email.title')}>
        <div className={style.text}>
          {t('forgotPassword.reset.errorText')}
        </div>
        <NavLink to="/login">
          <Button type="primary" className={cn('login-form-button', style.btn)}>
            {t('forgotPassword.reset.errorBtn')}
          </Button>
        </NavLink>

      </JoinContainer>
    )
  }

  return (
    <JoinContainer title={t('forgotPassword.reset.title')}>
      <Preloader loading={isLoadingCheckToken}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={({ password }) => { dispatch(resetPassword(token, password, email)) }}
        >
          {() => (
            <Form>
              <FieldFormik
                Prefix={UnlockOutlined}
                name="password"
                type="password"
                placeholder={t('registration.password')}
              />
              <FieldFormik
                Prefix={UnlockOutlined}
                name="passwordConfirmation"
                type="password"
                placeholder={t('registration.passwordConfirmation')}
              />

              <Button
                type="primary"
                htmlType="submit"
                className={cn('login-form-button', style.btn)}
                loading={isLoading}
              >
                {t('forgotPassword.reset.submitBtn')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
    </JoinContainer>
  )
}
