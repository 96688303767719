import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LessonType } from '@src/types/lessons'

export type LessonsType = {
  title: string
  calendarId: number
  lessonsDescription: string
  lessons: Array<LessonType>
}

export const initialState = {
  lessons: [] as LessonsType[],
  isLoading: false,
}

const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setLessonsList(state, { payload }: PayloadAction<LessonsType[]>) {
      state.lessons = payload
    },
  },
})

export type LessonsStateType = typeof initialState;

export const { setLessonsList, setIsLoading } = lessonsSlice.actions

export const lessonsReducer = lessonsSlice.reducer
