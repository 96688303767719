import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { Button, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { JoinContainer } from '@src/components/JoinContainer/JoinContainer'
import { UserType } from '@src/types/user'
import { AppDispatch } from '@src/store/store'
import { resetState } from '@src/store/ducks/auth/reducer'
import {
  selectAuthBackError,
  selectAuthIsLoading,
  selectDocumentsLinks,
  selectRegisterSuccess,
} from '@src/store/ducks/auth/selectors'
import { getDocumentsLinks, register } from '@src/store/ducks/auth/thunks'
import { CheckboxFormik } from '@src/components/common/CheckboxFormik/CheckboxFormik'
import { RadioFormik } from '@src/components/common/RadioFormik/RadioFormik'
import useQuery from '@src/hooks/useQuery'
import { RegistrationForm } from './components/RegistrationForm/RegistrationForm'
import style from './Registration.module.scss'

export const Registration = () => {
  const formRef = useRef<any>(null)
  const typeQuery = useQuery('type')
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const registerSuccess = useSelector(selectRegisterSuccess)
  const [formType, setFormType] = useState<UserType>(
    // @ts-ignore
    Object.values<any>(UserType).includes(typeQuery) ? typeQuery : UserType.INDIVIDUAL,
  )
  const backError = useSelector(selectAuthBackError)
  const isLoading = useSelector(selectAuthIsLoading)
  const documentsLinks = useSelector(selectDocumentsLinks)

  useEffect(() => {
    dispatch(getDocumentsLinks())
  }, [dispatch])

  useEffect(() => () => {
    dispatch(resetState())
  }, [dispatch])

  useEffect(() => {
    if (backError) {
      formRef.current?.setErrors(backError)
    }
  }, [backError])

  const initialValues = useMemo(() => ({
    isUserAgreement: false,
    isPersonalData: false,
    isOffer: false,
    isAuthorizedCompany: false,
    isAdult: null,
  }), [])

  if (registerSuccess) {
    return (
      <JoinContainer title={t('registration.success.title')}>
        <div className={style.success}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('registration.success.text') }}
          />
          <NavLink className={style.success_link} to="/login">{t('registration.loginLink')}</NavLink>
        </div>
      </JoinContainer>
    )
  }

  return (
    <JoinContainer
      title={t('registration.title')}
      description={t('registration.description')}
      className={style.container}
      header={(
        <Row gutter={[14, 14]} className={style.header}>
          <Col xs={24} md={8}>
            <Button
              type={formType === UserType.INDIVIDUAL ? 'primary' : 'default'}
              onClick={() => setFormType(UserType.INDIVIDUAL)}
            >
              {t('registration.individual')}
            </Button>
          </Col>
          <Col xs={24} md={8}>
            <Button
              type={formType === UserType.ENTITY ? 'primary' : 'default'}
              onClick={() => setFormType(UserType.ENTITY)}
            >
              {t('registration.legal')}
            </Button>
          </Col>
          <Col xs={24} md={8}>
            <Button
              type={formType === UserType.TEACHER ? 'primary' : 'default'}
              onClick={() => setFormType(UserType.TEACHER)}
            >
              {t('registration.teacher')}
            </Button>
          </Col>
        </Row>
      )}
    >
      <RegistrationForm formType={formType} ref={formRef} onSubmit={(value) => { dispatch(register(value)) }} />
      <Formik initialValues={initialValues} onSubmit={() => formRef.current?.submitForm()}>
        {({ values }) => (
          <Form>
            <div className={style.checkboxs}>
              <div className={style.checkbox_container}>
                <CheckboxFormik name="isUserAgreement">
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: t('registration.userAgreement', '', { url: documentsLinks.agreement }),
                    }}
                  />
                </CheckboxFormik>
              </div>
              <div className={style.checkbox_container}>
                <CheckboxFormik name="isPersonalData">
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: t('registration.personalData', '', { url: documentsLinks.legal }),
                    }}
                  />
                </CheckboxFormik>
              </div>
              {formType !== UserType.TEACHER && (
                <div className={style.checkbox_container}>
                  <CheckboxFormik name="isOffer">
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: t('registration.offer', '', { url: documentsLinks.cooperation }),
                      }}
                    />
                  </CheckboxFormik>
                </div>
              )}
              {formType === UserType.ENTITY && (
                <div className={style.checkbox_container}>
                  <CheckboxFormik name="isAuthorizedCompany">
                    <div>{t('registration.authorizedCompany')}</div>
                  </CheckboxFormik>
                </div>
              )}
              {formType === UserType.INDIVIDUAL && (
                <div className={style.checkbox_container}>
                  <RadioFormik
                    name="isAdult"
                    options={[
                      { value: 1, label: t('registration.adult') },
                      { value: 2, label: t('registration.adultParental') },
                    ]}
                  />
                </div>
              )}
            </div>
            <Button
              disabled={
                !values.isUserAgreement
                || !values.isPersonalData
                || (formType !== UserType.TEACHER && !values.isOffer)
                || (formType === UserType.ENTITY && !values.isAuthorizedCompany)
                || (formType === UserType.INDIVIDUAL && !values.isAdult)
              }
              loading={isLoading}
              type="primary"
              htmlType="submit"
              className={cn('login-form-button', style.btn)}
            >
              {t('registration.submitBtn')}
            </Button>
          </Form>
        )}
      </Formik>
      <div className={style.actions}>
        {t('registration.haveAccount')}
        {' '}
        <NavLink to="/login">{t('registration.loginLink')}</NavLink>
      </div>
    </JoinContainer>
  )
}
