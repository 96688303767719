import { Button, Dropdown, Menu } from 'antd'
import cn from 'classnames'
import { useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/ducks/user/selectors'
import style from './CreateDropdown.module.scss'
import { menuCreate, MenuListItemType } from './menuCreate'

const mapMenu = (menuArr: MenuListItemType, t: TFunction<'ru'>) => menuArr.map((item) => {
  if (item.subMenu) {
    return (
      <Menu.SubMenu
        key={item.title}
        title={t(item.title)}
        className={cn(style.subBlock)}
      >
        {mapMenu(item.subMenu, t)}
      </Menu.SubMenu>
    )
  }
  return (
    <Menu.Item className={style.itemBlock} key={item.title}>
      <NavLink to={item.url ?? '#'}>
        <span>
          {t(item.title)}
        </span>
      </NavLink>
    </Menu.Item>
  )
})

export const CreateDropdown = () => {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation()
  const me = useSelector(selectUser)

  const menu = useMemo(() => (
    <div className={style.menuContainer}>
      <Menu mode="inline">
        {mapMenu(menuCreate[me.roleId], t)}
      </Menu>
    </div>
  ), [me.roleId, t])

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={isVisible}
      onVisibleChange={setIsVisible}
    >
      <Button
        className={cn(style.btnAdd, 'ant-dropdown-link')}
        type="primary"
      >
        {`+ ${t('menu.add')}`}
      </Button>
    </Dropdown>
  )
}
