import { StudentsFiltersType, StudentsItemType } from '@src/store/ducks/students/types'
import { api, GetItemsType } from '../api'

export const studentsApi = {
  getList(page: number, filters?: any, term?: string) {
    return api.get<GetItemsType<StudentsItemType>>('/v1/students', { page, filters, term })
  },
  update(id: number, data: any) {
    return api.put('/v1/', data, { id })
  },
  getFilters() {
    return api.get<StudentsFiltersType>('/v1/students/filters')
  },
  find(term: string) {
    return api.get<StudentsItemType[]>('/v1/students/find', { term })
  },
}
