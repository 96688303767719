import { FC } from 'react'
import { Spin } from 'antd'
import cn from 'classnames'

import style from './Preloader.module.scss'

type PreloaderProps = {
  loading: boolean
  center?: boolean
}

export const Preloader: FC<PreloaderProps> = ({ children, loading, center }) => (
  <>
    {!loading && children}
    {loading && (
      <div className={cn(style.preloader, center && style.preloader_center)}>
        <div>
          <Spin size="large" />
        </div>
      </div>
    )}
  </>
)
