import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import {
  NavLink, Route, Switch, useLocation, useParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CameraFilled, DeleteOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import cn from 'classnames'
import { Button, Menu } from 'antd'
import Upload from 'antd/lib/upload'

import { AppDispatch } from '@src/store/store'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { selectUser } from '@src/store/ducks/user/selectors'
import {
  reset, setIsEdit, setIsLoading, setIsLoadingSave, setProfileUser,
} from '@src/store/ducks/profile/reducer'
import {
  selectProfileBackError,
  selectProfileError,
  selectProfileIsEdit,
  selectProfileIsLoading,
  selectProfileIsLoadingSave,
  selectProfileUser,
} from '@src/store/ducks/profile/selectors'
import { getInitials } from '@src/lib/getInitials'
import { ReactComponent as Pencil } from '@src/assets/pencil.svg'
import { getUserProfile, uploadImage } from '@src/store/ducks/profile/thunks'
import { Preloader } from '@src/components/common/Preloader/Preloader'
import { HttpStatus } from '@src/types/httpStatus'
import { Worksheet } from '@src/components/Profile/Worksheet/Worksheet'
import { ContactInfo } from '@src/components/Profile/ContactInfo/ContactInfo'
import { Role } from '@src/types/user'
import { UploadFile } from 'antd/lib/upload/interface'
import { AllowedTo } from '@src/components/common/AllowedTo/AllowedTo'
import { Curator } from '@src/components/Profile/Curator/Curator'
import { PrivateRoute } from '@src/routes/PrivateRoute'
import style from './Profile.module.scss'

export const Profile = () => {
  const worksheetRef = useRef<any>()
  const contactInfoRef = useRef<any>()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const params = useParams<{ id: string, worksheet?: string }>()
  const me = useSelector(selectUser)
  const user = useSelector(selectProfileUser)
  const isLoading = useSelector(selectProfileIsLoading)
  const isLoadingSave = useSelector(selectProfileIsLoadingSave)
  const error = useSelector(selectProfileError)
  const isEdit = useSelector(selectProfileIsEdit)
  const backError = useSelector(selectProfileBackError)
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)
  const location = useLocation()

  const isMe = useMemo(() => me.id === +params.id, [me.id, params.id])

  // крошки
  useEffect(() => {
    dispatch(setBreadcrumbs([
      { url: '/', title: t('controlPanel.title') }, { title: user.fullName },
    ]))
  }, [dispatch, t, user.fullName])

  useEffect(() => {
    if (backError) {
      if (params.worksheet) {
        worksheetRef.current?.setErrors(backError)
      } else {
        contactInfoRef.current?.setErrors(backError)
      }
    }
  }, [backError, params.worksheet])

  // запрос юзера, если это я то берем из стейта
  useEffect(() => {
    if (isMe) {
      dispatch(setProfileUser(me))
      dispatch(setIsLoading(false))
    } else {
      dispatch(getUserProfile(+params.id))
    }

    return () => {
      dispatch(reset())
    }
  }, [dispatch, isMe, me, params.id])

  // загрузка фойла с компа
  const beforeUpload = async (value: { fileList: UploadFile[] }) => {
    setIsLoadingPhoto(true)
    await dispatch(uploadImage(value.fileList[0].originFileObj, user.id))
    setIsLoadingPhoto(false)
  }

  // сохраняем изменения
  const handleSave = async () => {
    if (params.worksheet) {
      const worksheetFormErrors = await worksheetRef.current?.validateForm()
      worksheetRef.current?.setTouched(worksheetFormErrors)
      if (Object.keys(worksheetFormErrors).length > 0) return
      worksheetRef.current?.submitForm()
    } else {
      const contactInfoFormErrors = await contactInfoRef.current?.validateForm()
      contactInfoRef.current?.setTouched(contactInfoFormErrors)
      if (Object.keys(contactInfoFormErrors).length > 0) return
      contactInfoRef.current?.submitForm()
    }
    dispatch(setIsLoadingSave(true))
  }

  if (error) {
    switch (error) {
      case HttpStatus.FORBIDDEN:
        return (
          <div>
            <div>{t('profile.forbidden')}</div>
            <NavLink to={`/profile/${me.id}`}>{t('profile.meProfile')}</NavLink>
          </div>
        )
      case HttpStatus.NOT_FOUND:
        return (
          <div>
            <div>{t('profile.notFound')}</div>
            <NavLink to={`/profile/${me.id}`}>{t('profile.meProfile')}</NavLink>
          </div>
        )
      default:
        return <div>{t('error')}</div>
    }
  }

  return (
    <Preloader loading={isLoading}>
      <div className={style.header}>
        <div className={style.header_profile}>
          <div className={cn(style.header_profile_photo, isLoadingPhoto && style.header_profile_photo__loading)}>
            <Preloader loading={isLoadingPhoto} center>
              {user.fullInfo.photo ? (
                <img
                  className={style.header_profile_photo_item}
                  src={user.fullInfo.photo ?? ''}
                  alt={user.fullName}
                />
              ) : (
                <div className={style.header_profile_photo_item}>
                  {getInitials(user.fullName)}
                </div>
              )}
            </Preloader>
            {isEdit && (
              <ImgCrop>
                <Upload
                  onChange={beforeUpload}
                  fileList={[]}
                  accept="image/jpeg, image/png"
                  beforeUpload={() => false}
                >
                  <div className={style.header_profile_photo_upload}>
                    <CameraFilled />
                  </div>
                </Upload>
              </ImgCrop>
            )}
          </div>
          <div>
            <div className={style.header_profile_name}>{user.fullName}</div>
            <div className={style.header_profile_role}>{t(`roles.${user.roleId}`)}</div>
          </div>
        </div>

        <div className={style.actions}>
          {(
            isMe
            || (me.roleId === Role.ADMIN)
            || (me.roleId === Role.ORGANIZER && [Role.STUDENT, Role.TEACHER].includes(user.roleId))
            || (me.roleId === Role.SECRETARY && [Role.STUDENT, Role.TEACHER].includes(user.roleId))
          ) && (
            <>
              {(!isEdit && !isLoadingSave) && (
                <Button
                  size="small"
                  className={style.actions_item}
                  icon={<span className="anticon anticon-delete"><Pencil /></span>}
                  onClick={() => dispatch(setIsEdit(true))}
                >
                  {t('profile.editBtn')}
                </Button>
              )}
              <AllowedTo isMe userId={user.id} roles={[Role.STUDENT]}>
                <Button size="small" className={cn(style.actions_item, style.actions_delete)} icon={<DeleteOutlined />}>
                  {t('profile.deleteBtn')}
                </Button>
              </AllowedTo>
              {(isEdit || isLoadingSave) && (
                <Button
                  type="primary"
                  size="small"
                  className={style.actions_item}
                  onClick={handleSave}
                  loading={isLoadingSave}
                >
                  {t('profile.saveBtn')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      <div className={style.menu}>
        <Menu selectedKeys={[location.pathname]} mode="horizontal">
          <Menu.Item key={`/profile/${user.id}`}>
            <NavLink className={style.menu_link} to={`/profile/${user.id}`}>
              {t('profile.contactInfo.title')}
            </NavLink>
          </Menu.Item>

          <Menu.Item key={`/profile/${user.id}/worksheet`}>
            <AllowedTo userRole={user.roleId} userRoles={[Role.STUDENT]}>
              <NavLink className={style.menu_link} to={`/profile/${user.id}/worksheet`}>
                {t('profile.worksheet.title')}
              </NavLink>
            </AllowedTo>
          </Menu.Item>

          <Menu.Item key={`/profile/${user.id}/curator`}>
            <AllowedTo
              roles={[Role.ADMIN, Role.ORGANIZER, Role.SECRETARY, Role.TEACHER]}
              userRole={user.roleId}
              userRoles={[Role.STUDENT]}
            >
              <NavLink className={style.menu_link} to={`/profile/${user.id}/curator`}>
                {t('profile.curator.title')}
              </NavLink>
            </AllowedTo>
          </Menu.Item>
        </Menu>
      </div>

      <div className={style.main}>
        <Switch>
          <Route path="/profile/:id" exact>
            <ContactInfo isEdit={isEdit} ref={contactInfoRef} />
          </Route>
          <Route path="/profile/:id/worksheet" exact>
            <Worksheet isMe={isMe} isEdit={isEdit} ref={worksheetRef} />
          </Route>
          <PrivateRoute
            roles={[Role.ADMIN, Role.ORGANIZER, Role.SECRETARY, Role.TEACHER]}
            path="/profile/:id/curator"
            exact
          >
            <Curator />
          </PrivateRoute>
        </Switch>
      </div>
    </Preloader>
  )
}
