import { RootState } from '@src/store/store'
import { AuthStateType, initialState } from './reducer'

export const selectAuth = (state: RootState): AuthStateType => state.auth || initialState

export const selectAuthIsLoading = (state: RootState): boolean => selectAuth(state).isLoading
export const selectAuthIsLoadingCheckToken = (state: RootState): boolean => selectAuth(state).isLoadingCheckToken
export const selectAuthIsRestTokenError = (state: RootState): boolean => selectAuth(state).isRestTokenError
export const selectAuthResetPasswordSuccess = (state: RootState): boolean => selectAuth(state).resetPasswordSuccess
export const selectAuthBackError = (state: RootState) => selectAuth(state).backError
export const selectAuthIsAuth = (state: RootState): boolean => selectAuth(state).isAuth
export const selectRegisterSuccess = (state: RootState): boolean => selectAuth(state).registerSuccess
export const selectResetEmailSuccess = (state: RootState): boolean => selectAuth(state).resetEmailSuccess
export const selectDocumentsLinks = (state: RootState) => selectAuth(state).documentsLinks
export const selectChangePasswordSuccess = (state: RootState): boolean => selectAuth(state).changePasswordSuccess
