import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DialogsType, MessageType } from './types'

export const initialState = {
  isLoading: false,
  dialogs: [] as DialogsType[],
  dialogsId: [] as number[],
  onlineUsers: [] as number[],
  dialogId: null as null | number,
  messages: [] as MessageType[],
  isLoadingSend: false,
  lastId: undefined as number | undefined,
  isLast: false,
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setDialogs(state, { payload }: PayloadAction<DialogsType[]>) {
      state.dialogs = payload
      const newDialogsId = payload.map((item) => item.id)
      if (state.dialogsId.join(',') !== newDialogsId.join(',')) {
        state.dialogsId = newDialogsId
      }
    },
    setOnlineUsers(state, { payload }: PayloadAction<number[]>) {
      state.onlineUsers = payload
    },
    addOnlineUser(state, { payload }: PayloadAction<number>) {
      state.onlineUsers.push(payload)
    },
    removeOnlineUser(state, { payload }: PayloadAction<number>) {
      state.onlineUsers = state.onlineUsers.filter((item) => item !== payload)
    },
    setDialogId(state, { payload }: PayloadAction<number>) {
      state.dialogId = payload
    },
    setMessages(state, { payload }: PayloadAction<MessageType[]>) {
      state.messages = [...state.messages, ...payload]
    },
    addMessage(state, { payload }: PayloadAction<MessageType>) {
      state.messages.unshift(payload)
    },
    setReadMessage(state, { payload }: PayloadAction<number>) {
      state.messages = state.messages.map((item) => (item.id === payload ? { ...item, isRead: true } : item))
    },
    resetMessages(state) {
      state.dialogId = null
      state.messages = []
      state.isLast = false
      state.lastId = undefined
    },
    setIsLoadingSend(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingSend = payload
    },
    setLastId(state, { payload }:PayloadAction<number>) {
      state.lastId = payload
    },
    setIsLast(state, { payload }: PayloadAction<boolean>) {
      state.isLast = payload
    },

  },
})

export type ChatStateType = typeof initialState

export const {
  setIsLoading, setDialogs, setDialogId,
  setMessages, resetMessages, addMessage,
  setIsLoadingSend, setLastId, setIsLast,
  setReadMessage, setOnlineUsers, addOnlineUser,
  removeOnlineUser,
} = chatSlice.actions

export const chatReducer = chatSlice.reducer
