import {
  useCallback, useEffect, useMemo, useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button, Col, Row,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'

import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { AppDispatch } from '@src/store/store'
import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import style from '@src/pages/CreateMaterials/CreateMaterials.module.scss'
import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import {
  createInvoice, findInvoicesForm, getCalendarsForm, getCoursesForm, getPayersForm,
} from '@src/store/ducks/invoices/thunks'
import { resetInvoices } from '@src/store/ducks/invoices/reducer'
import { selectInvoicesFormData, selectInvoicesIsLoading } from '@src/store/ducks/invoices/selectors'
import moment from 'moment-timezone'
import { InvoicesTypeEnum } from '@src/types/orders'

export const InvoicesCreate = () => {
  const formRef = useRef<any>()
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const formData = useSelector(selectInvoicesFormData)
  const isLoading = useSelector(selectInvoicesIsLoading)

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { url: '/', title: t('controlPanel.title') },
      { url: '/invoices', title: t('invoices.title') },
      { title: t('invoicesCreate.title') },
    ]))
  }, [dispatch, t])

  useEffect(() => () => { dispatch(resetInvoices()) }, [dispatch])

  const invoiceType = useMemo(() => [
    {
      value: InvoicesTypeEnum.PROFORMA,
      label: t('invoices.types.proforma'),
    },
    {
      value: InvoicesTypeEnum.CREDIT,
      label: t('invoices.types.credit'),
    },
  ], [t])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
    /*  validDays: Yup.number().when('type', {
        is: InvoicesTypeEnum.PROFORMA,
        then: Yup.number().typeError(t('form.errors.number')).required(t('form.errors.required')),
      }), */
      studentId: Yup.number().nullable().when('type', {
        is: InvoicesTypeEnum.PROFORMA,
        then: Yup.number().nullable().required(t('form.errors.required')),
      }),
      calendarId: Yup.number().nullable().when('type', {
        is: InvoicesTypeEnum.PROFORMA,
        then: Yup.number().nullable().required(t('form.errors.required')),
      }),
      invoiceId: Yup.number().nullable().when('type', {
        is: InvoicesTypeEnum.CREDIT,
        then: Yup.number().nullable().required(t('form.errors.required')),
      }),
      amount: Yup.number().when('type', {
        is: InvoicesTypeEnum.CREDIT,
        then: Yup.number().typeError(t('form.errors.number')).required(t('form.errors.required')),
      }),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    type: InvoicesTypeEnum.PROFORMA,
    validDays: '0',
    studentId: null,
    theme: '',
    course: null,
    calendarId: null,
    invoiceId: null,
    amount: '',
  }), [])

  const handleSearch = useMemo(() => _.debounce((value: string, type: string) => {
    if (value.length > 1) {
      switch (type) {
        case 'payer':
          dispatch(getPayersForm(value))
          break
        case 'course':
          dispatch(getCoursesForm(value))
          break
        case 'calendar':
          dispatch(getCalendarsForm(value, formRef.current.values.course))
          break
        case 'invoice':
          dispatch(findInvoicesForm(value))
          break
        default:
      }
    }
  }, 1000), [dispatch])

  const successCallback = useCallback(() => {
    history.push('/invoices')
  }, [history])

  return (
    <div>
      <h1 className="page_title">
        {params.id ? t('invoicesCreate.titleEdit') : t('invoicesCreate.title')}
      </h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => { dispatch(createInvoice(values, successCallback)) }}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form className={style.form}>
            <Row gutter={[20, 0]}>
              <Col xs={24} md={4}>
                <SelectFormik
                  name="type"
                  placeholder={t('invoicesCreate.type')}
                  options={invoiceType}
                />
              </Col>
            </Row>
            {values.type === InvoicesTypeEnum.PROFORMA && (
              <Row gutter={[20, 0]}>
                <Col xs={24} md={10}>
                  <SelectFormik
                    name="studentId"
                    showSearch
                    onSearch={(e) => { handleSearch(`${e}`, 'payer') }}
                    placeholder={t('invoicesCreate.payer')}
                    options={formData.payers.map((item) => ({ value: item.id, label: `${item.surname} ${item.name}` }))}
                  />
                </Col>
                <Col xs={24} md={10}>
                  <FieldFormik name="theme" placeholder={t('invoicesCreate.theme')} />
                </Col>
                {/*  <Col xs={24} md={4}>
                  <FieldFormik name="validDays" placeholder={t('invoicesCreate.validDays')} />
                </Col> */}

                <Col xs={24} md={12}>
                  <SelectFormik
                    name="course"
                    showSearch
                    onSearch={(e) => { handleSearch(`${e}`, 'course') }}
                    placeholder={t('invoicesCreate.course')}
                    options={formData.courses.map((item) => ({ value: item.id, label: item.name }))}
                    onChange={() => setFieldValue('calendarId', null)}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="calendarId"
                    disabled={!values.course}
                    showSearch
                    onSearch={(e) => { handleSearch(`${e}`, 'calendar') }}
                    placeholder={t('invoicesCreate.calendar')}
                    options={
                      formData.calendars.map((item) => (
                        {
                          value: item.id,
                          // eslint-disable-next-line max-len
                          label: `${item.title ? item.title : `${item.id}`} ${moment(item.startDate).format('DD.MM.YYYY')}`,
                        }
                      ))
                    }
                  />
                </Col>
              </Row>
            )}
            {values.type === InvoicesTypeEnum.CREDIT && (
              <Row gutter={[20, 0]}>
                <Col xs={24} md={6}>
                  <SelectFormik
                    name="invoiceId"
                    showSearch
                    onSearch={(e) => { handleSearch(`${e}`, 'invoice') }}
                    placeholder={t('invoicesCreate.invoices')}
                    options={formData.invoices.map((item) => ({ value: item.id, label: item.number }))}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <FieldFormik name="amount" placeholder={t('invoicesCreate.amount')} />
                </Col>
              </Row>
            )}
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {params.id ? t('invoicesCreate.edit') : t('invoicesCreate.create')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
