import { FC } from 'react'
import { Radio } from 'antd'
import { useField } from 'formik'

type RadioFormikProps = {
  name: string
  options: Array<{
    value: any
    label: string
  }>
  className?: string
}

export const RadioFormik: FC<RadioFormikProps> = ({ name, options, className }) => {
  const [field] = useField({ name })

  return (
    <Radio.Group {...field} name={name}>
      {options.map((item) => (
        <Radio className={className} key={item.value} value={item.value}>{item.label}</Radio>
      ))}
    </Radio.Group>
  )
}
