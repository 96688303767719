import { useCallback, useState } from 'react'
import cn from 'classnames'
import { Popover } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Arrow } from '@src/assets/arrow.svg'
import { setLang } from '@src/store/ducks/user/thunks'
import { AppDispatch } from '@src/store/store'
import style from '@src/components/Header/Header.module.scss'
import { ReactComponent as Ru } from '@src/assets/flegs/ru.svg'
import { ReactComponent as Fr } from '@src/assets/flegs/fr.svg'

export const SelectLanguage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { i18n } = useTranslation()
  const [isLanguage, setIsLanguage] = useState<boolean>(false)

  const changeLanguage = useCallback((language: string) => {
    i18n.changeLanguage(language)
    setIsLanguage(false)
  }, [i18n])

  const handleLang = useCallback((lang: string) => {
    dispatch(setLang(lang))
    changeLanguage(lang)
  }, [changeLanguage, dispatch])

  return (
    <Popover
      content={(
        <div>
          {/* {i18n.language !== 'en' && (
          <button className={style.language_select_item} type="button" onClick={() => handleLang('en')}>
            <En />
            en
          </button>
          )} */}
          {i18n.language !== 'ru' && (
          <button className={style.language_select_item} type="button" onClick={() => handleLang('ru')}>
            <Ru />
            ru
          </button>
          )}
          {i18n.language !== 'fr' && (
          <button className={style.language_select_item} type="button" onClick={() => handleLang('fr')}>
            <Fr />
            fr
          </button>
          )}
        </div>
    )}
      trigger="click"
      placement="bottom"
      visible={isLanguage}
      onVisibleChange={setIsLanguage}
    >
      <div className={cn(style.right_block_item)}>
        <button className={cn(style.language_btn, isLanguage && style.open)} type="button">
          <div className={style.language_btn_flag}>
            {i18n.language === 'ru' && <Ru />}
            {/* {i18n.language === 'en' && <En />} */}
            {i18n.language === 'fr' && <Fr />}
          </div>
          {i18n.language}
          <Arrow className={style.language_btn_arrow} />
        </button>
      </div>
    </Popover>
  )
}
