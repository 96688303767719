import { api } from '@src/api/api'
import { NotificationsType } from '@src/store/ducks/notification/reducer'

export const notificationApi = {
  async getNotificationCount() {
    return api.get<number>('/v1/notifications', { count: true })
  },
  async getNotifications(lastId?: number) {
    return api.get<NotificationsType[]>('/v1/notifications', { lastId })
  },
  async deleteNotification(notificationId: number) {
    return api.del('/v1/notifications/delete', { notificationId })
  },
}
