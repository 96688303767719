import {
  useMemo, FC, useEffect, useCallback, useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { Button, Spin } from 'antd'
import { Form, Formik, FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import style from '@src/components/ScheduleModal/ScheduleModal.module.scss'
import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import { AppDispatch } from '@src/store/store'
import {
  createGroupLesson,
  getScheduleCalendars,
  getScheduleCourses,
  getScheduleTeachers,
  getScheduleTeachersFreeTime,
} from '@src/store/ducks/schedule/thunks'
import {
  selectScheduleCreateBackError,
  selectScheduleCreateData,
  selectScheduleIsLoadingCrateData,
} from '@src/store/ducks/schedule/selectors'
import { resetCreate } from '@src/store/ducks/schedule/reducer'
import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { CheckboxFormik } from '@src/components/common/CheckboxFormik/CheckboxFormik'

type CreateLessonProps = {
  createDate: Date
  closeModal: () => void
}

export const CreateLesson: FC<CreateLessonProps> = ({ createDate, closeModal }) => {
  const formRef = useRef<FormikProps<typeof initialValues>>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const createData = useSelector(selectScheduleCreateData)
  const isLoadingCrateData = useSelector(selectScheduleIsLoadingCrateData)
  const backError = useSelector(selectScheduleCreateBackError)
  const startDate = useMemo(() => moment(createDate).hour(0).minute(0).second(0)
    .utc(),
  [createDate])
  const endDate = useMemo(() => moment(startDate).add(1, 'day'), [startDate])

  useEffect(() => {
    dispatch(getScheduleCourses(startDate.format(), endDate.format()))

    return () => {
      dispatch(resetCreate())
    }
  }, [createDate, dispatch, endDate, startDate])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      title: Yup.string().required(t('form.errors.required')),
      titleFr: Yup.string().required(t('form.errors.required')),
      course: Yup.string().nullable().required(t('form.errors.required')),
      calendarId: Yup.string().nullable().required(t('form.errors.required')),
      teacherId: Yup.string().nullable().required(t('form.errors.required')),
      start: Yup.string().nullable().required(t('form.errors.required')),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    title: 'Групповое занятие',
    titleFr: 'Session de groupe',
    link: '',
    course: null as null | number,
    calendarId: null as null | number,
    teacherId: null as null | number,
    start: null as null | number,
    isIndividualBefore: false,
  }), [])

  const handleCourse = useCallback((value) => {
    dispatch(getScheduleCalendars(value, startDate.format(), endDate.format()))
    formRef.current?.setFieldValue('calendarId', null)
    formRef.current?.setFieldValue('teacherId', null)
    formRef.current?.setFieldValue('start', null)
  }, [dispatch, endDate, startDate])

  const handleCalendar = useCallback((value) => {
    dispatch(getScheduleTeachers(value, startDate.format(), endDate.format()))
    formRef.current?.setFieldValue('teacherId', null)
    formRef.current?.setFieldValue('start', null)
  }, [dispatch, endDate, startDate])

  const handleTeacher = useCallback((value) => {
    if (formRef.current?.values?.calendarId) {
      dispatch(
        getScheduleTeachersFreeTime(formRef.current.values.calendarId, value, startDate.format(), endDate.format()),
      )
      formRef.current?.setFieldValue('start', null)
    }
  }, [dispatch, endDate, startDate])

  // установки дефолт препода
  useEffect(() => {
    const thisTeacher = createData.teachers.find((item) => item.isBase)?.id
    if (thisTeacher && !formRef.current?.values?.teacherId) {
      formRef.current?.setFieldValue('teacherId', thisTeacher)
      handleTeacher(thisTeacher)
    }
  }, [createData.teachers, handleTeacher])

  // установки дефолт времени
  useEffect(() => {
    if (createData.teachersFreeTime.length && !formRef.current?.values?.start) {
      formRef.current?.setFieldValue(
        'start',
        createData.teachersFreeTime.find((item) => moment(createDate).isSame(item.startTime))?.startTime ?? null,
      )
    }
  }, [createData.teachersFreeTime, createDate])

  return (
    <div>
      <div className={style.modal_type}>
        {t('schedule.createTitle')}
        {' '}
        {moment(createDate).format('DD MMMM YYYY')}
      </div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values) => dispatch(createGroupLesson(values, closeModal))}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <FieldFormik name="title" placeholder={`${t('schedule.fieldName')} ru`} />
            <FieldFormik name="titleFr" placeholder={`${t('schedule.fieldName')} fr`} />
            <FieldFormik name="link" placeholder={t('schedule.link')} />
            <SelectFormik
              name="course"
              options={createData.courses.map((item) => ({ value: item.id, label: item.name }))}
              placeholder={t('schedule.selectCourse')}
              onChange={handleCourse}
            />
            <SelectFormik
              disabled={!values.course}
              name="calendarId"
              options={
                createData.calendars.map((item) => (
                  {
                    value: item.id,
                    label: `${t('schedule.flow')} ${item.title} - ${moment(item.startDate).format('DD MMM YYYY')}`,
                  }
                ))
              }
              placeholder={t('schedule.selectCalendar')}
              onChange={handleCalendar}
            />
            <SelectFormik
              disabled={!values.calendarId}
              name="teacherId"
              options={createData.teachers.map((item) => ({ value: item.id, label: `${item.surname} ${item.name}` }))}
              placeholder={t('schedule.selectTeacher')}
              onChange={handleTeacher}
            />
            <SelectFormik
              disabled={!values.teacherId}
              name="start"
              options={
                createData.teachersFreeTime.map((item) => (
                  {
                    value: item.startTime,
                    label: `${moment(item.startTime).format('HH:mm')} - ${moment(item.endTime).format('HH:mm')}`,
                  }
                ))
              }
              placeholder={t('schedule.selectTime')}
            />
            <CheckboxFormik name="isIndividualBefore" className={style.individualBefore}>
              {t('schedule.individualBefore')}
            </CheckboxFormik>

            {backError && <div className={style.modal_error}>{backError}</div>}
            <Button className="btn_round" type="primary" htmlType="submit">{t('schedule.createBtn')}</Button>
          </Form>
        )}
      </Formik>
      {isLoadingCrateData && (
        <div className={style.modal_loading}>
          <Spin />
        </div>
      )}
    </div>
  )
}
