import { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/ducks/user/selectors'
import { Role } from '@src/types/user'

type AllowedToProps = {
  roles?: Role[]
  isMe?: boolean
  userId?: number | null
  userRoles?: Role[]
  userRole?: Role
}

export const AllowedTo: FC<AllowedToProps> = ({
  roles, children, isMe, userRoles, userRole, userId,
}) => {
  const user = useSelector(selectUser)

  if ((roles?.indexOf(user.roleId) === -1)
    || (isMe && user.id !== userId)
  || (userRole && (userRoles?.indexOf(userRole) === -1))) return null

  return <>{children}</>
}
