import {
  FC, useCallback, useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { CloseOutlined, InfoCircleFilled, MenuOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'

import { Breadcrumbs } from '@src/components/Breadcrumbs/Breadcrumbs'
import {
  selectAppBreadcrumbs,
  selectAppIsOpenLeftSitebarMobile,
  selectAppTypeRightSitebar,
} from '@src/store/ducks/app/selectors'
import { ReactComponent as Arrow } from '@src/assets/arrow.svg'
import { ReactComponent as Notification } from '@src/assets/notification.svg'
import { ReactComponent as Messenger } from '@src/assets/messenger.svg'
import { selectUser } from '@src/store/ducks/user/selectors'
import { getInitials } from '@src/lib/getInitials'
import { AppDispatch } from '@src/store/store'
import { logout } from '@src/store/ducks/auth/thunks'
import { getNotificationCount } from '@src/store/ducks/notification/thunks'
import { selectNotificationCount } from '@src/store/ducks/notification/selectors'
import {
  setIsOpenLeftSitebarMobile,
  setIsOpenRightSitebarMobile,
  setTypeRightSitebar,
  TypeRightSitebarEnum,
} from '@src/store/ducks/app/reducer'
import { Role } from '@src/types/user'
import { selectChatNotReadCountAll } from '@src/store/ducks/chat/selectors'
import { ReactComponent as Logo } from '@src/assets/logo.svg'
import { SelectLanguage } from '@src/components/SelectLanguage/SelectLanguage'
import style from './Header.module.scss'

export const Header: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const breadcrumbs = useSelector(selectAppBreadcrumbs)

  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const notificationCount = useSelector(selectNotificationCount)
  const notReadCountAll = useSelector(selectChatNotReadCountAll)
  const typeRightSitebar = useSelector(selectAppTypeRightSitebar)
  const isOpenLeftSitebarMobile = useSelector(selectAppIsOpenLeftSitebarMobile)

  useEffect(() => {
    dispatch(getNotificationCount())
    const timerId = setInterval(() => {
      dispatch(getNotificationCount())
    }, 30000)

    return () => {
      clearInterval(timerId)
    }
  }, [dispatch])

  const notificationClose = useCallback(() => {
    dispatch(setIsOpenLeftSitebarMobile(false))
    switch (user.roleId) {
      case Role.STUDENT:
        dispatch(setTypeRightSitebar(TypeRightSitebarEnum.CURATOR))
        break
      default: dispatch(setTypeRightSitebar(TypeRightSitebarEnum.DEFAULT))
    }
  }, [dispatch, user.roleId])

  const handleNotification = useCallback(() => {
    if (typeRightSitebar === TypeRightSitebarEnum.NOTIFICATION) {
      notificationClose()
    } else {
      dispatch(setTypeRightSitebar(TypeRightSitebarEnum.NOTIFICATION))
      dispatch(setIsOpenRightSitebarMobile(true))
    }
  }, [dispatch, notificationClose, typeRightSitebar])

  const handleMessenger = useCallback(() => {
    dispatch(setTypeRightSitebar(TypeRightSitebarEnum.DIALOGS))
    dispatch(setIsOpenRightSitebarMobile(true))
  }, [dispatch])

  const handleInfo = useCallback(() => {
    dispatch(setTypeRightSitebar(TypeRightSitebarEnum.CURATOR))
    dispatch(setIsOpenRightSitebarMobile(true))
  }, [dispatch])

  const handleProfilePopover = () => {
    setIsProfileOpen(false)
  }

  return (
    <header className={style.header}>
      <div className={style.breadcrumbs}>
        <Breadcrumbs options={breadcrumbs} />
      </div>
      <div className={style.left_block}>
        <button
          onClick={() => { dispatch(setIsOpenLeftSitebarMobile(!isOpenLeftSitebarMobile)) }}
          type="button"
          className={style.menu_btn}
        >
          {isOpenLeftSitebarMobile ? (
            <CloseOutlined />
          ) : (
            <MenuOutlined />
          )}
        </button>
        <a className={style.logo} href={process.env.REACT_APP_SITE_URL}>
          <Logo />
        </a>
      </div>
      <div className={style.right_block}>

        {/* смена языка */}
        <div className={style.changeLang}>
          <SelectLanguage />
        </div>

        {/* профиль */}
        <div className={cn(style.right_block_item, style.profile)}>
          <div className={style.profile_name}>{user.fullName}</div>
          <Popover
            trigger="click"
            placement="bottom"
            visible={isProfileOpen}
            onVisibleChange={setIsProfileOpen}
            content={(
              <div className={style.profile_popover}>
                <NavLink
                  onClick={handleProfilePopover}
                  className={style.profile_popover_item}
                  to="/profile"
                >
                  {t('menu.profile')}
                </NavLink>
                <NavLink
                  onClick={handleProfilePopover}
                  className={style.profile_popover_item}
                  to="/change-password"
                >
                  {t('menu.changePassword')}
                </NavLink>
                <button
                  className={style.profile_popover_item}
                  type="button"
                  onClick={() => { dispatch(logout()) }}
                >
                  {t('logout')}
                </button>
              </div>
            )}
          >
            <button type="button" onClick={() => {}} className={cn(style.profile_btn, isProfileOpen && style.open)}>
              <div className={style.profile_avatar}>
                {user.fullInfo.photo ? (
                  <img src={user.fullInfo.photo} alt={user.fullName} />
                ) : (
                  <div>{getInitials(user.fullName)}</div>
                )}
              </div>
              <Arrow className={style.profile_arrow} />
            </button>
          </Popover>
        </div>

        {/* уведомления */}
        <button
          type="button"
          onClick={handleNotification}
          className={cn(style.right_block_item)}
        >
          <Badge count={notificationCount}>
            <Notification />
          </Badge>
        </button>
        {/* уведомления чат в моилке */}
        <button
          type="button"
          onClick={handleMessenger}
          className={cn(style.right_block_item, style.right_block_item_chat)}
        >
          <Badge count={notReadCountAll}>
            <Messenger />
          </Badge>
        </button>
        {/* куратор */}
        <button type="button" onClick={handleInfo} className={cn(style.right_block_item, style.right_block_item_info)}>
          <InfoCircleFilled />
        </button>
      </div>
    </header>
  )
}
