import { RootState } from '@src/store/store'
import { PaymentHistoryStateType, initialState } from './reducer'

export const selectPayloadHistory = (state: RootState): PaymentHistoryStateType => state.paymentHistory || initialState

export const selectPayloadHistoryList = (state: RootState) => selectPayloadHistory(state).list
export const selectPayloadHistoryMeta = (state: RootState) => selectPayloadHistory(state).meta
export const selectPayloadHistoryIsLoading = (state: RootState) => selectPayloadHistory(state).isLoading
export const selectPayloadHistoryFormData = (state: RootState) => selectPayloadHistory(state).formData
export const selectPayloadHistoryIsLoadingExport = (state: RootState) => selectPayloadHistory(state).isLoadingExport
