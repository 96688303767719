import {
  FC, forwardRef, Ref, useCallback, useImperativeHandle, useMemo, useRef,
} from 'react'
import * as Yup from 'yup'
import { Col, Row } from 'antd'
import cn from 'classnames'
import { Form, Formik, yupToFormErrors } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { FormikProps } from 'formik/dist/types'

import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { PlusOutlined, UnlockOutlined } from '@ant-design/icons'
import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import { UserType } from '@src/types/user'
import { PhoneFieldFormik } from '@src/components/common/PhoneFieldFormik/PhoneFieldFormik'
import { TimezoneSelect } from '@src/components/common/TimezoneSelect/TimezoneSelect'
import { CheckboxFormik } from '@src/components/common/CheckboxFormik/CheckboxFormik'
import { formatMinute } from '@src/lib/time'
import { communicationOptions, CommunicationOptionsType } from '@src/constants/communication'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CountrySelect } from '@src/components/common/CountrySelect/CountrySelect'
import { authApi } from '@src/api/auth-api'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import style from './RegistrationForm.module.scss'

type RegistrationFormProps = {
  formType: UserType
  ref: Ref<FormikProps<any>>
  onSubmit: (value: any) => void
  isCreate?: boolean
}

export const RegistrationForm: FC<RegistrationFormProps> = forwardRef(({ formType, onSubmit, isCreate }, ref) => {
  const { t } = useTranslation()
  const formRef = useRef<any>(null)

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      baseInfo: Yup.object().shape({
        phone: Yup.string()
          .min(10, t('form.errors.min', '', { count: 10 }))
          .required(t('form.errors.required')),
        email: Yup.string()
          .email(t('form.errors.email'))
          .required(t('form.errors.required')),
        /* .test({
            name: 'unique',
            message: (
              <span>
                {t('form.errors.unique')}
                {' '}
                <NavLink to="/login">{t('login.submitBtn')}</NavLink>
              </span>
            ) as any,
            test: async (value) => authApi.checkEmail(value),
          }), */
        password: Yup.string().when('$isCreate', {
          is: true,
          otherwise: Yup.string().min(6, t('form.errors.min', '', { count: 6 }))
            .required(t('form.errors.required')),
        }),
        passwordConfirmation: Yup.string().when('$isCreate', {
          is: true,
          otherwise: Yup.string().oneOf([Yup.ref('password'), null], t('form.errors.passMismatch'))
            .required(t('form.errors.required')),
        }),
      }),

      fullInfo: Yup.object().shape({
        name: Yup.string()
          .required(t('form.errors.required')),
        surname: Yup.string()
          .required(t('form.errors.required')),
        country: Yup.string().when('$userType', {
          is: UserType.ENTITY,
          otherwise: Yup.string().required(t('form.errors.required')),
        }),
        city: Yup.string().when('$userType', {
          is: UserType.ENTITY,
          otherwise: Yup.string().required(t('form.errors.required')),
        }),
        address: Yup.string().when('$userType', {
          is: UserType.ENTITY,
          otherwise: Yup.string().required(t('form.errors.required')),
        }),
        index: Yup.string().when('$userType', {
          is: UserType.INDIVIDUAL || UserType.TEACHER,
          then: Yup.string()
            .max(10, t('form.errors.max'))
            .required(t('form.errors.required')),
        }),
        timezone: Yup.string()
          .nullable()
          .required(t('form.errors.required')),
      }),

      billingInfo: Yup.object().when('isBilling', {
        is: true,
        then: Yup.object().shape({
          address: Yup.string()
            .required(t('form.errors.required')),
          country: Yup.string()
            .required(t('form.errors.required')),
          city: Yup.string()
            .required(t('form.errors.required')),
          index: Yup.string()
            .max(10, t('form.errors.max'))
            .required(t('form.errors.required')),
        }),
      }),

      entityInfo: Yup.object().when('userType', {
        is: UserType.ENTITY,
        then: Yup.object().shape({
          companyName: Yup.string()
            .required(t('form.errors.required')),
          number: Yup.string()
            .required(t('form.errors.required')),
          address: Yup.string()
            .required(t('form.errors.required')),
          city: Yup.string()
            .required(t('form.errors.required')),
          country: Yup.string()
            .required(t('form.errors.required')),
          index: Yup.string()
            .max(10, t('form.errors.max'))
            .required(t('form.errors.required')),
        }),
      }),

      payerInfo: Yup.object().when('isOtherStudent', {
        is: true,
        then: Yup.object().shape({
          name: Yup.string()
            .required(t('form.errors.required')),
          surname: Yup.string()
            .required(t('form.errors.required')),
          phone: Yup.string()
            .min(10, t('form.errors.min', '', { count: 10 }))
            .required(t('form.errors.required')),
          email: Yup.string()
            .email(t('form.errors.email'))
            .required(t('form.errors.required')),
          /* .test({
            name: 'unique',
            message: (
              <span>
                {t('form.errors.unique')}
                {' '}
                <NavLink to="/login">{t('login.submitBtn')}</NavLink>
              </span>
            ) as any,
            test: async (value) => authApi.checkEmail(value),
          }), */
        }),
      }),
    })
  ), [t])

  const setCheckEmail = useMemo(() => _.debounce(async (value: string) => {
    const result = await authApi.checkEmail(value)

    if (!result) {
      formRef.current.setFieldError('baseInfo.email', (
        <span>
          {t('form.errors.unique')}
          {' '}
          <NavLink to="/login">{t('login.submitBtn')}</NavLink>
        </span>
      ))
    }
  }, 1000), [t])

  const checkEmail = useCallback((e) => {
    setCheckEmail(e.target.value)
  }, [setCheckEmail])

  const initialValues = useMemo(() => {
    const values = {
      userType: formType,
      isBilling: false,
      isOtherStudent: false,

      baseInfo: {
        phone: '',
        email: '',
        password: '',
        passwordConfirmation: '',
      },

      fullInfo: {
        name: '',
        surname: '',
        country: formType === UserType.TEACHER ? 'fra' : '',
        city: '',
        address: '',
        communication: null as null | CommunicationOptionsType,
        messengers: '',
        index: '',
        timezone: `${moment.tz.guess()}|${+formatMinute(moment.tz(moment.tz.guess()).format('Z'))}`,
        description: '',
        experience: '',
      },

      billingInfo: {
        country: '',
        city: '',
        index: '',
        address: '',
      },
      entityInfo: {},
      payerInfo: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        communication: null as null | CommunicationOptionsType,
        messengers: '',
      },
    }
    if (formType === UserType.ENTITY) {
      values.entityInfo = {
        companyName: '',
        number: '',
        vat: '',
        address: '',
        city: '',
        country: '',
        index: '',
      }
    }

    return values
  }, [formType])

  useImperativeHandle(ref, () => formRef.current)

  const handleOtherStudent = useCallback((e: CheckboxChangeEvent) => {
    const { value } = e.target
    if (!value) {
      formRef.current.setValues((values: typeof initialValues) => ({
        ...values,
        isOtherStudent: true,
        baseInfo: {
          ...values.baseInfo,
          phone: '',
          email: '',
        },
        fullInfo: {
          ...values.fullInfo,
          name: '',
          surname: '',
          communication: '',
          messengers: '',
        },
        payerInfo: {
          name: values.fullInfo.name,
          surname: values.fullInfo.surname,
          phone: values.baseInfo.phone,
          email: values.baseInfo.email,
          communication: values.fullInfo.communication,
          messengers: values.fullInfo.messengers,
        },
      }))
      formRef.current.setTouched({
        ...formRef.current.touched,
        baseInfo: {
          ...formRef.current.touched?.baseInfo,
          phone: false,
          email: false,
        },
        fullInfo: {
          ...formRef.current.touched?.fullInfo,
          name: false,
          surname: false,
        },
      })
    } else {
      formRef.current.setValues((values: typeof initialValues) => ({
        ...values,
        isOtherStudent: false,
        baseInfo: {
          ...values.baseInfo,
          phone: values.payerInfo.phone,
          email: values.payerInfo.email,
        },
        fullInfo: {
          ...values.fullInfo,
          name: values.payerInfo.name,
          surname: values.payerInfo.surname,
          communication: values.payerInfo.communication,
          messengers: values.payerInfo.messengers,
        },
        payerInfo: initialValues.payerInfo,
      }))
    }
  }, [initialValues.payerInfo])

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const context = {
          userType: formType,
          isCreate,
        }

        return validationSchema
          .validate(values, { abortEarly: false, context })
          .then(() => ({}))
          .catch((err) => yupToFormErrors(err))
      }}
      onSubmit={onSubmit}
      innerRef={formRef}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <Row gutter={[20, 0]}>
            <Col xs={24} md={12}>
              <FieldFormik
                name={values.isOtherStudent ? 'payerInfo.name' : 'fullInfo.name'}
                autoComplete="name"
                placeholder={t('registration.name')}
                required
              />
            </Col>
            <Col xs={24} md={12}>
              <FieldFormik
                name={values.isOtherStudent ? 'payerInfo.surname' : 'fullInfo.surname'}
                autoComplete="surname"
                placeholder={t('registration.surname')}
                required
              />
            </Col>
            <Col xs={24} md={12}>
              <FieldFormik
                name={values.isOtherStudent ? 'payerInfo.email' : 'baseInfo.email'}
                autoComplete="email"
                type="email"
                placeholder={t('registration.email')}
                required
                onChange={(e) => { if (!values.isOtherStudent) checkEmail(e) }}
              />
            </Col>
            <Col xs={24} md={12}>
              <PhoneFieldFormik
                name={values.isOtherStudent ? 'payerInfo.phone' : 'baseInfo.phone'}
                placeholder={t('registration.phone')}
                preferredCountries={formType === UserType.TEACHER ? [] : ['ru', 'by', 'ua', 'kz']}
                onlyCountries={formType === UserType.TEACHER ? ['fr'] : undefined}
                country={formType === UserType.TEACHER ? 'fr' : 'ru'}
              />
            </Col>
          </Row>
          {(formType === UserType.INDIVIDUAL || formType === UserType.ENTITY || formType === UserType.TEACHER) && (
            <>
              <SelectFormik
                name={values.isOtherStudent ? 'payerInfo.communication' : 'fullInfo.communication'}
                options={communicationOptions.map((item) => ({ ...item, label: t(item.label) }))}
                placeholder={t('registration.communication')}
              />
              {(values.isOtherStudent
                ? values.payerInfo.communication
                : values.fullInfo.communication) === CommunicationOptionsType.MESSENGER && (
                <FieldFormik
                  name={values.isOtherStudent ? 'payerInfo.messengers' : 'fullInfo.messengers'}
                  placeholder={t('registration.messenger')}
                />
              )}
            </>
          )}

          {formType !== UserType.ENTITY && (
            <>
              <FieldFormik
                required
                name="fullInfo.address"
                autoComplete="address"
                placeholder={t('registration.address')}
              />
              <Row gutter={[10, 0]}>
                <Col xs={24} md={10}>
                  <CountrySelect
                    disabled={formType === UserType.TEACHER}
                    required
                    name="fullInfo.country"
                    placeholder={t('registration.country')}
                  />
                </Col>
                <Col xs={24} md={7}>
                  <FieldFormik required name="fullInfo.city" autoComplete="city" placeholder={t('registration.city')} />
                </Col>
                {(formType === UserType.INDIVIDUAL || formType === UserType.TEACHER) && (
                <Col xs={24} md={7}>
                  <FieldFormik
                    required
                    name="fullInfo.index"
                    autoComplete="index"
                    placeholder={t('registration.index')}
                  />
                </Col>
                )}
              </Row>
            </>
          )}

          {formType === UserType.ENTITY && (
            <>
              <FieldFormik
                name="entityInfo.companyName"
                autoComplete="companyName"
                placeholder={t('registration.legalInfo.companyName')}
                required
              />
              <FieldFormik
                name="entityInfo.address"
                autoComplete="address"
                placeholder={t('registration.legalInfo.address')}
                required
              />
              <Row gutter={[10, 0]}>
                <Col xs={24} md={10}>
                  <CountrySelect
                    required
                    name="entityInfo.country"
                    placeholder={t('registration.legalInfo.country')}
                  />
                </Col>
                <Col xs={24} md={7}>
                  <FieldFormik
                    required
                    name="entityInfo.city"
                    autoComplete="city"
                    placeholder={t('registration.legalInfo.city')}
                  />
                </Col>
                <Col xs={24} md={7}>
                  <FieldFormik
                    name="entityInfo.index"
                    autoComplete="index"
                    placeholder={t('registration.legalInfo.index')}
                    required
                  />
                </Col>
              </Row>
              <FieldFormik required name="entityInfo.number" placeholder={t('registration.legalInfo.number')} />
              <FieldFormik name="entityInfo.vat" placeholder={t('registration.legalInfo.vat')} />
            </>
          )}
          <TimezoneSelect name="fullInfo.timezone" placeholder={t('registration.timezone')} />

          {values.isBilling && (
            <Row gutter={[10, 0]}>
              <Col xs={24} md={12}>
                <CheckboxFormik name="isBilling" className="checkbox_formik__no">
                  <div className={cn(style.add_fields, style.add_fields_active)}>
                    <PlusOutlined />
                    <div>
                      <div className={style.add_fields_title}>{t('registration.billing.title')}</div>
                      <div className={style.add_fields_description}>
                        {formType === UserType.ENTITY
                          ? t('registration.billing.descriptionLegal') : t('registration.billing.description')}
                      </div>
                    </div>
                  </div>
                </CheckboxFormik>
              </Col>
              <Col span={24}>
                <FieldFormik
                  required
                  name="billingInfo.address"
                  placeholder={t('registration.billing.address')}
                />
              </Col>
              <Col xs={24} md={10}>
                <CountrySelect
                  required
                  name="billingInfo.country"
                  placeholder={t('registration.country')}
                />
              </Col>
              <Col xs={24} md={7}>
                <FieldFormik
                  required
                  name="billingInfo.city"
                  autoComplete="city"
                  placeholder={t('registration.city')}
                />
              </Col>
              <Col xs={24} md={7}>
                <FieldFormik name="billingInfo.index" autoComplete="index" placeholder={t('registration.index')} />
              </Col>
            </Row>
          )}
          {(formType === UserType.INDIVIDUAL || formType === UserType.ENTITY) && (
            <Row gutter={[10, 0]}>
              {!values.isBilling && (
                <Col xs={24} md={12}>
                  <CheckboxFormik name="isBilling" className="checkbox_formik__no">
                    <div className={style.add_fields}>
                      <PlusOutlined />
                      <div>
                        <div className={style.add_fields_title}>{t('registration.billing.title')}</div>
                        <div className={style.add_fields_description}>
                          {formType === UserType.ENTITY
                            ? t('registration.billing.descriptionLegal') : t('registration.billing.description')}
                        </div>
                      </div>
                    </div>
                  </CheckboxFormik>
                </Col>
              )}
              {!values.isOtherStudent && (
                <Col xs={24} md={12}>
                  <CheckboxFormik onChange={handleOtherStudent} name="isOtherStudent" className="checkbox_formik__no">
                    <div className={style.add_fields}>
                      <PlusOutlined />
                      <div>
                        <div className={style.add_fields_title}>{t('registration.studentData.title')}</div>
                        <div className={style.add_fields_description}>{t('registration.studentData.description')}</div>
                      </div>
                    </div>
                  </CheckboxFormik>
                </Col>
              )}
            </Row>
          )}
          {values.isOtherStudent && (
            <>
              <Row gutter={[10, 0]}>
                <Col xs={24} md={12}>
                  <CheckboxFormik onChange={handleOtherStudent} name="isOtherStudent" className="checkbox_formik__no">
                    <div className={cn(style.add_fields, style.add_fields_active)}>
                      <PlusOutlined />
                      <div>
                        <div className={style.add_fields_title}>{t('registration.studentData.title')}</div>
                        <div className={style.add_fields_description}>{t('registration.studentData.description')}</div>
                      </div>
                    </div>
                  </CheckboxFormik>
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="fullInfo.name"
                    autoComplete="name"
                    placeholder={t('registration.payerInfo.name')}
                    required
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="fullInfo.surname"
                    autoComplete="surname"
                    placeholder={t('registration.payerInfo.surname')}
                    required
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="baseInfo.email"
                    autoComplete="email"
                    type="email"
                    placeholder={t('registration.payerInfo.email')}
                    required
                    onChange={checkEmail}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <PhoneFieldFormik
                    name="baseInfo.phone"
                    placeholder={t('registration.payerInfo.phone')}
                    preferredCountries={formType === UserType.TEACHER ? [] : ['ru', 'by', 'ua', 'kz']}
                    onlyCountries={formType === UserType.TEACHER ? ['fr'] : undefined}
                    country={formType === UserType.TEACHER ? 'fr' : 'ru'}
                  />
                </Col>
              </Row>
              <SelectFormik
                name="fullInfo.communication"
                options={communicationOptions.map((item) => ({ ...item, label: t(item.label) }))}
                placeholder={t('registration.communication')}
              />
              {values.fullInfo.communication === CommunicationOptionsType.MESSENGER && (
                <FieldFormik name="fullInfo.messengers" placeholder={t('registration.messenger')} />
              )}
            </>
          )}

          {formType === UserType.TEACHER && (
            <>
              <FieldFormik
                name="fullInfo.description"
                placeholder={t('registration.nativeLanguages')}
              />
              <FieldFormik
                name="fullInfo.experience"
                placeholder={t('registration.aboutWorkExperience')}
              />
            </>
          )}

          {!isCreate && (
            <Row gutter={[10, 0]}>
              <Col xs={24} md={12}>
                <FieldFormik
                  Prefix={UnlockOutlined}
                  name="baseInfo.password"
                  type="password"
                  autoComplete="password"
                  placeholder={t('registration.password')}
                  required
                />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik
                  Prefix={UnlockOutlined}
                  name="baseInfo.passwordConfirmation"
                  type="password"
                  autoComplete="passwordConfirmation"
                  placeholder={t('registration.passwordConfirmation')}
                  required
                />
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  )
})
