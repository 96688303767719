import {
  useEffect, useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Table } from 'antd'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnProps } from 'antd/lib/table'
import {
  FilterValue, SorterResult, TablePaginationConfig,
} from 'antd/lib/table/interface'
import { DownloadOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'

import { AppDispatch } from '@src/store/store'
import { getPayloadHistory, paymentHistoryExport } from '@src/store/ducks/paymentHistory/thunks'
import {
  selectPayloadHistoryIsLoading, selectPayloadHistoryIsLoadingExport,
  selectPayloadHistoryList,
  selectPayloadHistoryMeta,
} from '@src/store/ducks/paymentHistory/selectors'
import { PaymentHistoryItemType } from '@src/store/ducks/paymentHistory/types'
import { isArray } from '@src/lib/utils'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { Role } from '@src/types/user'
import { AllowedTo } from '@src/components/common/AllowedTo/AllowedTo'
import { resetPayload } from '@src/store/ducks/paymentHistory/reducer'
import style from './PaymentHistory.module.scss'

export const PaymentHistory = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const dataSource = useSelector(selectPayloadHistoryList)
  const paginationMeta = useSelector(selectPayloadHistoryMeta)
  const isLoading = useSelector(selectPayloadHistoryIsLoading)
  const isLoadingExport = useSelector(selectPayloadHistoryIsLoadingExport)

  const columns = useMemo<ColumnProps<PaymentHistoryItemType>[]>(() => [
    {
      title: t('paymentHistory.columns.date'),
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (date) => moment(date).format('DD.MM.YYYY'),
    },
    {
      title: t('paymentHistory.columns.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `${amount}€`,
    },
    {
      title: t('paymentHistory.columns.number'),
      dataIndex: 'invoice',
      key: 'invoice',
      render: (invoice) => {
        if (!invoice?.number) return '-'

        return (
          <div>
            {invoice?.number}
          </div>
        )
      },
    },
    {
      title: t('paymentHistory.columns.factura'),
      dataIndex: 'invoice',
      key: 'numberFactura',
      render: (invoice) => {
        if (!invoice?.numberFactura) return '-'

        return (
          <div>
            <a
              className={style.number}
              href={`${process.env.REACT_APP_SITE_URL}/invoices/get/${invoice.id}`}
              download
              target="_blank"
              rel="noreferrer"
            >
              {invoice?.numberFactura}
            </a>
          </div>
        )
      },
    },
    {
      title: t('paymentHistory.columns.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('invoices.columns.payer'),
      dataIndex: 'payer',
      key: 'payer',
      width: 200,
      render: (payer) => (payer.companyName ? payer.companyName : `${payer.surname} ${payer.name}`),
    },
    {
      title: t('invoices.columns.form'),
      dataIndex: 'form',
      key: 'form',
      width: 200,
    },
    {
      dataIndex: 'url',
      key: 'url',
      align: 'center',
      render: (url) => url && (
        <a
          aria-label="download"
          href={url}
          download
          target="_blank"
          rel="noreferrer"
        >
          <DownloadOutlined style={{ color: '#3E97D7' }} />
        </a>
      ),
    },
  ], [t])

  useEffect(() => {
    dispatch(setBreadcrumbs([{ url: '/', title: t('controlPanel.title') }, { title: t('paymentHistory.title') }]))
  }, [dispatch, t])

  useEffect(() => {
    dispatch(getPayloadHistory(1))
  }, [dispatch])

  useEffect(() => () => { dispatch(resetPayload()) }, [dispatch])

  // сортировка
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PaymentHistoryItemType> | SorterResult<PaymentHistoryItemType>[],
  ) => {
    const sorterData = []
    if (isArray(sorter)) {
      sorter.forEach((item) => {
        sorterData.push({
          columns: item.field,
          order: item.order,
        })
      })
    } else {
      sorterData.push({
        columns: sorter.field,
        order: sorter.order,
      })
    }

    dispatch(getPayloadHistory(pagination.current || 1, {
      sorter: sorterData,
    }))
  }

  return (
    <div>
      <div className={style.header}>
        <h1 className="page_title">{t('paymentHistory.title')}</h1>
        <AllowedTo roles={[Role.ADMIN]}>
          <div>
            <NavLink to="/payment-history/create">
              <Button type="primary">{t('paymentHistory.create')}</Button>
            </NavLink>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                dispatch(paymentHistoryExport())
              }}
              type="primary"
              loading={isLoadingExport}
            >
              Экспорт
            </Button>
          </div>
        </AllowedTo>
      </div>

      <p className="page_description">{t('paymentHistory.description')}</p>

      {/* <SearchInput placeholder={t('paymentHistory.search')} name="term" onChange={handleSearch} /> */}

      <Table<PaymentHistoryItemType>
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
        className={style.table}
        scroll={{ x: 'max-content' }}
        pagination={{
          total: paginationMeta.totalCount,
          current: paginationMeta.currentPage,
          pageSize: paginationMeta.perPage,
          showSizeChanger: false,
        }}
      />
    </div>
  )
}
