import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { Col, Row, Select } from 'antd'
import moment from 'moment-timezone'
import cn from 'classnames'

import { AppDispatch } from '@src/store/store'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import {
  selectLessonsArr,
  selectLessonsIsLoading,
} from '@src/store/ducks/lessons/selectors'
import { getLessons } from '@src/store/ducks/lessons/thunks'
import { Preloader } from '@src/components/common/Preloader/Preloader'
import { LessonTypeEnum } from '@src/types/lessons'
import style from './Lessons.module.scss'

export const Lessons = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const [typeValue, setTypeValue] = useState<number | undefined>(undefined)

  const lessonsArr = useSelector(selectLessonsArr)
  const isLoading = useSelector(selectLessonsIsLoading)

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { url: '/', title: t('controlPanel.title') },
        { title: t('lessons.title') },
      ]),
    )
  }, [dispatch, t])

  useEffect(() => {
    dispatch(getLessons())
  }, [dispatch])

  const lessonsCheckArr = useMemo(() => {
    if (typeValue) {
      return lessonsArr.filter((item) => item.calendarId === typeValue)
    }
    return lessonsArr
  }, [lessonsArr, typeValue])

  return (
    <div className={style.lessons}>
      <div className={style.lessons__header}>
        <h1 className="page_title">{t('lessons.title')}</h1>
        <div className={style.select__container}>
          <Select
            className={style.select__select}
            placeholder={t('lessons.typePlaceholder')}
            allowClear
            onChange={setTypeValue}
            value={typeValue}
          >
            {lessonsArr.map((option) => (
              <Select.Option
                key={option.calendarId}
                value={option.calendarId}
              >
                {option.title}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <Preloader loading={isLoading}>
        {!lessonsCheckArr.length && (
          <div className={style.lessons__noLessons}>
            {t('lessons.noLessons')}
          </div>
        )}
        <div>
          {lessonsCheckArr.map((items) => (
            <div key={items.calendarId} className={style.lesson__container}>
              <div className={style.lesson__titleContainer}>
                <div className={style.lesson__titleContainer__countLessons}>
                  {items.lessonsDescription}
                </div>
                <div className={style.lesson__title}>{items.title}</div>
              </div>
              <Row gutter={[30, 15]}>
                {items.lessons.map((lesson) => (
                  <Col key={lesson.id} xs={24} md={12} xl={8} xxl={6}>
                    <div
                      className={
                        cn(style.lesson__description, lesson.isFinished && style.lesson__description__disabled)
                      }
                    >
                      <div className={style.timeContainer}>
                        <div className={style.data}>
                          {moment(lesson.start).format('DD MMMM HH:mm')}
                        </div>
                        <div className={style.time}>
                          {lesson.lessonDuration}
                        </div>
                      </div>
                      <div className={style.type}>
                        {lesson.type === LessonTypeEnum.INDIVIDUAL ? t('lessons.individuals') : t('lessons.group')}
                      </div>
                      <div className={style.teacher}>{lesson.teacher}</div>
                      {lesson.link && (
                        <a
                          href={lesson.link}
                          className={style.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('lessons.link')}
                        </a>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </div>
      </Preloader>
    </div>
  )
}
