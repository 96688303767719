import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { NavLink } from 'react-router-dom'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import error404 from '@src/assets/404.svg'
import { selectAuthIsAuth } from '@src/store/ducks/auth/selectors'
import { selectUser } from '@src/store/ducks/user/selectors'
import style from './Error.module.scss'

export const Error404 = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()

  const isAuth = useSelector(selectAuthIsAuth)
  const user = useSelector(selectUser)
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { url: '/', title: t('controlPanel.title') },
        { title: t('error404.title') },
      ]),
    )
  }, [dispatch, t])

  return (
    <div className={style.container}>
      <div className={style.not_found_image}>
        <img src={error404} alt="error404" />
      </div>
      <div className={style.not_found_content}>
        <h1>404</h1>
        <div className={style.description}>{t('error404.error')}</div>
        <div className={style.btn_error}>
          {isAuth ? (
            <NavLink to={`/profile/${user.id}`}>
              <Button size="large" type="primary">
                {t('error404.back')}
              </Button>
            </NavLink>
          ) : (
            <NavLink to="login">
              <Button size="large" type="primary">
                {t('error404.back')}
              </Button>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  )
}
