import { AppDispatch } from '@src/store/store'
import { lessonsApi } from '@src/api/lessons-api'
import moment from 'moment-timezone'
import { setIsLoading, setLessonsList } from './reducer'

export const getLessons = () => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  try {
    const result = await lessonsApi.getLessons()
    dispatch(setLessonsList(result.map((course) => (
      {
        ...course,
        lessons: course.lessons.sort((a, b) => +moment(a.start) - +moment(b.start)),
      }))))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}
