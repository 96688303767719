import {
  ChangeEvent, FC, useMemo, useState,
} from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import _ from 'lodash'

type SearchInputProps = {
  placeholder?: string
  name: string
  onChange: (value: string) => void
  initialValue?: string | null
  className?: string
}

export const SearchInput: FC<SearchInputProps> = ({
  placeholder, name, onChange, initialValue, className,
}) => {
  const [searchValue, setSearchValue] = useState<string>(initialValue ?? '')

  const setSearch = useMemo(() => _.debounce((value: string) => {
    onChange(value)
  }, 1000), [onChange])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearch(value)
    setSearchValue(value)
  }

  return (
    <Input
      prefix={<SearchOutlined style={{ color: '#BFBFBF' }} />}
      placeholder={placeholder}
      name={name}
      onChange={handleSearch}
      value={searchValue}
      className={className}
      allowClear
    />
  )
}
