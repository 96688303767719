import { api } from '@src/api/api'
import { User, UserStatus } from '@src/types/user'
import { Key } from 'antd/lib/table/interface'

export const userApi = {
  getMe() {
    return api.get<User>('/v1/users/me')
  },
  setLang(lang: string) {
    return api.put('/v1/users/profile/set-lang', { lang })
  },
  delete(userIds: Key[]) {
    return api.del('/v1/users/delete', { userIds })
  },
  updateStatus(userId: number, statusCode: string) {
    return api.put<UserStatus>('/v1/users/profile/update-status', { userId, statusCode })
  },
  export() {
    return api.get('/v1/export/students', {}, {}, 'blob')
  },
}
