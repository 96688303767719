import { FC } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { Header } from '@src/components/Header/Header'
import { SitebarLeft } from '@src/components/Sitebar/SitebarLeft/SitebarLeft'
import { SitebarRight } from '@src/components/Sitebar/SitebarRight/SitebarRight'
import { selectAppTypeRightSitebar } from '@src/store/ducks/app/selectors'
import { TypeRightSitebarEnum } from '@src/store/ducks/app/reducer'
import style from './PageContainer.module.scss'

export const PageContainer: FC = ({ children }) => {
  const typeRightSitebar = useSelector(selectAppTypeRightSitebar)

  return (
    <div className={style.container}>
      <SitebarLeft />
      <div className={cn(style.main, typeRightSitebar !== TypeRightSitebarEnum.DEFAULT && style.main_right)}>
        <Header />
        {children}
      </div>
      <SitebarRight />
    </div>
  )
}
