export enum ScheduleModalEnum {
  INFO = 0,
  CREATE = 1,
  EDIT = 2,
  DELETE = 3,
}

export type ScheduleFiltersType = {
  courses: Array<{
    id: number
    name: string
    categoryId: number
  }>
  teachers: Array<{
    id: number
    userId: number
    name: string
    surname: string
  }>
}
