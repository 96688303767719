import { AppDispatch, RootState } from '@src/store/store'
import {
  setDialogs, setIsLast, setIsLoading, setIsLoadingSend, setLastId, setMessages,
} from '@src/store/ducks/chat/reducer'
import { chatApi } from '@src/api/chat-api'
import { UploadFile } from 'antd/lib/upload/interface'

export const getDialogs = () => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  try {
    const result = await chatApi.getDialogs()
    dispatch(setDialogs(result))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const setRead = (data: number[], dialogId: number) => () => {
  try {
    chatApi.setRead(data, dialogId)
  } catch (err) {
    console.error(err)
  }
}

export const getMessages = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { chat, user } = getState()
  if (!chat.isLast) {
    dispatch(setIsLoading(true))
    try {
      if (chat.dialogId) {
        const result = await chatApi.getMessages(chat.dialogId, chat.lastId)
        const readData = result.data
          .filter((item) => item.userIdFrom !== user.id && !item.isRead)
          .map((item) => item.id)

        if (readData.length) dispatch(setRead(readData, chat.dialogId))

        dispatch(setLastId(result.data[0].id))
        dispatch(setIsLast(result.isLast))
        dispatch(setMessages(result.data.reverse()))
      }
    } catch (err) {
      console.error(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  }
}

export const sendMessage = (
  message: string, fileList: UploadFile[], parentId?: number,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { chat } = getState()
  dispatch(setIsLoadingSend(true))
  try {
    if (chat.dialogId) {
      let files: string[] = []
      if (fileList.length) {
        const filesPromise = fileList.map((item) => {
          const formData = new FormData()
          // @ts-ignore
          formData.append('attachments', item.originFileObj)

          return chatApi.storeFile(formData)
        })

        files = await Promise.all(filesPromise)
      }
      await chatApi.sendMessage(message, chat.dialogId, parentId, files)
    }
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingSend(false))
  }
}
