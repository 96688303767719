import { api, GetItemsType } from '@src/api/api'
import { GetInvoicesFiltersType, InvoicesFindType, InvoicesItemType } from '@src/store/ducks/invoices/types'

export const invoicesApi = {
  getAll(page: number, filters?: any) {
    return api.get<GetItemsType<InvoicesItemType>>('/v1/invoices/', { page, filters })
  },
  find(term: string, isCheck?: boolean) {
    return api.get<InvoicesFindType[]>('/v1/invoices/find', { term, isCheck })
  },
  create(data: any) {
    return api.post('/v1/invoices/create', data, {}, { lang: 'fr' })
  },
  createAvoir(data: any) {
    return api.post('/v1/avoirs/create', data, {}, { lang: 'fr' })
  },
  getFilters() {
    return api.get<GetInvoicesFiltersType>('/v1/invoices/filters')
  },
  export() {
    return api.get('/v1/export/invoices', {}, {}, 'blob')
  },
}
