import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { ReactComponent as Lick } from '@src/assets/link.svg'
import style from './MultiLink.module.scss'

type MultiLinkProps = {
  url: string
  title: string
  className?: string
  isIcon?: boolean
}

export const MultiLink: FC<MultiLinkProps> = ({
  url, title, className, isIcon,
}) => {
  if (url[0] === '/') {
    return (
      <NavLink className={cn(style.link, className)} to={url}>
        {isIcon && <Lick />}
        {title}
      </NavLink>
    )
  }

  return (
    <a
      className={cn(style.link, className)}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {isIcon && <Lick />}
      {title}
    </a>
  )
}
