import { Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PrivateRoute } from '@src/routes/PrivateRoute'
import { Role } from '@src/types/user'
import { Login } from '@src/pages/Login/Login'
import { Registration } from '@src/pages/Registration/Registration'
import { ForgotPassword } from '@src/pages/ForgotPassword/ForgotPassword'
import { ResetPassword } from '@src/pages/ResetPassword/ResetPassword'
import { Materials } from '@src/pages/Materials/Materials'
import { Profile } from '@src/pages/Profile/Profile'
import { selectUser } from '@src/store/ducks/user/selectors'
import { Courses } from '@src/pages/Courses/Courses'
import { PaymentHistory } from '@src/pages/PaymentHistory/PaymentHistory'
import { Lessons } from '@src/pages/Lessons/Lessons'
import { CreateMaterials } from '@src/pages/CreateMaterials/CreateMaterials'
import { Students } from '@src/pages/Students/Students'
import { StudentCreate } from '@src/pages/Students/StudentCreate/StudentCreate'
import { ChangePassword } from '@src/pages/ChangePassword/ChangePassword'
import { Error404 } from '@src/pages/Error404/Error404'
import { Schedule } from '@src/pages/Schedule/Schedule'
import { Teachers } from '@src/pages/Teachers/Teachers'
import { TeachersSchedule } from '@src/pages/Teachers/TeachersSchedule/TeachersSchedule'
import { TeachersCreate } from '@src/pages/Teachers/TeachersCreate/TeachersCreate'
import { Secretary } from '@src/pages/Secretary/Secretary'
import { SecretaryCreate } from '@src/pages/Secretary/SecretaryCreate/SecretaryCreate'
import { Organizers } from '@src/pages/Organizers/Organizers'
import { OrganizersCreate } from '@src/pages/Organizers/OrganizersCreate/OrganizersCreate'
import { Invoices } from '@src/pages/Invoices/Invoices'
import { InvoicesCreate } from '@src/pages/InvoicesCreate/InvoicesCreate'
import { PaymentCreate } from '@src/pages/PaymentCreate/PaymentCreate'

/* type RoutesOptType = {
  path: string
  component: ReactNode
  exact?: boolean
  private?: boolean
  roles?: Array<Role>
  routes?: Array<RoutesOptType>
}

const roo: RoutesOptType[] = [
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/registration',
    exact: true,
    component: Registration,
  },
  {
    path: '/registration',
    exact: true,
    component: Registration,
    private: true,
    roles: [Role.GUEST],
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
    private: true,
    roles: [Role.GUEST],
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
    private: true,
    roles: [Role.GUEST],
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPassword,
    private: true,
    roles: [Role.GUEST],
  },
  {
    path: '/profile/:id/:worksheet?',
    exact: true,
    component: Profile,
    private: true,
    roles: [Role.STUDENT, Role.ADMIN, Role.TEACHER, Role.SECRETARY],
  },
  {
    path: '/courses',
    exact: true,
    component: Courses,
    private: true,
    roles: [Role.STUDENT],
  },
  {
    path: '/payment-history',
    exact: true,
    component: PaymentHistory,
    private: true,
    roles: [Role.STUDENT],
  },
  {
    path: '/lessons',
    exact: true,
    component: Lessons,
    private: true,
    roles: [Role.STUDENT],
  },
  {
    path: '/schedule/:table?',
    exact: true,
    component: Schedule,
    private: true,
    roles: [Role.ADMIN],
  },
  {
    path: '/materials',
    exact: true,
    component: Materials,
    private: true,
    roles: [Role.STUDENT, Role.ADMIN, Role.TEACHER, Role.SECRETARY],
    routes: [
      {
        path: '/create',
        exact: true,
        component: CreateMaterials,
        private: true,
        roles: [Role.ADMIN],
      },
      {
        path: '/edit/:id',
        exact: true,
        component: CreateMaterials,
        private: true,
        roles: [Role.ADMIN],
      },
    ],
  },
  {
    path: '/students',
    exact: true,
    component: Students,
    private: true,
    roles: [Role.ADMIN, Role.TEACHER, Role.SECRETARY],
    routes: [
      {
        path: '/create',
        exact: true,
        component: StudentCreate,
        private: true,
        roles: [Role.ADMIN, Role.SECRETARY],
      },
    ],
  },
  /!* {
    path: '/reset-password',
    exact: true,
    component: ResetPassword,
    private: true,
    roles: [Role.GUEST],
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPassword,
    private: true,
    roles: [Role.GUEST],
  }, *!/
]

const mapRoute: any = (routes: RoutesOptType[], prefix?: string) => routes.map((route) => {
  const path = `${prefix ?? ''}${route.path}`
  if (route.routes) {
    return mapRoute(route.routes, path)
  }
  if (route.private) {
    console.log(path)
    return (
      <PrivateRoute key={path} path={path} exact={route.exact} roles={route.roles}>
        {route.component}
      </PrivateRoute>
    )
  }
  return (
    <Route key={path} path={path} exact={route.exact}>
      {route.component}
    </Route>
  )
}) */

export const Routes = () => {
  const user = useSelector(selectUser)
  return (
    <Switch>
      <Route path="/login" exact>
        <Login />
      </Route>
      <PrivateRoute path="/registration" exact roles={[Role.GUEST]}>
        <Registration />
      </PrivateRoute>
      <PrivateRoute path="/forgot-password" exact roles={[Role.GUEST]}>
        <ForgotPassword />
      </PrivateRoute>
      <PrivateRoute path="/reset-password" exact roles={[Role.GUEST]}>
        <ResetPassword />
      </PrivateRoute>

      <PrivateRoute
        path="/profile"
        exact
        roles={[Role.STUDENT, Role.ADMIN, Role.TEACHER, Role.SECRETARY, Role.ORGANIZER]}
      >
        <Redirect to={`/profile/${user.id}`} />
      </PrivateRoute>
      <PrivateRoute
        path="/profile/:id/:worksheet?"
        exact
        roles={[Role.STUDENT, Role.ADMIN, Role.TEACHER, Role.SECRETARY, Role.ORGANIZER]}
      >
        <Profile />
      </PrivateRoute>

      {/* <PrivateRoute path="/settings" exact roles={[Role.TEACHER, Role.ADMIN, Role.STUDENT]}>
      <Settings />
    </PrivateRoute> */}

      <PrivateRoute path="/courses" exact roles={[Role.STUDENT]}>
        <Courses />
      </PrivateRoute>

      <PrivateRoute path="/payment-history" exact roles={[Role.ADMIN, Role.STUDENT]}>
        <PaymentHistory />
      </PrivateRoute>
      <PrivateRoute path="/payment-history/create" exact roles={[Role.ADMIN]}>
        <PaymentCreate />
      </PrivateRoute>
      <PrivateRoute path="/invoices" exact roles={[Role.ADMIN, Role.STUDENT]}>
        <Invoices />
      </PrivateRoute>
      <PrivateRoute path="/invoices/create" exact roles={[Role.ADMIN]}>
        <InvoicesCreate />
      </PrivateRoute>
      <PrivateRoute path="/invoices/edit/:id" exact roles={[Role.ADMIN]}>
        <InvoicesCreate />
      </PrivateRoute>

      <PrivateRoute path="/lessons" exact roles={[Role.STUDENT]}>
        <Lessons />
      </PrivateRoute>
      <PrivateRoute path="/schedule/:table?" exact roles={[Role.ADMIN, Role.SECRETARY, Role.TEACHER, Role.ORGANIZER]}>
        <Schedule />
      </PrivateRoute>

      <PrivateRoute
        path="/materials"
        exact
        roles={[Role.STUDENT, Role.ADMIN, Role.TEACHER, Role.SECRETARY, Role.ORGANIZER]}
      >
        <Materials />
      </PrivateRoute>
      <PrivateRoute path="/materials/create" exact roles={[Role.ADMIN, Role.TEACHER, Role.SECRETARY, Role.ORGANIZER]}>
        <CreateMaterials />
      </PrivateRoute>
      <PrivateRoute path="/materials/edit/:id" exact roles={[Role.ADMIN, Role.TEACHER, Role.SECRETARY, Role.ORGANIZER]}>
        <CreateMaterials />
      </PrivateRoute>

      <PrivateRoute path="/students" exact roles={[Role.ADMIN, Role.TEACHER, Role.SECRETARY, Role.ORGANIZER]}>
        <Students />
      </PrivateRoute>
      <PrivateRoute path="/students/create" exact roles={[Role.ADMIN, Role.SECRETARY, Role.ORGANIZER]}>
        <StudentCreate />
      </PrivateRoute>

      <PrivateRoute path="/teachers" exact roles={[Role.ADMIN, Role.SECRETARY, Role.ORGANIZER]}>
        <Teachers />
      </PrivateRoute>
      <PrivateRoute path="/teachers/schedule/:id" exact roles={[Role.ADMIN, Role.ORGANIZER, Role.SECRETARY]}>
        <TeachersSchedule />
      </PrivateRoute>
      <PrivateRoute path="/teachers/create" exact roles={[Role.ADMIN, Role.ORGANIZER]}>
        <TeachersCreate />
      </PrivateRoute>

      <PrivateRoute path="/secretary" exact roles={[Role.ADMIN, Role.ORGANIZER]}>
        <Secretary />
      </PrivateRoute>
      <PrivateRoute path="/secretary/create" exact roles={[Role.ADMIN]}>
        <SecretaryCreate />
      </PrivateRoute>

      <PrivateRoute path="/organizers" exact roles={[Role.ADMIN]}>
        <Organizers />
      </PrivateRoute>
      <PrivateRoute path="/organizers/create" exact roles={[Role.ADMIN]}>
        <OrganizersCreate />
      </PrivateRoute>

      <PrivateRoute
        path="/change-password"
        exact
        roles={[Role.TEACHER, Role.ADMIN, Role.STUDENT]}
      >
        <ChangePassword />
      </PrivateRoute>

      <Route path="/" exact>
        <Redirect to="/profile" />
      </Route>

      <Route path="*">
        <Error404 />
      </Route>
    </Switch>
  )
}
