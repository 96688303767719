import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { Button } from 'antd'

import { EventCalendarType } from '@src/store/ducks/schedule/types'
import style from '@src/components/ScheduleModal/ScheduleModal.module.scss'
import { ScheduleModalEnum } from '@src/types/schedule'
import { LessonTypeEnum } from '@src/types/lessons'

type InfoLessonProps = {
  lesson: EventCalendarType
  setModalType: (type: ScheduleModalEnum) => void
}

export const InfoLesson: FC<InfoLessonProps> = ({ lesson, setModalType }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className={style.modal_type}>
        {lesson.extendedProps.student ? t('schedule.individual') : t('schedule.group')}
      </div>
      <div className={style.modal_time}>
        <span className={style.modal_date}>{moment(lesson.extendedProps.start).format('DD MMMM')}</span>
        {' '}
        {moment(lesson.extendedProps.start).format('HH:mm')}
        {' - '}
        {moment(lesson.extendedProps.end).format('HH:mm')}
      </div>
      <div className={style.modal_title}>{lesson.extendedProps.calendar.course.name}</div>
      <div className={style.modal_info}>
        {t('schedule.flow')}
        {': '}
        <span className="bold">{lesson.extendedProps.calendar.title || lesson.extendedProps.calendar.id}</span>
      </div>
      <div className={style.modal_info}>
        {t('schedule.teacher')}
        {': '}
        <NavLink to={`/profile/${lesson.extendedProps.teacher.userId}`} className="bold">
          {lesson.extendedProps.teacher.surname}
          {' '}
          {lesson.extendedProps.teacher.name}
        </NavLink>
      </div>
      {lesson.extendedProps.student && (
        <div className={style.modal_info}>
          {t('schedule.student')}
          {': '}
          <NavLink to={`/profile/${lesson.extendedProps.student.userId}`} className="bold">
            {lesson.extendedProps.student.surname}
            {' '}
            {lesson.extendedProps.student.name}
          </NavLink>
        </div>
      )}

      {moment(lesson.extendedProps.start).isAfter(new Date()) && (
        <div className={style.modal_actions}>
          <Button className="btn_round" onClick={() => setModalType(ScheduleModalEnum.EDIT)} type="primary">
            {t('schedule.edit')}
          </Button>
          {lesson.extendedProps.type === LessonTypeEnum.GROUP && (
            <Button
              onClick={() => setModalType(ScheduleModalEnum.DELETE)}
              className="btn_delete btn_round"
            >
              {t('schedule.delete')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
