import { FC, useCallback, useMemo } from 'react'
import { Table } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { DeleteOutlined } from '@ant-design/icons'
import cn from 'classnames'
import moment from 'moment-timezone'
import { Key, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { NavLink } from 'react-router-dom'

import { selectScheduleEvents, selectScheduleIsLoading, selectScheduleMeta } from '@src/store/ducks/schedule/selectors'
import { DataSourceType } from '@src/store/ducks/schedule/types'
import { ReactComponent as Pencil } from '@src/assets/pencil.svg'
import { ScheduleModalEnum } from '@src/types/schedule'
import { LessonTypeEnum } from '@src/types/lessons'
import { isArray } from '@src/lib/utils'
import style from './ScheduleTable.module.scss'

type ScheduleTableProps = {
  setModalType: (type: ScheduleModalEnum) => void
  setIsOpenModal: (state: boolean) => void
  setModalEvent: (data: any) => void
  setParamsFilters: (data: any) => void
}

export const ScheduleTable: FC<ScheduleTableProps> = ({
  setModalType, setIsOpenModal, setModalEvent, setParamsFilters,
}) => {
  const { t } = useTranslation()
  const events = useSelector(selectScheduleEvents)
  const isLoading = useSelector(selectScheduleIsLoading)
  const paginationMeta = useSelector(selectScheduleMeta)

  const dataSource = useMemo<DataSourceType[]>(() => events.map((event) => ({
    type: event.extendedProps.type,
    key: event.id,
    calendar: event.extendedProps.calendar,
    course: event.extendedProps.calendar.course,
    teacher: event.extendedProps.teacher,
    student: event.extendedProps.student,
    time: {
      start: event.start,
      end: event.end,
    },
  })), [events])

  const handleDelete = useCallback((event) => {
    setModalType(ScheduleModalEnum.DELETE)
    setIsOpenModal(true)
    setModalEvent(event)
  }, [setIsOpenModal, setModalEvent, setModalType])

  const handleEdit = useCallback((event) => {
    setModalType(ScheduleModalEnum.EDIT)
    setIsOpenModal(true)
    setModalEvent(event)
  }, [setIsOpenModal, setModalEvent, setModalType])

  const columns = useMemo<ColumnProps<DataSourceType>[]>(() => [
    {
      title: t('schedule.typeTitle'),
      dataIndex: 'type',
      key: 'type',
      // @ts-ignore
      render: (type) => t(`schedule.${type}`),
    },
    {
      title: t('schedule.flow'),
      dataIndex: 'calendar',
      key: 'calendar',
      render: (calendar) => calendar.title || calendar.id,
    },
    {
      title: t('schedule.course'),
      dataIndex: 'course',
      key: 'course',
      render: (course) => course.name,
    },
    {
      title: t('schedule.teacher'),
      dataIndex: 'teacher',
      key: 'teacher',
      render: ({ userId, surname, name }) => (
        <NavLink to={`/profile/${userId}`}>
          {`${surname} ${name}`}
        </NavLink>
      ),
    },
    {
      title: t('schedule.student'),
      dataIndex: 'student',
      key: 'student',
      render: (student) => (student ? (
        <NavLink to={`/profile/${student.userId}`}>
          {`${student.surname} ${student.name}`}
        </NavLink>
      ) : '—'),
    },
    {
      title: t('schedule.selectTime'),
      dataIndex: 'time',
      key: 'time',
      render: ({ start, end }) => `${moment(start).format('DD.MM.YYYY HH:mm')} - ${moment(end).format('HH:mm')}`,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          {moment(record.time.start).isAfter(new Date()) && (
            <>
              <button
                type="button"
                className={cn(style.actions_item, style.actions_item__pencil)}
                onClick={() => handleEdit(events.find((item) => item.id === record.key))}
              >
                <Pencil />
              </button>
              {record.type === LessonTypeEnum.GROUP && (
                <button
                  className={cn(style.actions_item, style.actions_item__delete)}
                  aria-label="delete"
                  type="button"
                  onClick={() => handleDelete(events.find((item) => item.id === record.key))}
                >
                  <DeleteOutlined />
                </button>
              )}
            </>
          )}
        </div>
      ),
    },
  ], [events, handleDelete, handleEdit, t])

  // сортировка
  const handleTableChange = (
    pagination: TablePaginationConfig,
    _: any,
    sorter: SorterResult<DataSourceType> | SorterResult<DataSourceType>[],
  ) => {
    const sorterData: Array<{ columns: Key | readonly Key[], order: string }> = []
    if (isArray(sorter)) {
      sorter.forEach((item) => {
        if (item.order && item.columnKey) {
          sorterData.push({
            columns: item.columnKey,
            order: item.order,
          })
        }
      })
    } else if (sorter.order && sorter.columnKey) {
      sorterData.push({
        columns: sorter.columnKey,
        order: sorter.order,
      })
    }
    setParamsFilters((prevState: any) => ({
      ...prevState,
      page: pagination.current || 1,
      filters: { ...prevState.filters, sorter: sorterData },
    }))
  }

  return (
    <div>
      <Table<DataSourceType>
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
        className={style.table}
        scroll={{ x: 'max-content' }}
        pagination={{
          total: paginationMeta?.totalCount,
          current: paginationMeta?.currentPage,
          pageSize: paginationMeta?.perPage,
          showSizeChanger: false,
        }}
      />
    </div>
  )
}
