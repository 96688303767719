import { useMemo } from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'

import { formatMinute } from '@src/lib/time'

type UseTimezonesType = {
  label: string
  value: string
  time: string
}

export const useTimezones = (): UseTimezonesType[] => useMemo(() => {
  const timeZones: any = moment.tz.names()
  const offsetTmz: Array<{label: string, value: string, time: string}> = []

  Object.keys(timeZones).forEach((key) => {
    const tz = formatMinute(moment.tz(timeZones[key]).format('Z'))

    const x = (+tz === 0) ? 0 : parseInt(tz, 10).toFixed(2)

    const timeZone = {
      label: `(GMT${moment.tz(timeZones[key]).format('Z')})${timeZones[key]}`,
      value: `${timeZones[key]}|${+tz}`,
      time: `${x}`,
    }
    offsetTmz.push(timeZone)
  })

  return _.sortBy(offsetTmz, [(el) => -(el.time)])
}, [])
