import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  Curator,
  CuratorType, Role, UserFullInfo, UserWorksheet,
} from '@src/types/user'
import { HttpStatus } from '@src/types/httpStatus'

type SetProfileUserPayloadAction = PayloadAction<{
  id: null | number,
  email: null | string,
  roleId: Role,
  phone: null | string,
  worksheet: UserWorksheet,
  fullInfo: UserFullInfo,
}>

export const initialState = {
  user: {
    id: null as null | number,
    email: null as null | string,
    roleId: Role.GUEST as Role,
    phone: null as null | string,
    fullName: '',
    worksheet: {} as UserWorksheet,
    fullInfo: {} as UserFullInfo,
  },
  isLoading: true,
  isLoadingSave: false,
  isEdit: false,
  backError: null as null | object | string,
  error: null as null | HttpStatus,
  curators: [] as CuratorType[],
  isLoadingUpdateCurator: false,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileUser(state, { payload }: SetProfileUserPayloadAction) {
      state.user = {
        ...payload,
        fullName: `${payload.fullInfo.name} ${payload.fullInfo.surname}`,
      }
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setIsLoadingSave(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingSave = payload
    },
    setError(state, { payload }: PayloadAction<HttpStatus>) {
      state.error = payload
    },
    setIsEdit(state, { payload }: PayloadAction<boolean>) {
      state.isEdit = payload
    },
    setBackError(state, { payload }: PayloadAction<object | null | string>) {
      state.backError = payload
    },
    updateProfilePhoto(state, { payload }: PayloadAction<string>) {
      state.user.fullInfo.photo = payload
    },
    setCurators(state, { payload }: PayloadAction<CuratorType[]>) {
      state.curators = payload
    },
    setIsLoadingUpdateCurator(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingUpdateCurator = payload
    },
    updateCuratorAC(state, { payload }: PayloadAction<Curator>) {
      state.user.fullInfo.curator = payload
    },
    reset: () => initialState,
  },
})

export type ProfileStateType = typeof initialState

export const {
  setProfileUser, setIsLoading, setError, reset, setIsLoadingSave, setIsEdit, setBackError, updateProfilePhoto,
  setCurators, setIsLoadingUpdateCurator, updateCuratorAC,
} = profileSlice.actions

export const profileReducer = profileSlice.reducer
