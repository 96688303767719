import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SecretaryItemType } from '@src/store/ducks/secretary/types'
import { ResponseMetaType } from '@src/api/api'
import { UserStatus } from '@src/types/user'

export const initialState = {
  list: [] as Array<SecretaryItemType & { fullName: string, key: number }>,
  isLoading: false,
  meta: {
    currentPage: 1,
    lastPage: 0,
    perPage: 0,
    totalCount: 0,
  },
}

const secretarySlice = createSlice({
  name: 'secretary',
  initialState,
  reducers: {
    setSecretaryList(state, { payload }: PayloadAction<SecretaryItemType[]>) {
      state.list = payload.map((item) => ({ ...item, key: item.id, fullName: `${item.surname} ${item.name}` }))
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setSecretaryMeta(state, { payload }: PayloadAction<ResponseMetaType>) {
      state.meta = payload
    },
    updateSecretaryStatus(state, { payload }: PayloadAction<{userId: number, status: UserStatus}>) {
      state.list = state.list.map((item) => {
        if (item.user.id === payload.userId) {
          return {
            ...item,
            user: {
              ...item.user,
              status: payload.status,
            },
          }
        }
        return item
      })
    },
  },
})

export type SecretaryStateType = typeof initialState

export const {
  setSecretaryList, setIsLoading, setSecretaryMeta, updateSecretaryStatus,
} = secretarySlice.actions

export const secretaryReducer = secretarySlice.reducer
