import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaymentHistoryItemType } from '@src/store/ducks/paymentHistory/types'
import { ResponseMetaType } from '@src/api/api'
import { InvoicesFindType } from '@src/store/ducks/invoices/types'

export const initialState = {
  list: [] as PaymentHistoryItemType[],
  isLoading: false,
  meta: {
    currentPage: 1,
    lastPage: 0,
    perPage: 0,
    totalCount: 0,
  },
  formData: {
    invoices: [] as InvoicesFindType[],
  },
  isLoadingExport: false,
}

const paymentHistorySlice = createSlice({
  name: 'paymentHistory',
  initialState,
  reducers: {
    setPaymentHistoryList(state, { payload }: PayloadAction<PaymentHistoryItemType[]>) {
      state.list = payload.map((item) => ({ ...item, key: item.id }))
    },
    setPaymentHistoryMeta(state, { payload }: PayloadAction<ResponseMetaType>) {
      state.meta = payload
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setPaymentFormData(state, { payload }: PayloadAction<
      { invoices: InvoicesFindType[] }
    >) {
      state.formData = {
        ...state.formData,
        ...payload,
      }
    },
    setIsLoadingExport(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingExport = payload
    },
    resetPayload: () => initialState,
  },
})

export type PaymentHistoryStateType = typeof initialState

export const {
  setPaymentHistoryList, setIsLoading, setPaymentHistoryMeta, setPaymentFormData, resetPayload, setIsLoadingExport,
} = paymentHistorySlice.actions

export const paymentHistoryReducer = paymentHistorySlice.reducer
