import {
  useCallback, useEffect, useRef, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { RegistrationForm } from '@src/pages/Registration/components/RegistrationForm/RegistrationForm'
import { AppDispatch } from '@src/store/store'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { UserType } from '@src/types/user'
import { createUser } from '@src/store/ducks/user/thunks'
import { selectUserBackError, selectUserIsLoadingCreate } from '@src/store/ducks/user/selectors'
import { resetCreate } from '@src/store/ducks/user/reducer'
import style from './StudentCreate.module.scss'

export const StudentCreate = () => {
  const formRef = useRef<any>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const [formType, setFormType] = useState<UserType>(UserType.INDIVIDUAL)
  const isLoadingCreate = useSelector(selectUserIsLoadingCreate)
  const backError = useSelector(selectUserBackError)

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { url: '/', title: t('controlPanel.title') },
      { title: t('students.title'), url: '/students' },
      { title: t('studentCreate.title') },
    ]))
  }, [dispatch, t])

  useEffect(() => () => { dispatch(resetCreate()) })

  useEffect(() => {
    if (backError) {
      formRef.current?.setErrors(backError)
    }
  }, [backError])

  const successCallback = useCallback(() => {
    history.push('/students')
  }, [history])

  return (
    <div>
      <h1 className="page_title">{t('studentCreate.title')}</h1>

      <div className={style.header}>
        <Button
          type={formType === UserType.INDIVIDUAL ? 'primary' : 'default'}
          onClick={() => setFormType(UserType.INDIVIDUAL)}
        >
          {t('registration.individual')}
        </Button>
        <Button
          type={formType === UserType.ENTITY ? 'primary' : 'default'}
          onClick={() => setFormType(UserType.ENTITY)}
        >
          {t('registration.legal')}
        </Button>
      </div>
      <RegistrationForm
        formType={formType}
        ref={formRef}
        onSubmit={(e) => { dispatch(createUser(e, successCallback)) }}
        isCreate
      />
      <Button
        loading={isLoadingCreate}
        onClick={() => formRef.current?.submitForm()}
        type="primary"
      >
        {t('studentCreate.save')}
      </Button>
    </div>
  )
}
