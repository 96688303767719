import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { AppDispatch } from '@src/store/store'
import { selectCourses } from '@src/store/ducks/curator/selectors'
import { getCourses } from '@src/store/ducks/curator/thunks'
import { getInitials } from '@src/lib/getInitials'
import { selectUser } from '@src/store/ducks/user/selectors'
import { Role } from '@src/types/user'
import { setIsOpenRightSitebarMobile, setTypeRightSitebar, TypeRightSitebarEnum } from '@src/store/ducks/app/reducer'
import { setDialogId } from '@src/store/ducks/chat/reducer'
import { ReactComponent as Arrow } from '@src/assets/arrow.svg'
import style from './Curator.module.scss'

export const Curator = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const curatorCourses = useSelector(selectCourses)
  const user = useSelector(selectUser)

  useEffect(() => {
    if (user.roleId === Role.STUDENT) {
      dispatch(getCourses())
    } else {
      dispatch(setTypeRightSitebar(TypeRightSitebarEnum.DEFAULT))
    }
  }, [dispatch, user.roleId])

  const openChat = () => {
    if (user.fullInfo.curator?.dialogId) {
      dispatch(setDialogId(user.fullInfo.curator.dialogId))
      dispatch(setTypeRightSitebar(TypeRightSitebarEnum.CHAT))
    }
  }

  const handleClose = () => {
    dispatch(setIsOpenRightSitebarMobile(false))
  }

  return (
    <div className={style.container}>
      <div className={style.container_inner}>
        {user.fullInfo.curator && (
          <>
            <div className={style.title}>{t('curator.title')}</div>
            <button
              type="button"
              className={cn('right_sitebar_back', style.title_back)}
              onClick={handleClose}
            >
              <Arrow />
              {t('curator.title')}
            </button>
            <div className={style.curator}>
              <NavLink to={`/profile/${user.fullInfo.curator.id}`} className={style.curator_title}>
                <div className={style.curator_photo}>
                  {user.fullInfo.curator.photo ? (
                    <img src={user.fullInfo.curator.photo} alt={user.fullInfo.curator.name} />
                  ) : (
                    <div>{getInitials(user.fullInfo.curator.name)}</div>
                  )}
                </div>

                <div className={style.curator_name}>{user.fullInfo.curator.name}</div>
              </NavLink>
              <a href={`mailto:${user.fullInfo.curator.email}`} className={style.curator_email}>
                {user.fullInfo.curator.email}
              </a>
              <a href={`tel:${user.fullInfo.curator.phone}`} className={style.curator_phone}>
                {user.fullInfo.curator.phone}
              </a>
              <button type="button" className={style.curator_chat} onClick={openChat}>{t('curator.chatLink')}</button>
            </div>
          </>
        )}

        <div className={style.courses}>
          {curatorCourses.map((item) => (
            /* todo: refactor key */
            <div key={item.title + Math.random()}>
              <div className={style.title}>{item.title}</div>
              <div className={style.remainder}>
                <div className={style.remainder_count}>{item.individualCount}</div>
                <div className={style.remainder_title}>{t('curator.remainderIndividual')}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
