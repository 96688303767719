import {
  FC, useCallback, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button, DatePicker, Select, Switch,
} from 'antd'
import { FilterOutlined } from '@ant-design/icons'

import { TableFiltersType } from '@src/types/tableFilters'
import style from './Filters.module.scss'

type FiltersProps = {
  filters: TableFiltersType
  onChange: (filters: Array<{ columns: string, value: any }>) => void
  onOpen?: () => void
  onClose?: () => void
  loading?: boolean
}

export const Filters: FC<FiltersProps> = ({
  children, filters, onChange, onOpen, onClose, loading,
}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [filtersData, setFiltersData] = useState<Array<{ columns: string, value: any }>>([])

  const handleOnChange = useCallback((dataIndex: string, value: any) => {
    const newFiltersData = filtersData.filter((filter) => filter.columns !== dataIndex)
    if (value) {
      newFiltersData.push({ columns: dataIndex, value })
    }
    setFiltersData(newFiltersData)
    onChange(newFiltersData)
  }, [filtersData, onChange])

  const filtersComponents = useMemo(() => filters.map((filter) => {
    switch (filter.type) {
      case 'datePicker':
        return (
          <DatePicker
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            onChange={(e) => handleOnChange(filter.dataIndex, e?.utc().format())}
            className={filter.className}
            style={{ width: filter.width }}
          />
        )
      case 'rangePicker':
        return (
          <DatePicker.RangePicker
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            onChange={(e) => handleOnChange(
              filter.dataIndex, e && ({ from: e[0]?.utc().format(), to: e[1]?.utc().format() }),
            )}
            defaultValue={filter.defaultValue}
            className={filter.className}
            style={{ width: filter.width }}
            allowClear={!filter.notAllowClear}
          />
        )
      case 'select':
        return (
          <Select
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            allowClear
            showSearch={filter.showSearch}
            onChange={(e) => handleOnChange(filter.dataIndex, e)}
            onSearch={(e) => filter.onSearch && filter.onSearch(e)}
            className={filter.className}
            style={{ width: filter.width }}
            optionFilterProp="children"
          >
            {filter.options.map((option) => (
              <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            ))}
          </Select>
        )
      case 'switch':
        return (
          <div className={style.switch} key={filter.dataIndex}>
            <div className={style.switch_title}>{filter.placeholder}</div>
            <Switch onChange={(e) => handleOnChange(filter.dataIndex, e)} />
          </div>
        )
      default:
        return null
    }
  }), [filters, handleOnChange])

  const toggleOpen = () => {
    if (isOpen) {
      if (onClose) onClose()
    } else if (onOpen) {
      onOpen()
    }
    setIsOpen(!isOpen)
  }

  return (
    <div className={style.filters}>
      <Button
        icon={<FilterOutlined />}
        type="primary"
        onClick={toggleOpen}
        loading={loading}
      >
        {(t('table.filters'))}
      </Button>
      {isOpen && !loading && (
      <>
        {filtersComponents}
        {children}
      </>
      )}
    </div>
  )
}
