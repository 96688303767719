import {
  CuratorType, User, UserWorksheet,
} from '@src/types/user'
import { api } from './api'

type UploadImageType = {
  imageName: string
  imageUrl: string
  mime: string
}

export type saveWorksheetRequest = {
  isStudiedFrench: boolean
  goals: string
  oftenStudy: string
  comment: string
  vk: string
  facebook: string
  viber: string
  whatsApp: string
  telegram: string
  nativeLanguage: Array<string>
}

export const profileApi = {
  getUser(userId: number) {
    return api.get<User>('/v1/users/profile', { userId })
  },
  uploadImage(form: any) {
    return api.post<UploadImageType>('/v1/users/profile/upload', form)
  },
  saveWorksheet(data: saveWorksheetRequest) {
    return api.post<UserWorksheet>('/v1/users/profile/store-worksheet', data)
  },
  saveContactInfo(data: object) {
    return api.post<User>('/v1/users/profile/update', data)
  },
  updateCurator(data: { studentId: number, userId: number }) {
    return api.put<User>('/v1/students/update-curator', {}, data)
  },
  getCurators() {
    return api.get<CuratorType[]>('/v1/curators/')
  },
}
