import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
  selectAppIsOpenRightSitebarMobile,
  selectAppTypeRightSitebar,
} from '@src/store/ducks/app/selectors'
import { setIsOpenRightSitebarMobile, setTypeRightSitebar, TypeRightSitebarEnum } from '@src/store/ducks/app/reducer'
import { Notification } from '@src/components/Notification/Notification'
import { Curator } from '@src/components/Curator/Curator'
import { ReactComponent as ChatIcon } from '@src/assets/chat.svg'
import { AppDispatch } from '@src/store/store'
import { Dialogs } from '@src/components/ChatBlock/Dialogs/Dialogs'
import { Chat } from '@src/components/ChatBlock/Chat/Chat'
import { selectChatNotReadCountAll } from '@src/store/ducks/chat/selectors'
import style from './SitebarRight.module.scss'

export const SitebarRight = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const typeRightSitebar = useSelector(selectAppTypeRightSitebar)
  const notReadCountAll = useSelector(selectChatNotReadCountAll)
  const sitebarMobile = useSelector(selectAppIsOpenRightSitebarMobile)

  const handleClose = () => {
    dispatch(setTypeRightSitebar(TypeRightSitebarEnum.CURATOR))
    dispatch(setIsOpenRightSitebarMobile(false))
  }

  if (typeRightSitebar === TypeRightSitebarEnum.DEFAULT) return null

  return (
    <div
      className={cn(style.wrapper, sitebarMobile && style.wrapper_open)}
    >
      <button
        className={cn(style.mobile_close)}
        onClick={handleClose}
        aria-label="close"
        type="button"
      />
      <div className={style.container}>

        {typeRightSitebar === TypeRightSitebarEnum.NOTIFICATION && <Notification />}
        {typeRightSitebar === TypeRightSitebarEnum.CURATOR && <Curator />}
        {typeRightSitebar === TypeRightSitebarEnum.DIALOGS && <Dialogs />}
        {typeRightSitebar === TypeRightSitebarEnum.CHAT && <Chat />}

        {typeRightSitebar !== TypeRightSitebarEnum.DIALOGS && typeRightSitebar !== TypeRightSitebarEnum.CHAT && (
        <button
          type="button"
          className={style.chat}
          onClick={() => { dispatch(setTypeRightSitebar(TypeRightSitebarEnum.DIALOGS)) }}
        >
          {!!notReadCountAll && (
            <div className={style.chat_not_read}>{notReadCountAll}</div>
          )}
          {t('openChat')}
          <div className={style.chat_icon}>
            <ChatIcon />
          </div>
        </button>
        )}
      </div>
    </div>
  )
}
