import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { GetInvoicesFiltersType, InvoicesFindType, InvoicesItemType } from '@src/store/ducks/invoices/types'
import { ResponseMetaType } from '@src/api/api'
import { StudentsItemType } from '@src/store/ducks/students/types'
import { CalendarType } from '@src/types/courses'
import { CoursesItemType } from '@src/store/ducks/courses/types'
import { InvoicesTypeEnum } from '@src/types/orders'

export const initialState = {
  list: [] as Array<InvoicesItemType>,
  isLoading: false,
  meta: {
    currentPage: 1,
    lastPage: 0,
    perPage: 1,
    totalCount: 0,
  },
  isLoadingFilters: false,
  filters: {
    modules: [],
    types: [],
    calendars: [],
  } as GetInvoicesFiltersType & { calendars: CalendarType[] },
  formData: {
    payers: [] as StudentsItemType[],
    courses: [] as CoursesItemType[],
    calendars: [] as CalendarType[],
    invoices: [] as InvoicesFindType[],
  },
  isLoadingExport: false,
}

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoicesList(state, { payload }: PayloadAction<InvoicesItemType[]>) {
      if (!payload.length) {
        state.list = []
        return
      }
      const data = payload.map((item) => ({ ...item, key: `${item.id}` }))
      const result: InvoicesItemType[] = []
      data.forEach((item) => {
        result.push(item)
        if (item.avoirs.length) {
          result.push(...item.avoirs.map((avoir) => ({
            ...item,
            type: InvoicesTypeEnum.CREDIT,
            number: avoir.number,
            date: avoir.createdAt,
            alreadyPayed: -avoir.amount,
            totalPriceWithNds: -avoir.amount,
            priceWithoutNds: -+((avoir.amount * 100) / (100 + item.nds)).toFixed(2),
            saldo: 0,
            file: avoir.file,
            key: `${item.key}avoir${avoir.id}}`,
          })))
        }
      })

      const sum = result.reduce((res, current) => (
        {
          ...res,
          totalPriceWithNds: +(res.totalPriceWithNds + current.totalPriceWithNds).toFixed(2),
          saldo: +(res.saldo + current.saldo).toFixed(2),
          alreadyPayed: +(res.alreadyPayed + current.alreadyPayed).toFixed(2),
          priceWithoutNds: +(res.priceWithoutNds + current.priceWithoutNds).toFixed(2),
        }
      ))

      state.meta.perPage = result.length + 1
      state.meta.totalCount = (result.length + 1) * state.meta.lastPage
      // @ts-ignore
      state.list = [...result, { ...sum, key: 'result' }]
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setInvoicesMeta(state, { payload }: PayloadAction<ResponseMetaType>) {
      state.meta = payload
    },
    setInvoicesFormData(state, { payload }: PayloadAction<
      { payers: StudentsItemType[] } | { courses: CoursesItemType[] } |
      { calendars: CalendarType[] } | { invoices : InvoicesFindType[] }
      >) {
      state.formData = {
        ...state.formData,
        ...payload,
      }
    },
    setIsLoadingFilters(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingFilters = payload
    },
    setInvoicesFilters(state, { payload }: PayloadAction<GetInvoicesFiltersType | { calendars: CalendarType[] }>) {
      state.filters = {
        ...state.filters,
        ...payload,
      }
    },
    setIsLoadingExport(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingExport = payload
    },
    resetInvoices: () => initialState,
  },
})

export type InvoicesStateType = typeof initialState

export const {
  setInvoicesList, setIsLoading, setInvoicesMeta, resetInvoices, setInvoicesFormData, setIsLoadingFilters,
  setInvoicesFilters, setIsLoadingExport,
} = invoicesSlice.actions

export const invoicesReducer = invoicesSlice.reducer
