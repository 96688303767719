import { Key } from 'antd/lib/table/interface'

import { api, GetItemsType } from '@src/api/api'
import { PaymentHistoryItemType } from '@src/store/ducks/paymentHistory/types'

export const paymentHistoryApi = {
  getList(page: number, filters: any) {
    return api.get<GetItemsType<PaymentHistoryItemType>>('/v1/payments/', { page, filters })
  },
  create(data: any) {
    return api.post('/v1/payments/create', data, {}, { lang: 'fr' })
  },
  deleteItems(items: Key[]) {
    api.del('/', { items })
    return 'ok'
  },
  export() {
    return api.get('/v1/export/payments', {}, {}, 'blob')
  },
}
