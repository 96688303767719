import i18n from 'i18next'

import { authApi } from '@src/api/auth-api'
import { AppDispatch, RootState } from '@src/store/store'
import { setUser } from '@src/store/ducks/user/reducer'
import { keysToCamelCase, keysToSnakeCase } from '@src/lib/utils'
import { setIsInitialized } from '@src/store/ducks/app/reducer'

import {
  logoutAC,
  setBackError,
  setChangePasswordSuccess, setDocumentsLinks, setIsAuth, setIsLoading, setIsLoadingCheckToken,
  setIsRestTokenError, setRegisterSuccess, setResetEmailSuccess, setResetPasswordSuccess,
} from './reducer'

export const login = (email: string, password: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  dispatch(setBackError(null))
  try {
    const result = await authApi.login(email, password)
    i18n.changeLanguage(result.user.interfaceLang)
    dispatch(setUser(result.user))
    dispatch(setIsAuth(true))
  } catch (err) {
    console.log(err)
    if (err?.response?.status === 400) {
      dispatch(setBackError(keysToCamelCase(err.response.data.error)))
    }
    dispatch(setIsLoading(false))
  }
}

export const logout = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { auth } = getState()
  try {
    dispatch(logoutAC())
    dispatch(setIsInitialized())
    if (auth.isAuth) {
      await authApi.logout()
    }
  } catch (err) {
    console.error(err)
  }
}

export const getDocumentsLinks = () => async (dispatch: AppDispatch) => {
  try {
    const result = await authApi.documentsLinks()
    dispatch(setDocumentsLinks(result))
  } catch (err) {
    console.error(err)
  }
}

export const register = (data: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    await authApi.register(keysToSnakeCase(data))
    dispatch(setRegisterSuccess())
  } catch (err) {
    console.log(err)

    if (err?.response?.status === 400) {
      dispatch(setBackError(keysToCamelCase(err.response.data)))
    }
    dispatch(setIsLoading(false))
  }
}

export const sendEmail = (email: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  try {
    await authApi.passwordEmail(email)
    dispatch(setResetEmailSuccess())
  } catch (err) {
    console.log(err)
    if (err?.response?.status === 400) {
      dispatch(setBackError(keysToCamelCase(err.response.data.error)))
    }
    dispatch(setIsLoading(false))
  }
}

export const checkResetToken = (token: string, email: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoadingCheckToken(true))
  try {
    await authApi.checkToken(token, email)
    dispatch(setIsLoadingCheckToken(false))
  } catch (err) {
    console.log(err)
    dispatch(setIsRestTokenError())
    dispatch(setIsLoadingCheckToken(false))
  }
}

export const resetPassword = (token: string, password: string, email: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  try {
    await authApi.resetPassword(token, password, email)
    dispatch(setResetPasswordSuccess())
  } catch (err) {
    console.log(err)
    dispatch(setIsLoading(false))
  }
}
export const sendNewPassword = (
  password: { oldPassword: string, newPassword: string },
) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  dispatch(setChangePasswordSuccess(false))
  try {
    await authApi.changePassword(password)
    dispatch(setChangePasswordSuccess(true))
    dispatch(setIsLoading(false))
  } catch (err) {
    console.log(err)
    if (err?.response?.status === 400) {
      dispatch(setBackError(keysToCamelCase(err.response.data)))
    }
    dispatch(setIsLoading(false))
  }
}
