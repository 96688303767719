import i18n from 'i18next'

import { userApi } from '@src/api/user/user-api'
import { AppDispatch } from '@src/store/store'
import { authApi } from '@src/api/auth-api'
import { keysToCamelCase, keysToSnakeCase } from '@src/lib/utils'
import { setIsAuth } from '../auth/reducer'
import { setIsInitialized } from '../app/reducer'
import {
  setBackError, setInterfaceLang, setIsLoadingCreate, setUser,
} from './reducer'

export const fetchMe = () => async (dispatch: AppDispatch) => {
  try {
    const result = await userApi.getMe()
    dispatch(setUser(result))
    i18n.changeLanguage(result.interfaceLang)
    document.documentElement.lang = result.interfaceLang
    dispatch(setIsAuth(true))
  } catch (err) {
    console.error(err)
    if (err?.response?.status === 401) {
      dispatch(setIsAuth(false))
    }
  } finally {
    dispatch(setIsInitialized())
  }
}

export const setLang = (lang: string) => async (dispatch: AppDispatch) => {
  try {
    document.documentElement.lang = lang
    await userApi.setLang(lang)
    dispatch(setInterfaceLang(lang))
  } catch (err) {
    console.error(err)
  }
}

export const createUser = (data: any, callback: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingCreate(true))
    await authApi.register(keysToSnakeCase({ ...data, isAdminCreate: true }))
    callback()
  } catch (err) {
    console.error(err)
    if (err?.response?.status === 400) {
      dispatch(setBackError(keysToCamelCase(err.response.data)))
    }
  } finally {
    dispatch(setIsLoadingCreate(false))
  }
}
