import { TeacherItemType, TeacherScheduleResponse, TeacherScheduleType } from '@src/store/ducks/teachers/types'
import { api, GetItemsType } from '../api'

export const teachersApi = {
  getList(page: number, term?: string) {
    return api.get<GetItemsType<TeacherItemType>>('/v1/teacher', { page, term })
  },
  getSchedule(teacherId: number) {
    return api.get<TeacherScheduleResponse>('/v1/teacher/schedule', { teacherId })
  },
  createSchedule(teacherId: number, start: string, end: string) {
    return api.post<TeacherScheduleType>('/v1/teacher/schedule/create', { teacherId, start, end })
  },
  updateSchedule(teacherId: number, dayId: number, start: string, end: string) {
    return api.put<TeacherScheduleType>('/v1/teacher/schedule/update', {
      teacherId, dayId, start, end,
    })
  },
  deleteSchedule(dayId: number) {
    return api.del('/v1/teacher/schedule/delete', { dayId })
  },
  exportSchedule(teacherId: number) {
    return api.get('/v1/export/teacher/schedule', { teacherId }, {}, 'blob')
  },
}
