import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { getCookie } from '@src/lib/cookie'
import ruLang from './ru.json'
import enLang from './en.json'
import frLang from './fr.json'

const resources: any = {
  en: {
    translation: enLang,
  },
  ru: {
    translation: ruLang,
  },
  fr: {
    translation: frLang,
  },
}

let userLang = getCookie('lang') || navigator.language?.substr(0, 2)?.toLowerCase() || 'ru'

if (typeof resources[userLang] === 'undefined') {
  userLang = 'ru'
}

document.documentElement.lang = userLang

i18n.use(initReactI18next).init({
  resources,
  lng: userLang,
  interpolation: {
    escapeValue: false,
  },
})

export { i18n }
