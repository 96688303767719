import { RootState } from '@src/store/store'
import { NotificationStateType, initialState } from './reducer'

export const selectNotification = (state: RootState): NotificationStateType => state.notification || initialState

export const selectNotificationCount = (state: RootState): number => selectNotification(state).count
export const selectNotificationSaveCount = (state: RootState): number => selectNotification(state).saveCount
export const selectNotificationIsLoadingFetch = (state: RootState) => selectNotification(state).isLoadingFetch
export const selectNotificationIsLoadingMore = (state: RootState) => selectNotification(state).isLoadingMore
export const selectNotificationIsError = (state: RootState) => selectNotification(state).isError
export const selectNotificationItems = (state: RootState) => selectNotification(state).notifications
export const selectNotificationLoadingDelete = (state: RootState) => selectNotification(state).loadingDelete
