import i18n from 'i18next'
import { notification } from 'antd'

import { AppDispatch, RootState } from '@src/store/store'
import { materialsApi } from '@src/api/materials-api'
import { MaterialsCreateType, MaterialsUpdateType } from '@src/store/ducks/materials/types'
import { keysToSnakeCase } from '@src/lib/utils'
import { HttpStatus } from '@src/types/httpStatus'
import {
  addDeleteLoading,
  removeDeleteLoading,
  setEditData,
  setEditNotFound,
  setIsLoading, setIsLoadingCreate, setMaterialsList, setMaterialsMeta, setTypes,
} from './reducer'

type getMaterialsType = {
  page: number
  term?: string
  type?: number
}

export const getMaterials = ({ page, term, type }: getMaterialsType) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true))
  try {
    const result = await materialsApi.getList(page, term, type)
    dispatch(setMaterialsList(result.items))
    dispatch(setMaterialsMeta(result.meta))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getOneMaterial = (materialId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setEditNotFound(false))
    const result = await materialsApi.getOne(materialId)
    dispatch(setEditData(result))
  } catch (err) {
    console.error(err)
    if (err?.response?.status === HttpStatus.NOT_FOUND) {
      dispatch(setEditNotFound(true))
    } else {
      notification.error({
        message: i18n.t('errorTitle'),
        description:
          `${i18n.t('error')} --- ${err?.response?.data?.message ?? err.message}`,
      })
    }
  }
}

export const getMaterialsTypes = () => async (dispatch: AppDispatch) => {
  try {
    const result = await materialsApi.getTypes()
    dispatch(setTypes(result))
  } catch (err) {
    console.error(err)
  }
}

export const createMaterials = (data: MaterialsCreateType, history: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingCreate(true))
    await materialsApi.create(keysToSnakeCase(data))
    history.push('/materials')
    notification.success({
      message: i18n.t('createMaterials.success'),
    })
  } catch (err) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description:
        `${i18n.t('createMaterials.error')} --- ${err?.response?.data?.message ?? err.message}`,
    })
  } finally {
    dispatch(setIsLoadingCreate(false))
  }
}

export const updateMaterials = (data: MaterialsUpdateType, history: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingCreate(true))
    await materialsApi.update(keysToSnakeCase(data))
    history.push('/materials')
    notification.success({
      message: i18n.t('createMaterials.update'),
    })
  } catch (err) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description:
        `${i18n.t('createMaterials.error')} --- ${err?.response?.data?.message ?? err.message}`,
    })
  } finally {
    dispatch(setIsLoadingCreate(false))
  }
}

export const deleteMaterials = (
  materialId: number, page: number, term?: string, type?: number,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    dispatch(addDeleteLoading(materialId))
    await materialsApi.delete(materialId)
    const { materials } = getState()
    if (materials.list.length === 1 && page > 1) {
      dispatch(getMaterials({ page: page - 1, term, type }))
    } else {
      dispatch(getMaterials({ page, term, type }))
    }
  } catch (err) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description:
        `${i18n.t('materials.deleteError')} --- ${err?.response?.data?.message ?? err.message}`,
    })
  } finally {
    dispatch(removeDeleteLoading(materialId))
  }
}
