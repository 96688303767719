import {
  useEffect, useState, Fragment, useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Input, Modal, Upload } from 'antd'
import moment from 'moment-timezone'
import { CloseOutlined, FileOutlined, PlusOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/lib/upload/interface'

import { setTypeRightSitebar, TypeRightSitebarEnum } from '@src/store/ducks/app/reducer'
import { ReactComponent as Arrow } from '@src/assets/arrow.svg'
import { AppDispatch } from '@src/store/store'
import {
  selectChatDialogInfo,
  selectChatIsLoading,
  selectChatIsLoadingSend,
  selectChatMessages, selectChatOnlineUsers,
} from '@src/store/ducks/chat/selectors'
import { getInitials } from '@src/lib/getInitials'
import { getMessages, sendMessage, setRead } from '@src/store/ducks/chat/thunks'
import { addMessage, resetMessages, setReadMessage } from '@src/store/ducks/chat/reducer'
import { selectUser } from '@src/store/ducks/user/selectors'
import { socketApi } from '@src/api/socket-api'
import { MessageType } from '@src/store/ducks/chat/types'
import { ReactComponent as Send } from '@src/assets/chat_send.svg'
import { ReactComponent as Spinner } from '@src/assets/spinner.svg'
import { parseLinks } from '@src/lib/parseLinks'
import { isImage } from '@src/lib/regex'
import { getBase64 } from '@src/lib/utils'
import style from './Chat.module.scss'

const { TextArea } = Input

moment.locale('ru')

export const Chat = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const dialogInfo = useSelector(selectChatDialogInfo)
  const isLoading = useSelector(selectChatIsLoading)
  const isLoadingSend = useSelector(selectChatIsLoadingSend)
  const messages = useSelector(selectChatMessages)
  const me = useSelector(selectUser)
  const [newMessage, setNewMessage] = useState<string>('')
  const [parentId, setParentId] = useState<number | undefined>(undefined)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const onlineUsers = useSelector(selectChatOnlineUsers)
  const [filePreview, setFilePreview] = useState({
    previewImage: '',
    previewVisible: false,
    previewTitle: '',
  })

  // запрос истории сообщений
  useEffect(() => {
    dispatch(getMessages())

    return () => {
      dispatch(resetMessages())
    }
  }, [dispatch])

  // подключение к сокетам
  useEffect(() => {
    let offEventNewMessage = () => {}
    let offEventReadMessage = () => {}
    let unsubscribe = () => {}

    if (dialogInfo) {
      unsubscribe = socketApi.subscribePrivate(`dialog.${dialogInfo.id}`)
      offEventNewMessage = socketApi.on('NewMessage', ({ data }: { data: MessageType }) => {
        dispatch(addMessage(data))
        if (data.userIdFrom !== me.id) dispatch(setRead([data.id], dialogInfo.id))
      })
      offEventReadMessage = socketApi.on('ReadMessage', ({ data }: { data: number }) => {
        dispatch(setReadMessage(data))
      })
    }

    return () => {
      if (dialogInfo) {
        unsubscribe()
        offEventNewMessage()
        offEventReadMessage()
      }
    }
  }, [dialogInfo, dispatch, me.id])

  // редерект на диалоги если нету диалога
  useEffect(() => {
    if (!dialogInfo) dispatch(setTypeRightSitebar(TypeRightSitebarEnum.DIALOGS))
  }, [dialogInfo, dispatch])

  // новое сообщение
  const handleNewMessage = () => {
    dispatch(sendMessage(newMessage, fileList, parentId))
    setNewMessage('')
    setParentId(undefined)
    setFileList([])
  }

  // выделение вообщения
  const onDoubleClickMessage = (id: number) => {
    if (parentId !== id) {
      setParentId(id)
    } else {
      setParentId(undefined)
    }
  }

  const onScroll = useCallback((e) => {
    if ((Math.abs(e.target.scrollTop - e.target.offsetHeight) > e.target.scrollHeight - 200) && !isLoading) {
      dispatch(getMessages())
    }
  }, [dispatch, isLoading])

  const uploadFile = (value: { fileList: UploadFile[] }) => {
    setFileList([...fileList, ...value.fileList])
  }

  const handleRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid))
  }

  const handlePreview = async (file: any) => {
    let { preview } = file
    if (!file.url && !file.preview) {
      preview = await getBase64(file.originFileObj)
    }

    setFilePreview({
      previewImage: file.url || preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  const handleCancel = () => {
    setFilePreview({
      previewImage: '',
      previewVisible: false,
      previewTitle: '',
    })
  }

  if (!dialogInfo) return null

  return (
    <div className={style.container}>
      <button
        type="button"
        className="right_sitebar_back"
        onClick={() => {
          dispatch(setTypeRightSitebar(TypeRightSitebarEnum.DIALOGS))
        }}
      >
        <Arrow />
        <div className={style.back_title}>
          <div
            className={cn(
              style.back_title_photo,
              onlineUsers.some((id) => id === dialogInfo.userIdTo) && style.back_title_photo_online,
            )}
          >
            {dialogInfo.photo ? (
              <img className={style.back_title_photo_item} src={dialogInfo.photo} alt={dialogInfo.name} />
            ) : (
              <div className={style.back_title_photo_item}>{getInitials(dialogInfo.name)}</div>
            )}
          </div>
          {dialogInfo.name}
        </div>
      </button>
      <div className={style.chat}>
        <div className={style.chat_scrollable} onScroll={onScroll}>
          {messages.map((item, i) => (
            <Fragment key={item.id}>
              <div
                onDoubleClick={() => onDoubleClickMessage(item.id)}
                className={cn(style.chat_message, item.userIdFrom === me.id && style.chat_message_me)}
              >
                {item.parent && (
                <div className={style.chat_message_parent}>
                  <div className={style.chat_message_parent_from}>
                    {item.parent.userIdFrom === me.id ? t('chat.you') : dialogInfo.name}
                  </div>
                  <div>{item.parent.message}</div>
                </div>
                )}
                <div className={style.chat_message_files}>
                  {item.attachments?.map((file) => (
                    isImage.test(file.toLowerCase().split('/').pop()?.split('#')[0].split('?')[0] || '') ? (
                      <button
                        key={file}
                        type="button"
                        onClick={() => handlePreview({ url: file, name: item.message || '' })}
                      >
                        <img
                          className={style.chat_message_files_item}
                          src={file}
                          alt={file}
                        />
                      </button>
                    ) : (
                      <a
                        className={cn(style.chat_message_files_item, style.chat_message_files_file)}
                        href={file}
                        download={file.split('/').pop()?.split('#')[0].split('?')[0]}
                        key={file}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FileOutlined />
                        {file.split('/').pop()?.split('#')[0].split('?')[0]}
                      </a>
                    )
                  ))}
                </div>
                <div className={style.chat_message_text}>{parseLinks(item.message || '')}</div>
                <div className={style.time}>
                  {moment(item.createdAt).format('HH:mm')}
                  {item.userIdFrom === me.id && !!item.isRead && ` · ${t('chat.read')}`}
                </div>
              </div>
              {(
                (i + 1 < messages.length && !moment(item.createdAt).isSame(moment(messages[i + 1].createdAt), 'day'))
                || (i === messages.length - 1)
              ) && (
                <div className={style.chat_new_day}>{moment(item.createdAt).format('dddd, DD.MM')}</div>
              )}
            </Fragment>
          ))}
          {isLoading && (
            <div className={style.chat_spinner}>
              <Spinner />
            </div>
          )}
        </div>
      </div>

      <div className={style.field}>
        {parentId && (
          <div className={style.parent}>
            <div className={style.parent_text}>
              {messages.find((item) => item.id === parentId)?.message}
            </div>
            <button type="button" className={style.parent_close} onClick={() => setParentId(undefined)}>
              <CloseOutlined />
            </button>
          </div>
        )}

        {!!fileList.length && (
          <Upload
            className={style.field_upload_list}
            fileList={fileList}
            listType="picture-card"
            onPreview={handlePreview}
            onRemove={handleRemove}
          />
        )}

        <div className={style.field_content}>

          <Upload className={style.field_upload} beforeUpload={() => false} fileList={[]} onChange={uploadFile}>
            <button type="button">
              <PlusOutlined />
            </button>
          </Upload>

          <TextArea
            className={style.field_input}
            placeholder={t('chat.fieldPlaceholder')}
            autoSize={{ minRows: 1, maxRows: 3 }}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyUp={(e) => { if (e.key === 'Enter') handleNewMessage() }}
          />
          <button
            className={style.field_send}
            type="button"
            onClick={handleNewMessage}
            disabled={isLoadingSend || (!newMessage && !fileList.length)}
          >
            {isLoadingSend ? (
              <Spinner className={style.field_spinner} />
            ) : (
              <Send />
            )}
          </button>
        </div>

      </div>
      <Modal
        visible={filePreview.previewVisible}
        title={filePreview.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={filePreview.previewImage} />
      </Modal>
    </div>
  )
}
