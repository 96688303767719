import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type DocumentsLinksType = {
  agreement: null | string
  legal: null | string
  cooperation: null | string
}

export const initialState = {
  isAuth: false,
  isLoading: false,
  backError: null as null | object | string,
  registerSuccess: false,
  resetEmailSuccess: false,
  isLoadingCheckToken: false,
  isRestTokenError: false,
  resetPasswordSuccess: false,
  changePasswordSuccess: false,
  documentsLinks: {
    agreement: null,
    legal: null,
    cooperation: null,
  } as DocumentsLinksType,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth(state, { payload }: PayloadAction<boolean>) {
      state.isAuth = payload
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setBackError(state, { payload }: PayloadAction<object | null | string>) {
      state.backError = payload
    },
    setRegisterSuccess(state) {
      state.registerSuccess = true
    },
    setResetEmailSuccess(state) {
      state.resetEmailSuccess = true
    },
    setIsLoadingCheckToken(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingCheckToken = payload
    },
    setIsRestTokenError(state) {
      state.isRestTokenError = true
    },
    setResetPasswordSuccess(state) {
      state.resetPasswordSuccess = true
    },
    setChangePasswordSuccess(state, { payload }: PayloadAction<boolean>) {
      state.changePasswordSuccess = payload
    },
    resetState(state) {
      state.backError = null
      state.isLoading = false
      state.registerSuccess = false
      state.resetEmailSuccess = false
      state.isLoadingCheckToken = false
      state.isRestTokenError = false
      state.resetPasswordSuccess = false
      state.changePasswordSuccess = false
    },
    setDocumentsLinks(state, { payload }: PayloadAction<DocumentsLinksType>) {
      state.documentsLinks = payload
    },
    logoutAC() {},
  },
})

export type AuthStateType = typeof initialState

export const {
  setIsAuth,
  setIsLoading,
  setBackError,
  setRegisterSuccess,
  resetState,
  setResetEmailSuccess,
  setIsLoadingCheckToken,
  setIsRestTokenError,
  setResetPasswordSuccess,
  setDocumentsLinks,
  logoutAC,
  setChangePasswordSuccess,
} = authSlice.actions

export const authReducer = authSlice.reducer
