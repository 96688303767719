import { AppDispatch } from '@src/store/store'
import { secretaryApi } from '@src/api/user/secretary-api'
import { userApi } from '@src/api/user/user-api'
import { notification } from 'antd'
import i18n from 'i18next'
import {
  setIsLoading, setSecretaryList, setSecretaryMeta, updateSecretaryStatus,
} from './reducer'

export const getSecretary = (page: number, term?: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    const result = await secretaryApi.getList(page, term)
    dispatch(setSecretaryList(result.items))
    dispatch(setSecretaryMeta(result.meta))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const updateStatus = (userId: number, statusCode: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await userApi.updateStatus(userId, statusCode)
    dispatch(updateSecretaryStatus({ userId, status: result }))
  } catch (err) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description: err?.response?.data?.error,
    })
  }
}
