import { Key } from 'antd/lib/table/interface'

import { AppDispatch } from '@src/store/store'
import {
  setIsLoading,
  setIsLoadingExport,
  setPaymentFormData,
  setPaymentHistoryList,
  setPaymentHistoryMeta,
} from '@src/store/ducks/paymentHistory/reducer'
import { paymentHistoryApi } from '@src/api/orders/paymentHistory-api'
import { invoicesApi } from '@src/api/orders/invoices-api'
import { saveAs } from 'file-saver'

export const getPayloadHistory = (page: number, filters?: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    const result = await paymentHistoryApi.getList(page, filters)
    dispatch(setPaymentHistoryList(result.items))
    dispatch(setPaymentHistoryMeta(result.meta))
    dispatch(setIsLoading(false))
  } catch (err) {
    console.error(err)
  } finally {
    // dispatch(setIsLoading(false))
  }
}

export const deletePayloadHistoryItems = (items: Key[]) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    await paymentHistoryApi.deleteItems(items)
    dispatch(getPayloadHistory(1))
  } catch (err) {
    console.error(err)
  }
}

export const findPayloadInvoices = (term: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await invoicesApi.find(term)
    dispatch(setPaymentFormData({ invoices: result }))
  } catch (err) {
    console.error(err)
  }
}

export const createPayload = (data: any, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    await paymentHistoryApi.create(data)
    callback()
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const paymentHistoryExport = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingExport(true))
    const result = await paymentHistoryApi.export()
    saveAs(result, 'payment_history.xlsx')
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingExport(false))
  }
}
