import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type NotificationsType = {
  id: number
  text: string,
  link: string | null,
  createdAt: string
}

export const initialState = {
  count: 0,
  saveCount: 0,
  notifications: [] as NotificationsType[],
  isLoadingFetch: false,
  isLoadingMore: false,
  loadingDelete: [] as Array<number>,
  isError: false,
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setCount(state, { payload }: PayloadAction<number>) {
      state.count = payload
    },
    setSaveCount(state) {
      state.saveCount = state.count
    },
    addNotifications(state, { payload }: PayloadAction<NotificationsType[]>) {
      state.notifications.push(...payload)
    },
    setIsLoadingFetch(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingFetch = payload
    },
    setIsLoadingMore(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingMore = payload
    },
    setIsError(state, { payload }: PayloadAction<boolean>) {
      state.isError = payload
    },
    addLoadingDelete(state, { payload }: PayloadAction<number>) {
      state.loadingDelete.push(payload)
    },
    removeLoadingDelete(state, { payload }: PayloadAction<number>) {
      state.loadingDelete = state.loadingDelete.filter((item) => item !== payload)
    },
    removeNotification(state, { payload }: PayloadAction<number>) {
      state.notifications = state.notifications.filter((item) => item.id !== payload)
      state.saveCount -= 1
    },
    resetNotification(state) {
      state.notifications = []
      state.saveCount = 0
    },
  },
})

export type NotificationStateType = typeof initialState

export const {
  setCount, addNotifications, removeNotification,
  setIsLoadingFetch, setSaveCount, setIsLoadingMore,
  setIsError, addLoadingDelete, removeLoadingDelete,
  resetNotification,
} = notificationSlice.actions

export const notificationReducer = notificationSlice.reducer
