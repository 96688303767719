import { FC } from 'react'

import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import { useTimezones } from '@src/hooks/useTimezones'

type TimezoneSelectProps = {
  name: string
  placeholder?: string
  disabled?: boolean
}

export const TimezoneSelect: FC<TimezoneSelectProps> = (props) => {
  const selectOptions = useTimezones()

  return <SelectFormik {...props} options={selectOptions} showSearch />
}
