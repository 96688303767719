import { useEffect, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { NavLink, Redirect } from 'react-router-dom'
import { Button } from 'antd'
import { UnlockOutlined, UserOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { JoinContainer } from '@src/components/JoinContainer/JoinContainer'
import { login } from '@src/store/ducks/auth/thunks'
import { AppDispatch } from '@src/store/store'
import { selectAuthBackError, selectAuthIsAuth, selectAuthIsLoading } from '@src/store/ducks/auth/selectors'
import { resetState } from '@src/store/ducks/auth/reducer'
import { isString } from '@src/lib/utils'
import style from './Login.module.scss'

export const Login = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(selectAuthIsLoading)
  const backError = useSelector(selectAuthBackError)
  const isAuth = useSelector(selectAuthIsAuth)

  useEffect(() => () => {
    dispatch(resetState())
  }, [dispatch])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      email: Yup.string()
        .email(t('form.errors.email'))
        .required(t('form.errors.required')),
      password: Yup.string()
        .required(t('form.errors.required')),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    email: '',
    password: '',
    rememberMe: true,
  }), [])

  if (isAuth) {
    return <Redirect to="/" />
  }

  return (
    <JoinContainer title={t('login.title')}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({ email, password }) => dispatch(login(email, password))}
      >
        {() => (
          <Form>
            <FieldFormik Prefix={UserOutlined} name="email" placeholder={t('login.email')} />
            <FieldFormik
              Prefix={UnlockOutlined}
              name="password"
              type="password"
              placeholder={t('login.password')}
            />
            {backError && isString(backError) && <div className={style.error}>{backError}</div>}

            <Button type="primary" htmlType="submit" className={cn('login-form-button', style.btn)} loading={isLoading}>
              {t('login.submitBtn')}
            </Button>

            <div className={style.remember_me}>
              {/* <CheckboxFormik name="rememberMe">{t('login.rememberMe')}</CheckboxFormik> */}
              <NavLink to="/forgot-password" href="">{t('login.forgotYourPassword')}</NavLink>
            </div>

            <div className={style.registration}>
              {t('login.noAccount')}
              {' '}
              <NavLink to="/registration">{t('login.registration')}</NavLink>
            </div>
          </Form>
        )}
      </Formik>
    </JoinContainer>
  )
}
