import { AppDispatch } from '@src/store/store'
import { invoicesApi } from '@src/api/orders/invoices-api'
import {
  setInvoicesFilters,
  setInvoicesFormData, setInvoicesList, setInvoicesMeta, setIsLoading, setIsLoadingExport, setIsLoadingFilters,
} from '@src/store/ducks/invoices/reducer'
import { studentsApi } from '@src/api/user/students-api'
import { coursesApi } from '@src/api/courses-api'
import { InvoicesTypeEnum } from '@src/types/orders'
import { saveAs } from 'file-saver'

export const getInvoicesAll = (page: number, filters?: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    const result = await invoicesApi.getAll(page, filters)
    dispatch(setInvoicesMeta(result.meta))
    dispatch(setInvoicesList(result.items))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getPayersForm = (term: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await studentsApi.find(term)
    dispatch(setInvoicesFormData({ payers: result }))
  } catch (err) {
    console.error(err)
  }
}

export const getCoursesForm = (term: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await coursesApi.find(term)
    dispatch(setInvoicesFormData({ courses: result }))
  } catch (err) {
    console.error(err)
  }
}

export const getCalendarsForm = (term: string, courseId?: number) => async (dispatch: AppDispatch) => {
  try {
    console.log(courseId)
    const result = await coursesApi.calendarsFind(term, courseId)
    dispatch(setInvoicesFormData({ calendars: result }))
  } catch (err) {
    console.error(err)
  }
}

export const createInvoice = (data: any, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    if (data.type === InvoicesTypeEnum.PROFORMA) {
      await invoicesApi.create(data)
    } else {
      await invoicesApi.createAvoir(data)
    }
    callback()
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getFiltersInvoices = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingFilters(true))
    const result = await invoicesApi.getFilters()
    dispatch(setInvoicesFilters(result))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingFilters(false))
  }
}

export const getCalendarsFilter = (term: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await coursesApi.calendarsFind(term)
    dispatch(setInvoicesFilters({ calendars: result }))
  } catch (err) {
    console.error(err)
  }
}

export const findInvoicesForm = (term: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await invoicesApi.find(term, true)
    dispatch(setInvoicesFormData({ invoices: result }))
  } catch (err) {
    console.error(err)
  }
}

export const invoicesExport = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingExport(true))
    const result = await invoicesApi.export()
    saveAs(result, 'invoices.xlsx')
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingExport(false))
  }
}
