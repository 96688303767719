import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/store/store'
import { ChatStateType, initialState } from './reducer'

export const selectChat = (state: RootState): ChatStateType => state.chat || initialState

export const selectChatIsLoading = (state: RootState): boolean => selectChat(state).isLoading
export const selectChatIsLoadingSend = (state: RootState): boolean => selectChat(state).isLoadingSend
export const selectChatDialogs = (state: RootState) => selectChat(state).dialogs
export const selectChatDialogsId = (state: RootState) => selectChat(state).dialogsId
export const selectChatOnlineUsers = (state: RootState) => selectChat(state).onlineUsers
export const selectChatNotReadCountAll = createSelector(
  selectChatDialogs, (state) => state.reduce((sum, item) => (sum + item.notReadCount), 0),
)
export const selectChatMessages = (state: RootState) => selectChat(state).messages
export const selectChatDialogInfo = createSelector(
  selectChat, (state) => state.dialogs.find((item) => item.id === state.dialogId),
)
