import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import { AppDispatch } from '@src/store/store'
import { ReactComponent as Arrow } from '@src/assets/arrow.svg'
import { Preloader } from '@src/components/common/Preloader/Preloader'
import {
  selectNotificationCount,
  selectNotificationIsError,
  selectNotificationIsLoadingFetch,
  selectNotificationIsLoadingMore,
  selectNotificationItems,
  selectNotificationLoadingDelete,
  selectNotificationSaveCount,
} from '@src/store/ducks/notification/selectors'
import { deleteNotification, getNotifications, moreNotifications } from '@src/store/ducks/notification/thunks'
import { ReactComponent as Spinner } from '@src/assets/spinner.svg'
import { MultiLink } from '@src/components/common/MultiLink/MultiLink'
import { resetNotification } from '@src/store/ducks/notification/reducer'
import {
  setIsOpenRightSitebarMobile,
  setTypeRightSitebar,
  TypeRightSitebarEnum,
} from '@src/store/ducks/app/reducer'
import style from './Notification.module.scss'

export const Notification = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const isLoadingFetch = useSelector(selectNotificationIsLoadingFetch)
  const isLoadingMore = useSelector(selectNotificationIsLoadingMore)
  const isError = useSelector(selectNotificationIsError)
  const notification = useSelector(selectNotificationItems)
  const notificationCount = useSelector(selectNotificationCount)
  const notificationSaveCount = useSelector(selectNotificationSaveCount)
  const loadingDelete = useSelector(selectNotificationLoadingDelete)

  useEffect(() => {
    if (notificationCount && !notification.length && !isLoadingFetch) {
      dispatch(getNotifications())
    }
  }, [dispatch, isLoadingFetch, notification.length, notificationCount])

  useEffect(() => () => {
    dispatch(resetNotification())
  }, [dispatch])

  const notificationClose = () => {
    dispatch(setTypeRightSitebar(TypeRightSitebarEnum.CURATOR))
    dispatch(setIsOpenRightSitebarMobile(false))
  }

  return (
    <div className={style.container}>
      <button
        type="button"
        className="right_sitebar_back"
        onClick={notificationClose}
      >
        <Arrow />
        {t('notification.title')}
      </button>

      <Preloader loading={isLoadingFetch}>
        {notification.length ? (
          <div className={style.newText}>{t('notification.new')}</div>
        ) : (
          <div className={style.noText}>{t('notification.noNotification')}</div>
        )}
        {isError && <div className={style.error}>{t('notification.error')}</div>}

        <div className={style.notification}>
          {notification.map((item) => (
            <div key={item.id} className={style.notification_item}>
              <button
                type="button"
                className={style.notification_close}
                onClick={() => dispatch(deleteNotification(item.id))}
                disabled={loadingDelete.some((loadId) => loadId === item.id)}
              >
                {loadingDelete.some((loadId) => loadId === item.id) ? (
                  <Spinner />
                ) : (
                  <CloseOutlined />
                )}
              </button>
              <div className={style.notification_date}>{moment(item.createdAt).format('DD.MM.YYYY, HH:mm')}</div>
              <div className={style.notification_text}>{item.text}</div>
              {item.link && (
                <MultiLink url={item.link} title={t('notification.link')} className={style.notification_link} isIcon />
              )}
            </div>
          ))}
          {!!notification.length && (notificationSaveCount > notification.length) && (
            <Button
              type="primary"
              className={style.notification_more}
              loading={isLoadingMore}
              onClick={() => dispatch(moreNotifications(notification[notification.length - 1].id))}
            >
              {t('notification.more')}
            </Button>
          )}
        </div>
      </Preloader>
    </div>
  )
}
