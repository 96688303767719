import { Role } from '@src/types/user'

export type MenuListItemType = Array<{
  url?: string
  title: any
  subMenu?: Array<{
    title: any
    url: string
  }>
}>

type MenuListType = {
  [Role.GUEST]: MenuListItemType
  [Role.ADMIN]: MenuListItemType
  [Role.SECRETARY]: MenuListItemType
  [Role.TEACHER]: MenuListItemType
  [Role.STUDENT]: MenuListItemType
  [Role.ORGANIZER]: MenuListItemType
}

export const menuCreate: MenuListType = {
  [Role.GUEST]: [],
  [Role.ADMIN]: [
    {
      title: 'menu.create.user',
      subMenu: [
        {
          title: 'menu.create.student',
          url: '/students/create',
        },
        {
          title: 'menu.create.teacher',
          url: '/teachers/create',
        },
        {
          title: 'menu.create.organizer',
          url: '/organizers/create',
        },
        {
          title: 'menu.create.secretary',
          url: '/secretary/create',
        },
      ],
    },
    {
      title: 'menu.create.material',
      url: '/materials/create',
    },
  ],
  [Role.SECRETARY]: [],
  [Role.TEACHER]: [],
  [Role.STUDENT]: [],
  [Role.ORGANIZER]: [],
}
