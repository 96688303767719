import { FC } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteProps } from 'react-router'

import { Role } from '@src/types/user'
import { selectUser } from '@src/store/ducks/user/selectors'
import { Error404 } from '@src/pages/Error404/Error404'

type PrivateRouteProps = {
    roles: Array<Role>
} & RouteProps

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, roles, ...rest }) => {
  const user = useSelector(selectUser)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (roles && roles.indexOf(user.roleId) !== -1) {
          return children
        }

        if (user.roleId === Role.GUEST) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        if (roles && roles.indexOf(user.roleId) === -1) {
          return <Error404 />
        }

        return children
      }}
    />
  )
}
