import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type BreadcrumbsType = Array<{ url?: string, title: string }>

export enum TypeRightSitebarEnum {
  DEFAULT = 0,
  NOTIFICATION = 1,
  CURATOR = 2,
  DIALOGS = 3,
  CHAT = 4,
}

export const initialState = {
  isInitialized: false,
  breadcrumbs: [] as BreadcrumbsType,
  typeRightSitebar: TypeRightSitebarEnum.DEFAULT as TypeRightSitebarEnum,
  isOpenLeftSitebarMobile: false,
  isOpenRightSitebarMobile: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsInitialized(state) {
      state.isInitialized = true
    },
    setBreadcrumbs(state, { payload }: PayloadAction<BreadcrumbsType>) {
      state.breadcrumbs = payload
    },
    setTypeRightSitebar(state, { payload }: PayloadAction<TypeRightSitebarEnum>) {
      state.typeRightSitebar = payload
    },
    setIsOpenLeftSitebarMobile(state, { payload }: PayloadAction<boolean>) {
      state.isOpenLeftSitebarMobile = payload
    },
    setIsOpenRightSitebarMobile(state, { payload }: PayloadAction<boolean>) {
      state.isOpenRightSitebarMobile = payload
    },
  },
})

export type AppStateType = typeof initialState

export const {
  setIsInitialized,
  setBreadcrumbs,
  setTypeRightSitebar,
  setIsOpenLeftSitebarMobile,
  setIsOpenRightSitebarMobile,
} = appSlice.actions

export const appReducer = appSlice.reducer
