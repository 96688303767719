import {
  FC, forwardRef, Ref,
} from 'react'
import { FormikProps } from 'formik/dist/types'

import { AllowedTo } from '@src/components/common/AllowedTo/AllowedTo'
import { Role } from '@src/types/user'
import { Student } from '@src/components/Profile/ContactInfo/Student/Student'
import { useSelector } from 'react-redux'
import { selectProfileUser } from '@src/store/ducks/profile/selectors'
import { Admin } from '@src/components/Profile/ContactInfo/Admin/Admin'
import { Secretary } from '@src/components/Profile/ContactInfo/Secretary/Secretary'
import { Organizer } from '@src/components/Profile/ContactInfo/Organizer/Organizer'
import { Teacher } from '@src/components/Profile/ContactInfo/Teacher/Teacher'
import style from './ContactInfo.module.scss'

type ContactInfoProps = {
  isEdit: boolean
  ref: Ref<FormikProps<any>>
}

export const ContactInfo: FC<ContactInfoProps> = forwardRef(({ isEdit }, ref) => {
  const user = useSelector(selectProfileUser)

  return (
    <div className={style.container}>
      <AllowedTo userRoles={[Role.STUDENT]} userRole={user.roleId}>
        <Student isEdit={isEdit} ref={ref} />
      </AllowedTo>
      <AllowedTo userRoles={[Role.ADMIN]} userRole={user.roleId}>
        <Admin isEdit={isEdit} ref={ref} />
      </AllowedTo>
      <AllowedTo userRoles={[Role.SECRETARY]} userRole={user.roleId}>
        <Secretary isEdit={isEdit} ref={ref} />
      </AllowedTo>
      <AllowedTo userRoles={[Role.ORGANIZER]} userRole={user.roleId}>
        <Organizer isEdit={isEdit} ref={ref} />
      </AllowedTo>
      <AllowedTo userRoles={[Role.TEACHER]} userRole={user.roleId}>
        <Teacher isEdit={isEdit} ref={ref} />
      </AllowedTo>
    </div>
  )
})
