import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StudentsFiltersType, StudentsItemType } from '@src/store/ducks/students/types'
import { ResponseMetaType } from '@src/api/api'
import { UserStatus } from '@src/types/user'

type UpdateLoadingAction = PayloadAction<{
  field: 'fullName'
  id: number
  isLoading: boolean
}>

export const initialState = {
  list: [] as Array<StudentsItemType & { fullName: string, key: number }>,
  isLoading: false,
  updateLoading: {
    fullName: [] as number[],
  },
  meta: {
    currentPage: 1,
    lastPage: 0,
    perPage: 0,
    totalCount: 0,
  },
  filters: {
    statuses: [],
    courses: [],
    calendars: [],
    modules: [],
    types: [],
  } as StudentsFiltersType,
  isLoadingFilters: false,
  isLoadingExport: false,
}

const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    setStudentsList(state, { payload }: PayloadAction<StudentsItemType[]>) {
      state.list = payload.map((item) => ({ ...item, key: item.id, fullName: `${item.surname} ${item.name}` }))
    },
    setStudentsMeta(state, { payload }: PayloadAction<ResponseMetaType>) {
      state.meta = payload
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setUpdateLoading(state, { payload }: UpdateLoadingAction) {
      if (payload.isLoading) {
        state.updateLoading[payload.field].push(payload.id)
      } else {
        state.updateLoading[payload.field] = state.updateLoading[payload.field].filter((item) => item !== payload.id)
      }
    },
    updateName(state, { payload }: PayloadAction<{id: number, name: string}>) {
      state.list = state.list.map((item) => (item.id === payload.id ? { ...item, fullName: payload.name } : item))
    },
    setStudentsFilters(state, { payload }: PayloadAction<StudentsFiltersType>) {
      state.filters = payload
    },
    setIsLoadingFilters(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingFilters = payload
    },
    updateStudentStatus(state, { payload }: PayloadAction<{userId: number, status: UserStatus}>) {
      state.list = state.list.map((item) => {
        if (item.user.id === payload.userId) {
          return {
            ...item,
            user: {
              ...item.user,
              status: payload.status,
            },
          }
        }
        return item
      })
    },
    setIsLoadingExport(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingExport = payload
    },
  },
})

export type StudentsStateType = typeof initialState

export const {
  setStudentsList, setStudentsMeta, setIsLoading, setUpdateLoading, updateName, setStudentsFilters,
  setIsLoadingFilters, updateStudentStatus, setIsLoadingExport,
} = studentsSlice.actions

export const studentsReducer = studentsSlice.reducer
