export const isObject = (value: any) => value === Object(value) && !Array.isArray(value) && typeof value !== 'function'
export const isFunction = (value: any) => typeof value === 'function'
export const isUndefined = (value: any) => typeof value === 'undefined'
export const isNull = (value: any) => value === null
export const isArray = (value: any): value is any[] => Array.isArray(value)
export const isString = (value: any) => typeof value === 'string'
export const isBoolean = (value: any) => typeof value === 'boolean'
export const isNumber = (value: any) => typeof value === 'number'

export const toCamel = (string: string): string => string.replace(/([-_][a-z])/gi, ($1) => $1
  .toUpperCase()
  .replace('-', '')
  .replace('_', ''))

export const toSnake = (string: string): string => string.replace(/([A-Z_][a-z])/gi, ($1) => $1
  .replace(/([A-Z])/g, ($11) => `_${$11}`)
  .replace('-', '_')
  .toLowerCase())

export const keysToCamelCase = (args: any): any => {
  if (isObject(args)) {
    const newObject = {}

    Object.keys(args).forEach((k) => {
      // @ts-ignore
      newObject[toCamel(k)] = keysToCamelCase(args[k])
    })

    return newObject
  }

  if (Array.isArray(args)) {
    return args.map((i) => keysToCamelCase(i))
  }

  return args
}

export const keysToSnakeCase = (args: any): any => {
  if (isObject(args)) {
    const newObject = {}

    Object.keys(args).forEach((k) => {
      // @ts-ignore
      newObject[toSnake(k)] = keysToSnakeCase(args[k])
    })

    return newObject
  }

  if (Array.isArray(args)) {
    return args.map((i) => keysToSnakeCase(i))
  }

  return args
}

export const getBase64 = (file: any): Promise<string | ArrayBuffer | null> => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = (error) => reject(error)
})

export const getObjectValue = (arr: string[], obj: any): any => {
  if (!arr.length || !obj) return obj

  const key = arr.shift() ?? ''
  if (arr.length) {
    return getObjectValue(arr, obj[key])
  }
  return obj[key]
}
