import React, {
  FC, useEffect, useMemo, useRef,
} from 'react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { Button } from 'antd'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'

import { JoinContainer } from '@src/components/JoinContainer/JoinContainer'
import style from '@src/pages/ChangePassword/ChangePassword.module.scss'
import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { UnlockOutlined } from '@ant-design/icons'
import { AppDispatch } from '@src/store/store'
import { sendNewPassword } from '@src/store/ducks/auth/thunks'
import {
  selectAuthBackError,
  selectAuthIsLoading, selectChangePasswordSuccess,
} from '@src/store/ducks/auth/selectors'

export const ChangePassword: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const formRef = useRef<any>(null)

  const backError = useSelector(selectAuthBackError)
  const isLoading = useSelector(selectAuthIsLoading)
  const changePasswordSuccess = useSelector(selectChangePasswordSuccess)

  useEffect(() => {
    if (backError) {
      formRef.current?.setErrors(backError)
    }
  }, [backError])

  const initialValues = useMemo(() => ({
    oldPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  }), [])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      oldPassword: Yup.string().required(t('form.errors.required')),
      newPassword: Yup.string().min(6, t('form.errors.min', '', { count: 6 }))
        .required(t('form.errors.required'))
        .notOneOf([Yup.ref('oldPassword')], t('form.errors.repeatNewPassword')),
      passwordConfirmation: Yup.string().oneOf([Yup.ref('newPassword'), null], t('form.errors.passMismatch'))
        .required(t('form.errors.required')),
    })), [t])

  return (
    <div className={style.changePasswordContainer}>
      <JoinContainer unLogo title={t('changePassword.title')}>
        <div
          className={style.description}
        >
          {t('changePassword.description')}
        </div>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(value) => {
            dispatch(sendNewPassword(value))
          }}
          innerRef={formRef}
        >
          {() => (
            <Form>
              <FieldFormik
                Prefix={UnlockOutlined}
                name="oldPassword"
                type="password"
                placeholder={t('changePassword.oldPassword')}
                required
              />

              <FieldFormik
                Prefix={UnlockOutlined}
                name="newPassword"
                type="password"
                placeholder={t('changePassword.password')}
                required
              />
              <FieldFormik
                Prefix={UnlockOutlined}
                name="passwordConfirmation"
                type="password"
                placeholder={t('changePassword.passwordConfirmation')}
                required
              />
              <Button
                type="primary"
                htmlType="submit"
                className={cn('login-form-button', style.btn)}
                loading={isLoading}
              >
                {t('changePassword.submitBtn')}
              </Button>
              {changePasswordSuccess && (
              <div
                className={style.successfull}
              >
                {t('changePassword.successPass')}
              </div>
              )}
            </Form>
          )}
        </Formik>
      </JoinContainer>
    </div>
  )
}
