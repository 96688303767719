import { Role } from '@src/types/user'

type MenuListItemType = Array<{
  url: string
  title: any
}>

type MenuListType = {
  [Role.GUEST]: MenuListItemType
  [Role.ADMIN]: MenuListItemType
  [Role.SECRETARY]: MenuListItemType
  [Role.TEACHER]: MenuListItemType
  [Role.STUDENT]: MenuListItemType
  [Role.ORGANIZER]: MenuListItemType
}

export const menuList: MenuListType = {
  [Role.GUEST]: [],
  [Role.ADMIN]: [
    {
      url: '/profile',
      title: 'menu.profile',
    },
    {
      url: '/materials',
      title: 'menu.materials',
    },
    {
      url: '/students',
      title: 'menu.students',
    },
    {
      url: '/teachers',
      title: 'menu.teachers',
    },
    {
      url: '/secretary',
      title: 'menu.secretary',
    },
    {
      url: '/organizers',
      title: 'menu.organizers',
    },
    {
      url: '/schedule',
      title: 'menu.schedule',
    },
    {
      url: '/payment-history',
      title: 'menu.payment-history',
    },
    {
      url: '/invoices',
      title: 'menu.invoices',
    },
  ],
  [Role.SECRETARY]: [
    {
      url: '/profile',
      title: 'menu.profile',
    },
    {
      url: '/materials',
      title: 'menu.materials',
    },
    {
      url: '/students',
      title: 'menu.students',
    },
    {
      url: '/teachers',
      title: 'menu.teachers',
    },
    {
      url: '/schedule',
      title: 'menu.schedule',
    },
  ],
  [Role.TEACHER]: [
    {
      url: '/profile',
      title: 'menu.profile',
    },
    {
      url: '/materials',
      title: 'menu.materials',
    },
    {
      url: '/students',
      title: 'menu.students',
    },
    {
      url: '/schedule',
      title: 'menu.schedule',
    },
  ],
  [Role.STUDENT]: [
    {
      url: '/profile',
      title: 'menu.profile',
    },
    {
      url: '/courses',
      title: 'menu.courses',
    },
    {
      url: '/lessons',
      title: 'menu.lessons',
    },
    {
      url: '/materials',
      title: 'menu.materials',
    },
    {
      url: '/payment-history',
      title: 'menu.payment-history',
    },
    {
      url: '/invoices',
      title: 'menu.invoices',
    },
  ],
  [Role.ORGANIZER]: [
    {
      url: '/profile',
      title: 'menu.profile',
    },
    {
      url: '/materials',
      title: 'menu.materials',
    },
    {
      url: '/students',
      title: 'menu.students',
    },
    {
      url: '/secretary',
      title: 'menu.secretary',
    },
    {
      url: '/teachers',
      title: 'menu.teachers',
    },
    {
      url: '/schedule',
      title: 'menu.schedule',
    },
  ],
}
