import { RootState } from '@src/store/store'
import { StudentsStateType, initialState } from './reducer'

export const selectStudents = (state: RootState): StudentsStateType => state.students || initialState

export const selectStudentsList = (state: RootState) => selectStudents(state).list
export const selectStudentsMeta = (state: RootState) => selectStudents(state).meta
export const selectStudentsIsLoading = (state: RootState) => selectStudents(state).isLoading
export const selectStudentsUpdateLoading = (state: RootState) => selectStudents(state).updateLoading
export const selectStudentsFilters = (state: RootState) => selectStudents(state).filters
export const selectStudentsIsLoadingFilters = (state: RootState) => selectStudents(state).isLoadingFilters
export const selectStudentsIsLoadingExport = (state: RootState) => selectStudents(state).isLoadingExport
