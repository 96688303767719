import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TeacherItemType, TeacherScheduleCalendarType } from '@src/store/ducks/teachers/types'
import { ResponseMetaType } from '@src/api/api'
import { UserStatus } from '@src/types/user'

export const initialState = {
  list: [] as Array<TeacherItemType & { fullName: string, key: number }>,
  isLoading: false,
  meta: {
    currentPage: 1,
    lastPage: 0,
    perPage: 1,
    totalCount: 0,
  },
  schedule: [] as TeacherScheduleCalendarType[],
  teacherName: '',
  isLoadingExport: false,
}

const teachersSlice = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    setTeachersList(state, { payload }: PayloadAction<TeacherItemType[]>) {
      state.list = payload.map((item) => ({ ...item, key: item.id, fullName: `${item.surname} ${item.name}` }))
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setTeachersMeta(state, { payload }: PayloadAction<ResponseMetaType>) {
      state.meta = payload
    },
    setTeachersSchedule(state, { payload }: PayloadAction<TeacherScheduleCalendarType[]>) {
      state.schedule = payload
    },
    setTeacherName(state, { payload }: PayloadAction<string>) {
      state.teacherName = payload
    },
    addTeachersSchedule(state, { payload }: PayloadAction<TeacherScheduleCalendarType>) {
      state.schedule.push(payload)
    },
    updateTeachersSchedule(state, { payload }: PayloadAction<TeacherScheduleCalendarType>) {
      state.schedule = state.schedule.map((item) => {
        if (item.id === payload.id) return payload
        return item
      })
    },
    deleteTeacherScheduleAC(state, { payload }: PayloadAction<string>) {
      state.schedule = state.schedule.filter((item) => item.id !== payload)
    },
    rollbackTeachersSchedule(state) {
      state.schedule = [...state.schedule]
    },
    updateTeacherStatus(state, { payload }: PayloadAction<{userId: number, status: UserStatus}>) {
      state.list = state.list.map((item) => {
        if (item.user.id === payload.userId) {
          return {
            ...item,
            user: {
              ...item.user,
              status: payload.status,
            },
          }
        }
        return item
      })
    },
    setIsLoadingExport(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingExport = payload
    },
    reset: () => initialState,
  },
})

export type TeachersStateType = typeof initialState

export const {
  setTeachersList, setIsLoading, setTeachersMeta, reset, setTeachersSchedule,
  addTeachersSchedule, updateTeachersSchedule, rollbackTeachersSchedule,
  updateTeacherStatus, setTeacherName, deleteTeacherScheduleAC,
  setIsLoadingExport,
} = teachersSlice.actions

export const teachersReducer = teachersSlice.reducer
