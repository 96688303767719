import { api, GetItemsType } from '@src/api/api'
import { CoursesItemType, CoursesTargetType } from '@src/store/ducks/courses/types'
import { CalendarType } from '@src/types/courses'

type GetCoursesRequest = GetItemsType<CoursesItemType, { targets: CoursesTargetType[] }>

export const coursesApi = {
  getCourses(page: number, target?: string, type?: string) {
    return api.get<GetCoursesRequest>('/v1/users/profile/courses', { target, page, type })
  },
  calendarsFind(term: string, courseId?: number) {
    return api.get<CalendarType[]>('/v1/calendars/find', { term, courseId })
  },
  find(term: string) {
    return api.get<CoursesItemType[]>('/v1/courses/find', { term })
  },
}
