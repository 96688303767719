import { notification } from 'antd'
import i18n from 'i18next'

import { AppDispatch, RootState } from '@src/store/store'
import { profileApi, saveWorksheetRequest } from '@src/api/profile-api'
import { setUser, updateUserPhoto, updateWorksheet } from '@src/store/ducks/user/reducer'
import { HttpStatus } from '@src/types/httpStatus'
import { keysToCamelCase, keysToSnakeCase } from '@src/lib/utils'
import {
  setBackError, setCurators,
  setError, setIsEdit, setIsLoading, setIsLoadingSave, setIsLoadingUpdateCurator, setProfileUser,
  updateCuratorAC, updateProfilePhoto,
} from './reducer'

export const uploadImage = (image: any, userId: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('user_id', userId)
    const result = await profileApi.uploadImage(formData)
    const { user, profile } = getState()
    if (profile.user.id === user.id) {
      dispatch(updateUserPhoto(result.imageUrl))
    } else {
      dispatch(updateProfilePhoto(result.imageUrl))
    }
  } catch (err) {
    console.error(err)
    notification.error({
      message: 'Error',
      description:
        i18n.t('profile.uploadImageError'),
    })
  }
}

export const getUserProfile = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const result = await profileApi.getUser(id)
    dispatch(setProfileUser(result))
    dispatch(setIsLoading(false))
  } catch (err) {
    console.error(err)
    switch (err?.response?.status) {
      case HttpStatus.FORBIDDEN:
        dispatch(setError(HttpStatus.FORBIDDEN))
        break
      case HttpStatus.NOT_FOUND:
        dispatch(setError(HttpStatus.NOT_FOUND))
        break
      default:
        break
    }
  }
}

export const saveWorksheet = (data: saveWorksheetRequest) => async (dispatch: AppDispatch) => {
  try {
    const result = await profileApi.saveWorksheet(keysToSnakeCase(data))
    dispatch(updateWorksheet(result))
    dispatch(setIsEdit(false))
  } catch (err) {
    console.error(err)
    if (err?.response?.status === 400) {
      dispatch(setBackError(keysToCamelCase(err.response.data)))
    }
  } finally {
    dispatch(setIsLoadingSave(false))
  }
}

export const saveContactInfo = (data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const result = await profileApi.saveContactInfo(keysToSnakeCase(data))
    const { user } = getState()
    if (data.userId === user.id) {
      dispatch(setUser(result))
    } else {
      dispatch(setProfileUser(result))
    }
    dispatch(setIsEdit(false))
  } catch (err) {
    console.error(err)
    if (err?.response?.status === 400) {
      dispatch(setBackError(keysToCamelCase(err.response.data)))
    }
  } finally {
    dispatch(setIsLoadingSave(false))
  }
}

export const updateCurator = (data: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingUpdateCurator(true))
    const result = await profileApi.updateCurator(data)
    dispatch(updateCuratorAC({
      email: result.email,
      id: result.id,
      name: result.fullInfo.name,
      phone: result.phone,
      photo: result.fullInfo.photo,
    }))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoadingUpdateCurator(false))
  }
}

export const getCurators = () => async (dispatch: AppDispatch) => {
  try {
    const result = await profileApi.getCurators()
    dispatch(setCurators(result))
  } catch (err) {
    console.error(err)
  }
}
