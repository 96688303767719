import {
  FC, useCallback, useEffect, useMemo, useRef,
} from 'react'
import { EventCalendarType } from '@src/store/ducks/schedule/types'
import { ScheduleModalEnum } from '@src/types/schedule'
import { useTranslation } from 'react-i18next'
import { Form, Formik, FormikProps } from 'formik'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { Button, Spin } from 'antd'

import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import style from '@src/components/ScheduleModal/ScheduleModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import {
  getScheduleFreeDay, getScheduleTeachers, getScheduleTeachersFreeTime, updateLesson,
} from '@src/store/ducks/schedule/thunks'
import {
  selectScheduleCreateBackError,
  selectScheduleCreateData,
  selectScheduleIsLoadingCrateData,
} from '@src/store/ducks/schedule/selectors'
import { resetCreate } from '@src/store/ducks/schedule/reducer'
import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { CheckboxFormik } from '@src/components/common/CheckboxFormik/CheckboxFormik'

type DeleteLessonProps = {
  lesson: EventCalendarType
  setModalType: (type: ScheduleModalEnum) => void
  closeModal: () => void
}

export const EditLesson: FC<DeleteLessonProps> = ({ lesson, closeModal }) => {
  const formRef = useRef<FormikProps<typeof initialValues>>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const createData = useSelector(selectScheduleCreateData)
  const isLoadingCrateData = useSelector(selectScheduleIsLoadingCrateData)
  const backError = useSelector(selectScheduleCreateBackError)

  useEffect(() => {
    const startDate = moment(lesson.extendedProps.start).hour(0).minute(0).second(0)
      .utc()
      .format()
    const endDate = moment(lesson.extendedProps.start).hour(0).minute(0).second(0)
      .utc()
      .add(1, 'day')
      .format()
    dispatch(getScheduleFreeDay(lesson.extendedProps.calendarId, lesson.extendedProps.type, lesson.extendedProps.id))
    dispatch(getScheduleTeachers(lesson.extendedProps.calendarId, startDate, endDate, lesson.extendedProps.id))
    dispatch(getScheduleTeachersFreeTime(
      lesson.extendedProps.calendarId,
      lesson.extendedProps.teacherId,
      startDate,
      endDate,
      lesson.extendedProps.id,
      lesson.extendedProps?.student?.id,
    ))

    return () => {
      dispatch(resetCreate())
    }
  }, [dispatch, lesson])

  const initialValues = useMemo(() => ({
    title: lesson.extendedProps?.title || '',
    titleFr: lesson.extendedProps?.titleFr || '',
    link: lesson.extendedProps.link || '',
    calendarId: lesson.extendedProps.calendarId,
    lessonId: lesson.extendedProps.id,
    studentId: lesson.extendedProps?.student?.id,
    day: moment(lesson.extendedProps.start).format('YYYY-MM-DD') as string,
    teacherId: lesson.extendedProps.teacherId,
    start: moment(lesson.extendedProps.start).utc().format(),
    isIndividualBefore: !!lesson.extendedProps.isIndividualBefore,
  }), [lesson])

  const handleDay = useCallback((value) => {
    dispatch(getScheduleTeachers(
      lesson.extendedProps.calendarId,
      moment(value).utc().format(),
      moment(value).utc().add(1, 'day').format(),
      lesson.extendedProps.id,
    ))
    formRef.current?.setFieldValue('teacherId', null)
    formRef.current?.setFieldValue('start', null)
  }, [dispatch, lesson])

  const handleTeacher = useCallback((value) => {
    dispatch(
      getScheduleTeachersFreeTime(
        lesson.extendedProps.calendarId,
        value,
        moment(formRef.current?.values.day).utc().format(),
        moment(formRef.current?.values.day).utc().add(1, 'day').format(),
        lesson.extendedProps.id,
        lesson.extendedProps?.student?.id,
      ),
    )
    formRef.current?.setFieldValue('start', null)
  }, [dispatch, lesson])

  return (
    <div>
      <div className={style.modal_type}>
        {t('schedule.editTitle')}
      </div>
      <div className={style.modal_title}>{lesson.extendedProps.calendar.course.name}</div>
      <div className={style.modal_type}>
        {lesson.extendedProps.student ? t('schedule.individual') : t('schedule.group')}
      </div>
      <div className={style.modal_info}>
        {t('schedule.flow')}
        {': '}
        <span className="bold">{lesson.extendedProps.calendar.title || lesson.extendedProps.calendar.id}</span>
      </div>
      {lesson.extendedProps.student && (
        <div className={style.modal_info}>
          {t('schedule.student')}
          {': '}
          <NavLink to={`/profile/${lesson.extendedProps.student.userId}`} className="bold">
            {lesson.extendedProps.student.surname}
            {' '}
            {lesson.extendedProps.student.name}
          </NavLink>
        </div>
      )}

      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values) => dispatch(updateLesson(values, closeModal))}
      >
        {({ values }) => (
          <Form>
            {!lesson.extendedProps.student && (
              <>
                <FieldFormik name="title" placeholder={`${t('schedule.fieldName')} ru`} />
                <FieldFormik name="titleFr" placeholder={`${t('schedule.fieldName')} fr`} />
              </>
            )}
            <FieldFormik name="link" placeholder={t('schedule.link')} />
            <SelectFormik
              name="day"
              placeholder={t('schedule.selectDay')}
              options={createData.freeDay.map((item) => ({ value: item, label: moment(item).format('DD.MM.YYYY') }))}
              onChange={handleDay}
            />
            <SelectFormik
              name="teacherId"
              placeholder={t('schedule.selectTeacher')}
              options={createData.teachers.map((item) => ({ value: item.id, label: `${item.surname} ${item.name}` }))}
              disabled={!values.day}
              onChange={handleTeacher}
            />
            <SelectFormik
              name="start"
              placeholder={t('schedule.selectTime')}
              options={
                createData.teachersFreeTime.map((item) => (
                  {
                    value: moment(item.startTime).utc().format(),
                    label: `${moment(item.startTime).format('HH:mm')} - ${moment(item.endTime).format('HH:mm')}`,
                  }
                ))
              }
              disabled={!values.teacherId}
            />
            {!lesson.extendedProps.student && (
              <CheckboxFormik name="isIndividualBefore" className={style.individualBefore}>
                {t('schedule.individualBefore')}
              </CheckboxFormik>
            )}

            {backError && <div className={style.modal_error}>{backError}</div>}
            <Button className="btn_round" type="primary" htmlType="submit">{t('schedule.save')}</Button>
          </Form>
        )}
      </Formik>
      {isLoadingCrateData && (
        <div className={style.modal_loading}>
          <Spin />
        </div>
      )}
    </div>
  )
}
