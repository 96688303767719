import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Role, User, UserFullInfo, UserWorksheet,
} from '@src/types/user'

export const initialState = {
  id: null as null | number,
  email: null as null | string,
  roleId: Role.GUEST as Role,
  emailVerifiedAt: null,
  phone: null as null | string,
  fullName: '',
  fullInfo: {} as UserFullInfo,
  worksheet: {} as UserWorksheet,
  interfaceLang: null as null | string,
  isLoadingCreate: false,
  backError: null as null | object | string,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<User>) {
      state.id = payload.id
      state.email = payload.email
      state.roleId = payload.roleId
      state.phone = payload.phone
      state.fullInfo = payload.fullInfo ?? {}
      state.worksheet = payload.worksheet
      state.fullName = `${payload.fullInfo?.surname} ${payload.fullInfo?.name}`
      state.interfaceLang = payload.interfaceLang
    },
    updateWorksheet(state, { payload }: PayloadAction<UserWorksheet>) {
      state.worksheet = payload
    },
    updateUserPhoto(state, { payload }: PayloadAction<string>) {
      state.fullInfo.photo = payload
    },
    removeUser(state) {
      state.id = null
      state.email = null
      state.roleId = Role.GUEST
      state.phone = null
    },
    setInterfaceLang(state, { payload }: PayloadAction<string>) {
      state.interfaceLang = payload
    },
    setIsLoadingCreate(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingCreate = payload
    },
    setBackError(state, { payload }: PayloadAction<object | null | string>) {
      state.backError = payload
    },
    resetCreate(state) {
      state.backError = null
      state.isLoadingCreate = false
    },
  },
})

export type UserStateType = typeof initialState

export const {
  setUser, removeUser, setInterfaceLang, updateUserPhoto, updateWorksheet, setIsLoadingCreate,
  resetCreate, setBackError,
} = userSlice.actions

export const userReducer = userSlice.reducer
