export const parseLinks = (str: string) => {
  // eslint-disable-next-line max-len
  const re = /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim
  const res: any = []

  str.replace(re, (m, link, text) => {
    res.push(link ? (
      <a href={(link[0] === 'w' ? '//' : '') + link} key={res.length} target="_blank" rel="noreferrer">
        {link}
      </a>
    ) : text)
    return m
  })
  return res
}
