import { Button, Menu } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as Logo } from '@src/assets/logo.svg'
import { menuList } from '@src/components/Sitebar/SitebarLeft/menuList'
import { selectUser } from '@src/store/ducks/user/selectors'
import { AllowedTo } from '@src/components/common/AllowedTo/AllowedTo'
import { Role } from '@src/types/user'
import { CreateDropdown } from '@src/components/CreateDropdown/CreateDropdown'
import { selectAppIsOpenLeftSitebarMobile } from '@src/store/ducks/app/selectors'
import { AppDispatch } from '@src/store/store'
import { setIsOpenLeftSitebarMobile } from '@src/store/ducks/app/reducer'
import { getInitials } from '@src/lib/getInitials'
import { logout } from '@src/store/ducks/auth/thunks'
import { SelectLanguage } from '@src/components/SelectLanguage/SelectLanguage'
import style from './SitebarLeft.module.scss'

export const SitebarLeft = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector(selectUser)
  const isOpenSitebarMobile = useSelector(selectAppIsOpenLeftSitebarMobile)

  return (
    <div className={cn(style.left_sitebar, isOpenSitebarMobile && style.open)}>
      <a href={process.env.REACT_APP_SITE_URL} className={style.logo}>
        <Logo />
      </a>
      <div className={cn(style.profile)}>
        <div className={style.profile_avatar}>
          {user.fullInfo.photo ? (
            <img src={user.fullInfo.photo} alt={user.fullName} />
          ) : (
            <div>{getInitials(user.fullName)}</div>
          )}
        </div>
        <div className={style.profile_name}>{user.fullName}</div>
        <SelectLanguage />
      </div>
      <Menu
        mode="inline"
        // defaultSelectedKeys={['/']}
        selectedKeys={[`/${location.pathname.split('/')[1]}`]}
        className={style.menu}
      >
        {menuList[user.roleId].map((item) => (
          <Menu.Item key={item.url} className={style.menu_item}>
            <NavLink onClick={() => dispatch(setIsOpenLeftSitebarMobile(false))} to={item.url}>{t(item.title)}</NavLink>
          </Menu.Item>
        ))}
        <Menu.Item key="/change-password" className={cn(style.menu_item, style.menu_item_mobile)}>
          <NavLink onClick={() => dispatch(setIsOpenLeftSitebarMobile(false))} to="/change-password">
            {t('menu.changePassword')}
          </NavLink>
        </Menu.Item>
        <Menu.Item className={cn(style.menu_item, style.menu_item_mobile)}>
          <button onClick={() => { dispatch(logout()) }} type="button">
            {t('logout')}
          </button>
        </Menu.Item>
      </Menu>
      <AllowedTo roles={[Role.ADMIN]}>
        <div className={cn(style.addItem, style.homeworks)}>
          <CreateDropdown />
        </div>
      </AllowedTo>

      <div className={style.homeworks}>
        <a target="blanc" href={process.env.REACT_APP_MOODLE_URL}>
          <Button size="large" type="primary">
            {t('menu.homeworks')}
          </Button>
        </a>
      </div>
    </div>
  )
}
