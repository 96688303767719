import { RootState } from '@src/store/store'
import { initialState, TeachersStateType } from './reducer'

export const selectTeachers = (state: RootState): TeachersStateType => state.teachers || initialState

export const selectTeachersList = (state: RootState) => selectTeachers(state).list
export const selectTeachersIsLoading = (state: RootState) => selectTeachers(state).isLoading
export const selectTeachersMeta = (state: RootState) => selectTeachers(state).meta
export const selectTeachersSchedule = (state: RootState) => selectTeachers(state).schedule
export const selectTeachersName = (state: RootState) => selectTeachers(state).teacherName
export const selectTeachersIsLoadingExport = (state: RootState) => selectTeachers(state).isLoadingExport
