import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button, Col, Pagination, Row, Select,
} from 'antd'
import cn from 'classnames'
import moment from 'moment-timezone'

import { AppDispatch } from '@src/store/store'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { getCourses } from '@src/store/ducks/courses/thunks'
import {
  selectCoursesIsLoading,
  selectCoursesList,
  selectCoursesMeta,
  selectCoursesTargets,
} from '@src/store/ducks/courses/selectors'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { Preloader } from '@src/components/common/Preloader/Preloader'
import style from './Courses.module.scss'

type TypeOptionType = {
  value: string,
  label: 'courses.individual' | 'courses.group'
}

const typeOption: TypeOptionType[] = [
  {
    value: 'individual',
    label: 'courses.individual',
  },
  {
    value: 'groups',
    label: 'courses.group',
  },
]

export const Courses = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const history = useHistory()
  const target = useQuery('target')
  const page = useQuery('page')
  const type = useQuery('type')
  const targets = useSelector(selectCoursesTargets)
  const coursesList = useSelector(selectCoursesList)
  const coursesMeta = useSelector(selectCoursesMeta)
  const isLoading = useSelector(selectCoursesIsLoading)

  useEffect(() => {
    dispatch(setBreadcrumbs([{ url: '/', title: t('controlPanel.title') }, { title: t('courses.title') }]))
  }, [dispatch, t])

  useEffect(() => {
    dispatch(getCourses(page ? +page : 1, target ?? undefined, type ?? undefined))
  }, [dispatch, page, target, type])

  const handleTarget = (e: string) => {
    history.push({
      search: addQuery({ target: e, page: '1' }),
    })
  }

  const handlePagination = useCallback((e: number) => {
    history.push({
      search: addQuery({ page: `${e}` }),
    })
  }, [history])

  const handleType = useCallback((e: string) => {
    history.push({
      search: addQuery({ type: e || '', page: '1' }),
    })
  }, [history])

  return (
    <div>
      <div className={style.title}>
        <h1 className="page_title">{t('courses.title')}</h1>
        <Select
          placeholder={t('courses.selectCourse')}
          allowClear
          onChange={handleType}
          value={type ?? undefined}
          className={style.type}
        >
          {typeOption.map((item) => (
            <Select.Option key={item.value} value={item.value}>{t(`${item.label}`)}</Select.Option>
          ))}
        </Select>
      </div>

      <div className={style.targets_title}>{t('courses.targetsTitle')}</div>
      <div className={style.targets_container}>
        <Button
          onClick={() => handleTarget('')}
          type={target ? 'default' : 'primary'}
          className={style.targets_item}
        >
          {t('courses.targetsAll')}
        </Button>
        {targets.map((item) => (
          <Button
            key={item.code}
            onClick={() => handleTarget(item.code)}
            type={target === item.code ? 'primary' : 'default'}
            className={style.targets_item}
          >
            {item.name}
          </Button>
        ))}
      </div>

      <Preloader loading={isLoading}>
        <div className={style.courses}>
          {!coursesList.length && <div className={style.courses_noCourses}>{t('courses.noCourses')}</div>}
          <Row gutter={[30, 30]}>
            {coursesList.map((item) => (
              <Col sm={24} md={12} xl={8} key={item.id}>
                <a
                  href={`${process.env.REACT_APP_SITE_URL}/courses/${item.slug}`}
                  target="_blank"
                  className={style.courses_item}
                  rel="noreferrer"
                >
                  {!!item.bought && (
                    <div className={style.courses_item_bought}>{t('courses.bought')}</div>
                  )}
                  <div className={cn(style.courses_item__inner, item.bought && style.courses_item__bought)}>
                    <div className={style.courses_item_category} style={{ background: item.category.color }}>
                      {item.category.name}
                    </div>
                    <div className={style.courses_item_title}>
                      {item.name}
                    </div>
                    <div className={style.courses_item_time}>
                      <span>
                        {t('courses.start')}
                        {' '}
                        {moment(item.startDate).format('DD MMMM')}
                      </span>
                      <span>{item.courseDuration}</span>
                    </div>
                    <div className={style.courses_item_regularity}>
                      {t('courses.regularity')}
                      {' '}
                      <span className="bold">{item.regularity}</span>
                    </div>
                    <div className={style.courses_item_lessonDuration}>
                      {t('courses.lessonDuration')}
                      {' '}
                      <span className="bold">{item.lessonDuration}</span>
                    </div>
                    <div className={style.courses_item_description}>{item.shortDescription}</div>

                    <div className={style.courses_item_price}>
                      {item.price}
                      {'€ '}
                      <span>
                        \
                        {t('courses.course')}
                      </span>
                    </div>
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      </Preloader>
      {coursesMeta.totalCount > coursesMeta.perPage && (
        <Pagination
          className={style.pagination}
          disabled={isLoading}
          current={coursesMeta.currentPage}
          defaultPageSize={coursesMeta.perPage}
          total={coursesMeta.totalCount}
          onChange={handlePagination}
        />
      )}
    </div>
  )
}
