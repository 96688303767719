import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Button, Col, Pagination, Popconfirm, Row, Select,
} from 'antd'
import { NavLink, useHistory } from 'react-router-dom'

import { AppDispatch } from '@src/store/store'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { deleteMaterials, getMaterials, getMaterialsTypes } from '@src/store/ducks/materials/thunks'
import {
  selectMaterialsDeleteLoading,
  selectMaterialsIsLoading,
  selectMaterialsList,
  selectMaterialsMeta,
  selectMaterialsTypes,
} from '@src/store/ducks/materials/selectors'
import { MultiLink } from '@src/components/common/MultiLink/MultiLink'
import { Preloader } from '@src/components/common/Preloader/Preloader'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { AllowedTo } from '@src/components/common/AllowedTo/AllowedTo'
import { Role } from '@src/types/user'
import { SearchInput } from '@src/components/common/SearchInput/SearchInput'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import style from './Materials.module.scss'

export const Materials = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const history = useHistory()
  const page = useQuery('page')
  const term = useQuery('term')
  const type = useQuery('type')
  const materialsList = useSelector(selectMaterialsList)
  const materialsMeta = useSelector(selectMaterialsMeta)
  const isLoading = useSelector(selectMaterialsIsLoading)
  const materialsTypes = useSelector(selectMaterialsTypes)
  const deleteLoading = useSelector(selectMaterialsDeleteLoading)

  useEffect(() => {
    dispatch(setBreadcrumbs([{ url: '/', title: t('controlPanel.title') }, { title: t('materials.title') }]))
  }, [dispatch, t])

  useEffect(() => {
    dispatch(getMaterialsTypes())
  }, [dispatch])

  useEffect(() => {
    dispatch(getMaterials({ page: page ? +page : 1, term: term || undefined, type: type ? +type : undefined }))
  }, [dispatch, page, term, type])

  const handlePagination = useCallback((e: number) => {
    history.push({
      search: addQuery({ page: `${e}` }),
    })
  }, [history])

  const handleType = useCallback((e: number) => {
    history.push({
      search: addQuery({ type: `${e || ''}`, page: '1' }),
    })
  }, [history])

  const handleSearch = useCallback((e: string) => {
    history.push({
      search: addQuery({ term: e, page: '1' }),
    })
  }, [history])

  return (
    <div>
      <div className={style.header}>
        <div>
          <h1 className="page_title">{t('materials.title')}</h1>
          <p className="page_description">{t('materials.description')}</p>
        </div>
        <AllowedTo roles={[Role.ADMIN, Role.TEACHER, Role.SECRETARY, Role.ORGANIZER]}>
          <NavLink to="/materials/create">
            <Button type="primary">{t('materials.create')}</Button>
          </NavLink>
        </AllowedTo>
      </div>

      <div className={style.actions}>
        <SearchInput name="term" placeholder={t('materials.search')} onChange={handleSearch} initialValue={term} />
        <Select
          className={style.actions_select}
          placeholder={t('materials.typePlaceholder')}
          allowClear
          onChange={handleType}
          value={type ? +type : undefined}
        >
          {materialsTypes.map((option) => (
            <Select.Option key={option.id} value={option.id}>{option.title}</Select.Option>
          ))}
        </Select>
      </div>

      <Preloader loading={isLoading}>
        {!materialsList.length && (
          <div className={style.no_materials}>{t('materials.noMaterials')}</div>
        )}
        <Row gutter={[{ sm: 15, lg: 15, xl: 30 }, {
          xs: 15, sm: 15, md: 15, lg: 30,
        }]}
        >
          {materialsList.map((item) => (
            <Col sm={24} md={12} xl={8} key={item.id}>
              <div className={style.material}>
                <AllowedTo roles={[Role.ADMIN, Role.TEACHER, Role.SECRETARY, Role.ORGANIZER]}>
                  <div className={style.material_actions}>
                    <NavLink to={`/materials/edit/${item.id}`} className={style.material_edit}>
                      <EditOutlined />
                    </NavLink>
                    <Popconfirm
                      title={t('popconfirm.title')}
                      okText={t('popconfirm.ok')}
                      cancelText={t('popconfirm.cancel')}
                      onConfirm={
                      () => dispatch(
                        deleteMaterials(item.id, page ? +page : 1, term || undefined, type ? +type : undefined),
                      )
                    }
                      disabled={deleteLoading.some((loadingId) => item.id === loadingId)}
                    >
                      <button
                        type="button"
                        className={style.material_delete}
                        disabled={deleteLoading.some((loadingId) => item.id === loadingId)}
                      >
                        <DeleteOutlined />
                      </button>
                    </Popconfirm>
                  </div>
                </AllowedTo>
                <div className={style.material_title}>{item.title}</div>
                <div className={style.material_description} dangerouslySetInnerHTML={{ __html: item.description }} />
                <MultiLink url={item.link} title={t('materials.link')} className={style.material_link} isIcon />
              </div>
            </Col>
          ))}
        </Row>
      </Preloader>
      {materialsMeta.totalCount > materialsMeta.perPage && (
        <Pagination
          className={style.pagination}
          disabled={isLoading}
          current={materialsMeta.currentPage}
          defaultPageSize={materialsMeta.perPage}
          total={materialsMeta.totalCount}
          onChange={handlePagination}
        />
      )}
    </div>
  )
}
