import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CoursesItemType, CoursesTargetType } from '@src/store/ducks/courses/types'
import { ResponseMetaType } from '@src/api/api'

export const initialState = {
  list: [] as CoursesItemType[],
  meta: {
    currentPage: 1,
    lastPage: 0,
    perPage: 6,
    totalCount: 0,
  },
  targets: [] as CoursesTargetType[],
  isLoading: false,
}

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCoursesList(state, { payload }: PayloadAction<CoursesItemType[]>) {
      state.list = payload
    },
    setCoursesTargets(state, { payload }: PayloadAction<CoursesTargetType[]>) {
      state.targets = payload
    },
    setCoursesMeta(state, { payload }: PayloadAction<ResponseMetaType>) {
      state.meta = payload
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
  },
})

export type CoursesStateType = typeof initialState

export const {
  setCoursesList, setCoursesTargets, setIsLoading, setCoursesMeta,
} = coursesSlice.actions

export const coursesReducer = coursesSlice.reducer
