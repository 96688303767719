import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ReactComponent as Logo } from '@src/assets/logo.svg'
import { ReactComponent as Splat1 } from '@src/assets/splat-1.svg'
import { ReactComponent as Splat2 } from '@src/assets/splat-2.svg'
import { SelectLanguage } from '@src/components/SelectLanguage/SelectLanguage'
import style from './JoinContainer.module.scss'

type JoinContainerProps = {
  className?: string
  title?: string
  header?: ReactNode
  unLogo?: boolean
  description?: string
}

export const JoinContainer: FC<JoinContainerProps> = ({
  children,
  unLogo,
  title,
  className,
  header,
  description,
}) => {
  const { t } = useTranslation()
  return (
    <div className={style.main}>
      <div className={style.splat_1}>
        <Splat1 />
      </div>

      <div>
        {!unLogo && (
          <>
            <a href={process.env.REACT_APP_SITE_URL} className={style.logo}>
              <Logo />
            </a>
            <div className={style.lang}>
              <SelectLanguage />
            </div>
          </>
        )}
        {header}
        <div className={cn(style.content, className)}>
          <h1 className={style.title}>{title}</h1>
          <div className={style.description}>{description}</div>
          <div className={style.children}>
            {children}
          </div>
        </div>
      </div>
      <div className={style.splat_2}>
        <Splat2 />
      </div>

      <div className={style.copy}>{t('copy')}</div>
    </div>
  )
}
