import { AppDispatch } from '@src/store/store'
import { organizersApi } from '@src/api/user/organizers-api'
import { userApi } from '@src/api/user/user-api'
import { notification } from 'antd'
import i18n from 'i18next'
import {
  setIsLoading, setOrganizersMeta, setOrganizersList, updateOrganizerStatus,
} from './reducer'

export const getOrganizers = (page: number, term?: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoading(true))
    const result = await organizersApi.getList(page, term)
    dispatch(setOrganizersList(result.items))
    dispatch(setOrganizersMeta(result.meta))
  } catch (err) {
    console.error(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const updateStatus = (userId: number, statusCode: string) => async (dispatch: AppDispatch) => {
  try {
    const result = await userApi.updateStatus(userId, statusCode)
    dispatch(updateOrganizerStatus({ userId, status: result }))
  } catch (err) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description: err?.response?.data?.error,
    })
  }
}
