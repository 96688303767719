import { RootState } from '@src/store/store'
import { MaterialsStateType, initialState } from './reducer'

export const selectMaterials = (state: RootState): MaterialsStateType => state.materials || initialState

export const selectMaterialsList = (state: RootState) => selectMaterials(state).list
export const selectMaterialsMeta = (state: RootState) => selectMaterials(state).meta
export const selectMaterialsIsLoading = (state: RootState) => selectMaterials(state).isLoading
export const selectMaterialsIsLoadingCreate = (state: RootState) => selectMaterials(state).isLoadingCreate
export const selectMaterialsTypes = (state: RootState) => selectMaterials(state).types
export const selectMaterialsDeleteLoading = (state: RootState) => selectMaterials(state).deleteLoading
export const selectMaterialsEditData = (state: RootState) => selectMaterials(state).editData
export const selectMaterialsEditNotFound = (state: RootState) => selectMaterials(state).editNotFound
