import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LessonTypeEnum } from '@src/types/lessons'
import { ScheduleFiltersType } from '@src/types/schedule'
import { ResponseMetaType } from '@src/api/api'
import { StudentsItemType } from '@src/store/ducks/students/types'
import { CalendarType } from '@src/types/courses'
import {
  EventCalendarType,
  ScheduleCalendarTeachers,
  ScheduleCalendarTeachersFreeTime,
  ScheduleCalendarType,
  ScheduleCourseType,
} from './types'

export const initialState = {
  events: [] as EventCalendarType[],
  isLoading: false,
  isLoadingDelete: false,
  isLoadingCreateData: false,
  create: {
    courses: [] as ScheduleCourseType[],
    calendars: [] as ScheduleCalendarType[],
    teachers: [] as ScheduleCalendarTeachers[],
    teachersFreeTime: [] as ScheduleCalendarTeachersFreeTime[],
    freeDay: [] as Array<string>,
  },
  filters: {
    courses: [],
    teachers: [],
    students: [],
    calendars: [],
  } as ScheduleFiltersType & { students: StudentsItemType[], calendars: CalendarType[] },
  createBackError: '',
  isLoadingFilters: false,
  meta: {
    currentPage: 1,
    lastPage: 0,
    perPage: 0,
    totalCount: 0,
  },
  isLoadingExport: false,
}

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setEvents(state, { payload }: PayloadAction<EventCalendarType[]>) {
      state.events = payload
    },
    addEvent(state, { payload }: PayloadAction<EventCalendarType>) {
      state.events.push(payload)
    },
    updateEvent(state, { payload }: PayloadAction<EventCalendarType>) {
      state.events = state.events.map((item) => {
        if (item.id === payload.id) {
          return payload
        }
        return item
      })
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setIsLoadingDelete(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingDelete = payload
    },
    setIsLoadingCreateData(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingCreateData = payload
    },
    deleteEvent(state, { payload }: PayloadAction<{ id: number, type: LessonTypeEnum }>) {
      state.events = state.events.filter(
        (item) => !(item.extendedProps.id === payload.id && item.extendedProps.type === payload.type),
      )
    },
    setCourses(state, { payload }: PayloadAction<ScheduleCourseType[]>) {
      state.create.courses = payload
    },
    setCalendars(state, { payload }: PayloadAction<ScheduleCalendarType[]>) {
      state.create.calendars = payload
    },
    setTeachers(state, { payload }: PayloadAction<ScheduleCalendarTeachers[]>) {
      state.create.teachers = payload
    },
    setTeachersFreeTime(state, { payload }: PayloadAction<ScheduleCalendarTeachersFreeTime[]>) {
      state.create.teachersFreeTime = payload
    },
    setFreeDay(state, { payload }: PayloadAction<Array<string>>) {
      state.create.freeDay = payload
    },
    resetCreate(state) {
      state.create = initialState.create
      state.createBackError = ''
    },
    setCreateBackError(state, { payload }) {
      state.createBackError = payload
    },
    setIsLoadingFilters(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingFilters = payload
    },
    setScheduleFilters(state, { payload }: PayloadAction<
      ScheduleFiltersType | { students: StudentsItemType[] } | { calendars: CalendarType[] }
    >) {
      state.filters = {
        ...state.filters,
        ...payload,
      }
    },
    setScheduleMeta(state, { payload }: PayloadAction<ResponseMetaType>) {
      state.meta = payload
    },
    setIsLoadingExport(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingExport = payload
    },
  },
})

export type ScheduleStateType = typeof initialState

export const {
  setEvents, deleteEvent, setIsLoading, setIsLoadingDelete,
  setCourses, setIsLoadingCreateData, setCalendars,
  setTeachers, setTeachersFreeTime, resetCreate, addEvent,
  setCreateBackError, setFreeDay, updateEvent, setIsLoadingFilters,
  setScheduleFilters, setScheduleMeta, setIsLoadingExport,
} = scheduleSlice.actions

export const scheduleReducer = scheduleSlice.reducer
