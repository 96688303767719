import { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'moment/locale/ru'
import 'moment/locale/fr'
import { useTranslation } from 'react-i18next'
import ruAnt from 'antd/lib/locale/ru_RU'
import enAnt from 'antd/lib/locale/en_US'
import frAnt from 'antd/lib/locale/fr_FR'
import { ConfigProvider } from 'antd'

import { Routes } from '@src/routes/routes'
import { Preloader } from '@src/components/common/Preloader/Preloader'
import { AppDispatch } from '@src/store/store'
import { fetchMe } from '@src/store/ducks/user/thunks'
import { PageContainer } from '@src/components/PageContainer/PageContainer'
import { selectAuthIsAuth } from '@src/store/ducks/auth/selectors'
import { selectAuthIsInitialized } from '@src/store/ducks/app/selectors'
import { selectUser } from '@src/store/ducks/user/selectors'
import { Role } from '@src/types/user'
import { setTypeRightSitebar, TypeRightSitebarEnum } from '@src/store/ducks/app/reducer'
import { socketApi, SubscribePresenceCallback } from '@src/api/socket-api'
import moment from 'moment-timezone'
import { getDialogs } from '@src/store/ducks/chat/thunks'
import { DialogsType } from '@src/store/ducks/chat/types'
import {
  addOnlineUser, removeOnlineUser, setDialogs, setOnlineUsers,
} from '@src/store/ducks/chat/reducer'
import { selectChatDialogsId } from '@src/store/ducks/chat/selectors'

const App: FC = () => {
  const isInitialized = useSelector(selectAuthIsInitialized)
  const isAuth = useSelector(selectAuthIsAuth)
  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector(selectUser)
  const dialogsId = useSelector(selectChatDialogsId)
  const { i18n } = useTranslation()

  useEffect(() => {
    dispatch(fetchMe())
  }, [dispatch])

  // конект к сокет сервера
  useEffect(() => {
    if (user.id) {
      console.log(user.id)
      socketApi.connect()
    }

    return () => {
      socketApi.disconnect()
    }
  }, [user.id])

  // фирст тип правого сйтбара
  useEffect(() => {
    switch (user.roleId) {
      case Role.STUDENT:
        dispatch(setTypeRightSitebar(TypeRightSitebarEnum.CURATOR))
        break
      default:
        dispatch(setTypeRightSitebar(TypeRightSitebarEnum.DEFAULT))
    }
  }, [dispatch, user.roleId])

  // установка языка для moment
  useEffect(() => {
    moment.locale(i18n.language)
  }, [i18n.language])

  // запос диалогов
  useEffect(() => {
    if (isAuth) {
      dispatch(getDialogs())
    }
  }, [dispatch, isAuth])

  // поднятие сокетов для диалогов
  useEffect(() => {
    let unsubscribe = () => {}
    let offEvent = () => {}
    if (user.id) {
      unsubscribe = socketApi.subscribePrivate(`user_dialogs.${user.id}`)
      offEvent = socketApi.on('DialogsEvent', ({ data }: { data: DialogsType[] }) => {
        dispatch(setDialogs(data))
      })
    }

    return () => {
      unsubscribe()
      offEvent()
    }
  }, [dispatch, user.id])

  // сокет онлайн статуса
  useEffect(() => {
    // eslint-disable-next-line
    const unsubscribeArr = dialogsId.map((dialogId) => {
      return socketApi.subscribePresence(`dialog.${dialogId}`, (members, type) => {
        switch (type) {
          case SubscribePresenceCallback.SUBSCRIBED:
            dispatch(setOnlineUsers(members))
            break
          case SubscribePresenceCallback.JOINING:
            dispatch(addOnlineUser(members))
            break
          case SubscribePresenceCallback.LAVING:
            dispatch(removeOnlineUser(members))
            break
          default:
            break
        }
      })
    })

    return () => {
      unsubscribeArr.forEach((item) => {
        item()
      })
    }
  }, [dialogsId, dispatch])

  const locale = useMemo(() => {
    switch (i18n.language) {
      case 'ru':
        return ruAnt
      case 'en':
        return enAnt
      case 'fr':
        return frAnt
      default:
        return ruAnt
    }
  }, [i18n.language])

  return (
    <ConfigProvider locale={locale}>
      <Preloader loading={!isInitialized}>
        <div className="App">
          {isAuth ? (
            <PageContainer>
              <Routes />
            </PageContainer>
          ) : (
            <Routes />
          )}
        </div>
      </Preloader>
    </ConfigProvider>
  )
}
export default App
