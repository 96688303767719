import { useEffect, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'

import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import {
  selectProfileCurators,
  selectProfileIsLoadingUpdateCurator,
  selectProfileUser,
} from '@src/store/ducks/profile/selectors'
import { AppDispatch } from '@src/store/store'
import { getCurators, updateCurator } from '@src/store/ducks/profile/thunks'
import { Role } from '@src/types/user'
import { selectUser } from '@src/store/ducks/user/selectors'
import style from './Curator.module.scss'

export const Curator = () => {
  const { t } = useTranslation()
  const user = useSelector(selectProfileUser)
  const me = useSelector(selectUser)
  const curators = useSelector(selectProfileCurators)
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(selectProfileIsLoadingUpdateCurator)

  const isEdit = useMemo(() => [Role.ADMIN, Role.ORGANIZER, Role.SECRETARY].includes(me.roleId), [me.roleId])

  useEffect(() => {
    if (isEdit) {
      dispatch(getCurators())
    }
  }, [dispatch, isEdit])

  const initialValues = useMemo(() => ({
    studentId: user.fullInfo.id,
    userId: curators.find((item) => item.userId === user.fullInfo?.curator?.id)?.userId
      || user.fullInfo?.curator?.name,
  }), [curators, user])

  return (
    <div className={style.container}>
      <Formik initialValues={initialValues} onSubmit={(values) => dispatch(updateCurator(values))}>
        {() => (
          <Form>
            <SelectFormik
              name="userId"
              options={curators.map((item) => ({ value: item.userId, label: `${item.surname} ${item.name}` }))}
              placeholder={t('profile.curator.title')}
              showSearch
              disabled={!isEdit}
            />

            {isEdit && (
              <Button loading={isLoading} type="primary" htmlType="submit">{t('profile.saveBtn')}</Button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}
