import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { DateClickArg } from '@fullcalendar/interaction'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import {
  Button, Menu, Modal,
} from 'antd'
import moment from 'moment-timezone'
import {
  NavLink, Route, Switch, useLocation, useParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { InfoLesson } from '@src/components/ScheduleModal/InfoLesson/InfoLesson'
import { ScheduleModalEnum } from '@src/types/schedule'
import { DeleteLesson } from '@src/components/ScheduleModal/DeleteLesson/DeleteLesson'
import { EditLesson } from '@src/components/ScheduleModal/EditLesson/EditLesson'
import { CreateLesson } from '@src/components/ScheduleModal/CreateLesson/CreateLesson'
import { ScheduleCalendar } from '@src/components/Schedule/ScheduleCalendar/ScheduleCalendar'
import { ScheduleTable } from '@src/components/Schedule/ScheduleTable/ScheduleTable'
import { Filters } from '@src/components/Table/Filters/Filters'
import { TableFiltersType } from '@src/types/tableFilters'
import {
  getCalendarsFilter, getFilters, getSchedule, getStudentsFilter, scheduleExport,
} from '@src/store/ducks/schedule/thunks'
import { AppDispatch } from '@src/store/store'
import {
  selectScheduleFilters,
  selectScheduleIsLoadingExport,
  selectScheduleIsLoadingFilters,
} from '@src/store/ducks/schedule/selectors'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { selectUser } from '@src/store/ducks/user/selectors'
import { Role } from '@src/types/user'
import style from './Schedule.module.scss'

export const Schedule = () => {
  const { t } = useTranslation()
  const params = useParams<{ table?: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const isLoadingFilters = useSelector(selectScheduleIsLoadingFilters)
  const isLoadingExport = useSelector(selectScheduleIsLoadingExport)
  const filtersData = useSelector(selectScheduleFilters)
  const me = useSelector(selectUser)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [modalType, setModalType] = useState<ScheduleModalEnum | null>(null)
  const [modalEvent, setModalEvent] = useState<any>(null)
  const [createDate, setCreateDate] = useState<Date>(new Date())
  const [paramsFilters, setParamsFilters] = useState({
    page: 1,
    dates: { start: '', end: '' },
    filters: {},
  })

  useEffect(() => {
    dispatch(setBreadcrumbs([{ url: '/', title: t('controlPanel.title') }, { title: t('schedule.title') }]))
  }, [dispatch, t])

  useEffect(() => {
    if (params.table) {
      setParamsFilters((prevState) => ({
        ...prevState,
        dates: {
          start: moment().subtract(15, 'day').format(),
          end: moment().add(15, 'day').format(),
        },
      }))
    }
  }, [params.table])

  useEffect(() => {
    if (paramsFilters.dates.start && paramsFilters.dates.end) {
      dispatch(getSchedule(
        moment(paramsFilters.dates.start).utc().format(),
        moment(paramsFilters.dates.end).utc().format(),
        !!params.table,
        params.table ? paramsFilters.page : null,
        paramsFilters.filters,
      ))
    }
  }, [paramsFilters, dispatch])// eslint-disable-line

  const dateClick = useCallback((dateArg: DateClickArg) => {
    if (moment(dateArg.date).isAfter(new Date())) {
      setCreateDate(dateArg.date)
      setModalType(ScheduleModalEnum.CREATE)
      setIsOpenModal(true)
    }
  }, [])

  const closeModal = useCallback(() => {
    setIsOpenModal(false)
    setModalType(null)
  }, [])

  const filtersList = useMemo<TableFiltersType>(() => {
    const filters: TableFiltersType = [
      {
        type: 'select',
        placeholder: t('students.filters.course'),
        dataIndex: 'course',
        showSearch: true,
        width: '200px',
        options: filtersData.courses.map((item) => ({ value: item.id, label: item.name })),
      },
      {
        type: 'select',
        placeholder: t('schedule.filters.student'),
        dataIndex: 'student',
        showSearch: true,
        width: '200px',
        onSearch: _.debounce((value: string) => {
          if (value.length > 1) dispatch(getStudentsFilter(value))
        }, 1000),
        options: filtersData.students.map((item) => ({ value: item.id, label: `${item.surname} ${item.name}` })),
      },
      {
        type: 'select',
        placeholder: t('schedule.filters.stream'),
        dataIndex: 'calendar',
        showSearch: true,
        width: '200px',
        onSearch: _.debounce((value: string) => {
          if (value.length > 1) dispatch(getCalendarsFilter(value))
        }, 1000),
        options: filtersData.calendars.map((item) => (
          {
            value: item.id,
            // eslint-disable-next-line max-len
            label: `${item.course.name}, ${t('schedule.flow')}: ${item.title || item.id} - ${moment(item.startDate).format('DD.MM')}`,
          }
        )),
      },
    ]

    if ([Role.ADMIN, Role.SECRETARY, Role.ORGANIZER].includes(me.roleId)) {
      filters.push({
        type: 'select',
        placeholder: t('schedule.filters.teacher'),
        dataIndex: 'teacher',
        showSearch: true,
        width: '200px',
        options: filtersData.teachers.map((item) => ({ value: item.id, label: `${item.surname} ${item.name}` })),
      })
    }

    if (params.table) {
      filters.unshift({
        type: 'rangePicker',
        placeholder: [t('schedule.filters.dateFrom'), t('schedule.filters.dateTo')],
        dataIndex: 'date',
        defaultValue: [moment(paramsFilters.dates.start), moment(paramsFilters.dates.end)],
        notAllowClear: true,
      })
    }

    return filters
  }, [dispatch, filtersData, me.roleId, params.table, paramsFilters, t])

  const handleFilters = useCallback((e: Array<{ columns: string, value: any }>) => {
    const dateEvent = e.find((item) => item.columns === 'date')

    setParamsFilters((prevState) => ({
      ...prevState,
      dates: {
        start: dateEvent ? dateEvent.value.from : prevState.dates.start,
        end: dateEvent ? dateEvent.value.to : prevState.dates.end,
      },
      filters: { ...prevState.filters, filters: e },
    }))
  }, [])

  return (
    <div className="schedule">
      <h1 className={cn(style.title, 'page_title')}>{t('schedule.title')}</h1>
      <div className={style.menu}>
        <Menu selectedKeys={[location.pathname]} mode="horizontal">
          <Menu.Item key="/schedule">
            <NavLink className={style.menu_link} to="/schedule">
              {t('schedule.type.calendar')}
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/schedule/table">
            <NavLink className={style.menu_link} to="/schedule/table">
              {t('schedule.type.table')}
            </NavLink>
          </Menu.Item>
        </Menu>
      </div>

      <div className={style.filters}>
        <Filters
          filters={filtersList}
          onChange={(e) => handleFilters(e)}
          onOpen={() => { dispatch(getFilters()) }}
          loading={isLoadingFilters}
          onClose={
            () => setParamsFilters((prevState) => ({ ...prevState, filters: { ...prevState.filters, filters: {} } }))
          }
        />
        <Button
          onClick={() => {
            dispatch(scheduleExport(moment(paramsFilters.dates.start).utc().format(), moment(paramsFilters.dates.end).utc().format(), paramsFilters.filters))
          }}
          type="primary"
          loading={isLoadingExport}
        >
          Экспорт
        </Button>
      </div>

      <Switch>
        <Route path="/schedule" exact>
          <ScheduleCalendar
            dates={paramsFilters.dates}
            setDates={(e) => setParamsFilters((prevState) => ({
              ...prevState,
              dates: e,
            }))}
            dateClick={dateClick}
            setModalEvent={setModalEvent}
            setModalType={setModalType}
            setIsOpenModal={setIsOpenModal}
          />
        </Route>
        <Route path="/schedule/table" exact>
          <ScheduleTable
            setModalType={setModalType}
            setIsOpenModal={setIsOpenModal}
            setModalEvent={setModalEvent}
            setParamsFilters={setParamsFilters}
          />
        </Route>
      </Switch>

      {isOpenModal && (
        <Modal visible={isOpenModal} onCancel={closeModal} footer={null}>
          {modalType === ScheduleModalEnum.INFO && (
            <InfoLesson lesson={modalEvent} setModalType={setModalType} />
          )}
          {modalType === ScheduleModalEnum.DELETE && (
            <DeleteLesson lesson={modalEvent} setModalType={setModalType} closeModal={closeModal} />
          )}
          {modalType === ScheduleModalEnum.EDIT && (
            <EditLesson lesson={modalEvent} setModalType={setModalType} closeModal={closeModal} />
          )}
          {modalType === ScheduleModalEnum.CREATE && (
            <CreateLesson createDate={createDate} closeModal={closeModal} />
          )}
        </Modal>
      )}
    </div>
  )
}
