import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { EventCalendarType } from '@src/store/ducks/schedule/types'
import style from '@src/components/ScheduleModal/ScheduleModal.module.scss'
import { ScheduleModalEnum } from '@src/types/schedule'
import { AppDispatch } from '@src/store/store'
import { deleteLesson } from '@src/store/ducks/schedule/thunks'
import { selectScheduleIsLoadingDelete } from '@src/store/ducks/schedule/selectors'

type DeleteLessonProps = {
  lesson: EventCalendarType
  setModalType: (type: ScheduleModalEnum) => void
  closeModal: () => void
}

export const DeleteLesson: FC<DeleteLessonProps> = ({ lesson, setModalType, closeModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(selectScheduleIsLoadingDelete)

  return (
    <div className={style.modal__center}>
      <div className={style.modal_type}>
        {t('schedule.reallyDeleteTitle')}
      </div>
      <div className={style.modal_title}>{lesson.extendedProps.calendar.course.name}</div>
      <div className={style.modal_time}>
        <span className={style.modal_date}>{moment(lesson.extendedProps.start).format('DD MMMM')}</span>
        {' '}
        {moment(lesson.extendedProps.start).format('HH:mm')}
        {' - '}
        {moment(lesson.extendedProps.end).format('HH:mm')}
      </div>
      <div className={style.modal_info}>
        {t('schedule.flow')}
        {': '}
        <span className="bold">{lesson.extendedProps.calendar.title || lesson.extendedProps.calendar.id}</span>
      </div>
      <div className={style.modal_info}>
        {t('schedule.teacher')}
        {': '}
        <NavLink to={`/profile/${lesson.extendedProps.teacher.userId}`} className="bold">
          {lesson.extendedProps.teacher.surname}
          {' '}
          {lesson.extendedProps.teacher.name}
        </NavLink>
      </div>
      {lesson.extendedProps.student && (
        <div className={style.modal_info}>
          {t('schedule.student')}
          {': '}
          <NavLink to={`/profile/${lesson.extendedProps.student.userId}`} className="bold">
            {lesson.extendedProps.student.surname}
            {' '}
            {lesson.extendedProps.student.name}
          </NavLink>
        </div>
      )}

      <div className={style.modal_actions}>
        <Button
          className="btn_round"
          onClick={() => setModalType(ScheduleModalEnum.INFO)}
          type="default"
          disabled={isLoading}
        >
          {t('schedule.no')}
        </Button>
        <Button
          onClick={() => dispatch(deleteLesson(lesson.extendedProps.id, lesson.extendedProps.type, closeModal))}
          className="btn_delete btn_round"
          loading={isLoading}
        >
          {t('schedule.reallyDelete')}
        </Button>

      </div>
    </div>
  )
}
