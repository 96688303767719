import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Popover, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment-timezone'
import { Key, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { AppDispatch } from '@src/store/store'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import {
  deleteStudent, getFiltersStudents, getStudents, studentsExport, updateStatus,
} from '@src/store/ducks/students/thunks'
import {
  selectStudentsFilters,
  selectStudentsIsLoading, selectStudentsIsLoadingExport,
  selectStudentsIsLoadingFilters,
  selectStudentsList,
  selectStudentsMeta,
} from '@src/store/ducks/students/selectors'
import { StudentsItemType } from '@src/store/ducks/students/types'
import { isArray } from '@src/lib/utils'
import { Filters } from '@src/components/Table/Filters/Filters'
import { ReactComponent as Pencil } from '@src/assets/pencil.svg'
import { TableFiltersType } from '@src/types/tableFilters'
import { SearchInput } from '@src/components/common/SearchInput/SearchInput'
import { AllowedTo } from '@src/components/common/AllowedTo/AllowedTo'
import { Role } from '@src/types/user'
import { selectUser } from '@src/store/ducks/user/selectors'
import style from './Students.module.scss'

export const Students = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const me = useSelector(selectUser)
  const students = useSelector(selectStudentsList)
  const isLoading = useSelector(selectStudentsIsLoading)
  const paginationMeta = useSelector(selectStudentsMeta)
  const filtersData = useSelector(selectStudentsFilters)
  const isLoadingFilters = useSelector(selectStudentsIsLoadingFilters)
  const isLoadingExport = useSelector(selectStudentsIsLoadingExport)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [editStatusOpen, setEditStatusOpen] = useState<null | number>(null)
  const [params, setParams] = useState({
    searchValue: '',
    page: 1,
    filters: {},
  })

  const onVisibleStatusChange = useCallback((id: number, visible: boolean) => {
    if ([Role.ADMIN, Role.ORGANIZER].includes(me.roleId)) {
      setEditStatusOpen(visible ? id : null)
    }
  }, [me.roleId])

  const handleStatus = useCallback((id: number, status: string) => {
    dispatch(updateStatus(id, status))
    setEditStatusOpen(null)
  }, [dispatch])

  const columns = useMemo<ColumnProps<StudentsItemType>[]>(() => [
    {
      title: t('students.columns.name'),
      dataIndex: 'fullName',
      key: 'surname',
      sorter: { multiple: 1 },
      className: 'blue',
      width: '30%',
      /* onCell: (record) => ({
        title: t('students.columns.name'),
        record,
        editable: true,
        dataIndex: 'fullName',
        handleSave: (id: number, dataIndex: string, value: string) => {
          dispatch(updateStudent(id, { fullName: value }))
        },
        loading: updateLoading.fullName,
      }), */
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: { multiple: 1 },
    },
    {
      title: t('students.columns.date'),
      dataIndex: 'createdAt',
      key: 'created_at',
      sorter: { multiple: 1 },
      render: (_, record) => moment(record.createdAt).format('DD MMMM YYYY'),
    },
    {
      title: t('students.columns.status'),
      dataIndex: 'status',
      key: 'student_status_id',
      sorter: { multiple: 1 },
      render: (_, record) => (
        <Popover
          content={(
            <div className={style.status_select}>
              <button
                type="button"
                onClick={() => handleStatus(record.user.id, 'active')}
              >
                {t('user.statuses.active')}
              </button>
              <button
                type="button"
                onClick={() => handleStatus(record.user.id, 'notActive')}
              >
                {t('user.statuses.notActive')}
              </button>
            </div>
          )}
          trigger="click"
          placement="bottom"
          visible={editStatusOpen === record.id}
          onVisibleChange={(visible) => onVisibleStatusChange(record.id, visible)}
        >
          <div className={`user_status user_status--${record.user.status.code}`}>{record.user.status.title}</div>
        </Popover>

      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          <NavLink className={cn(style.actions_item)} to={`profile/${record.user.id}`}>
            {[Role.ADMIN, Role.ORGANIZER, Role.SECRETARY].includes(me.roleId) ? (
              <Pencil />
            ) : (
              <EyeOutlined />
            )}
          </NavLink>
          {/*        <button
            className={cn(style.actions_item, style.actions_item__delete)}
            aria-label="delete"
            type="button"
            onClick={() => { dispatch(deleteStudent([record.user.id])) }}
          >
            <DeleteOutlined />
          </button> */}
        </div>
      ),
    },
  ], [t, editStatusOpen, handleStatus, onVisibleStatusChange, me.roleId])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'rangePicker',
      placeholder: [t('students.filters.dateFrom'), t('students.filters.dateTo')],
      dataIndex: 'date',
    },
    {
      type: 'select',
      placeholder: t('students.filters.status'),
      dataIndex: 'status',
      width: '150px',
      options: filtersData.statuses.map((item) => ({ value: item.id, label: item.title })),
    },
    {
      type: 'select',
      placeholder: t('students.filters.course'),
      dataIndex: 'course',
      showSearch: true,
      width: '200px',
      options: filtersData.courses.map((item) => ({ value: item.id, label: item.name })),
    },
    {
      type: 'select',
      placeholder: t('students.filters.stream'),
      dataIndex: 'calendar',
      showSearch: true,
      width: '200px',
      options: filtersData.calendars.map((item) => (
        { value: item.id, label: `${item.course.name} - ${moment(item.startDate).format('DD.MM')}` }
      )),
    },
    {
      type: 'select',
      placeholder: t('students.filters.module'),
      dataIndex: 'module',
      width: '130px',
      options: filtersData.modules.map((item) => ({ value: item.id, label: item.name })),
    },
    {
      type: 'select',
      placeholder: t('students.filters.type'),
      dataIndex: 'type',
      width: '150px',
      options: filtersData.types.map((item) => ({ value: item.id, label: item.title })),
    },
  ], [filtersData, t])

  useEffect(() => {
    dispatch(setBreadcrumbs([{ url: '/', title: t('controlPanel.title') }, { title: t('students.title') }]))
  }, [dispatch, t])

  useEffect(() => {
    dispatch(getStudents(params.page, params.filters, params.searchValue))
  }, [dispatch, params])

  // выбор строк
  /* const handleRowSelection = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  } */

  // сортировка
  const handleTableChange = (
    pagination: TablePaginationConfig,
    _: any,
    sorter: SorterResult<StudentsItemType> | SorterResult<StudentsItemType>[],
  ) => {
    const sorterData: Array<{ columns: Key | readonly Key[], order: string }> = []
    if (isArray(sorter)) {
      sorter.forEach((item) => {
        if (item.order && item.columnKey) {
          sorterData.push({
            columns: item.columnKey,
            order: item.order,
          })
        }
      })
    } else if (sorter.order && sorter.columnKey) {
      sorterData.push({
        columns: sorter.columnKey,
        order: sorter.order,
      })
    }
    setParams((prevState) => ({
      ...prevState,
      page: pagination.current || 1,
      filters: { ...prevState.filters, sorter: sorterData },
    }))
  }

  // удаление
  const handleDelete = () => {
    dispatch(deleteStudent(selectedRowKeys))
    setSelectedRowKeys([])
  }

  // фильтрация
  const handleFilters = (e: Array<{ columns: string, value: any }>) => {
    setParams((prevState) => ({
      ...prevState,
      filters: { ...prevState.filters, filters: e },
    }))
  }

  // поиск
  const handleSearch = useCallback((value: string) => {
    setParams((prevState) => ({
      ...prevState,
      searchValue: value,
      page: 1,
    }))
  }, [])

  return (
    <div>
      <div className={style.header}>
        <h1 className="page_title">{t('students.title')}</h1>
        <AllowedTo roles={[Role.ADMIN, Role.SECRETARY, Role.ORGANIZER]}>
          <div>
            <NavLink to="/students/create">
              <Button type="primary">{t('students.create')}</Button>
            </NavLink>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                dispatch(studentsExport())
              }}
              type="primary"
              loading={isLoadingExport}
            >
              Экспорт
            </Button>
          </div>
        </AllowedTo>
      </div>

      <SearchInput placeholder={t('students.search')} name="term" onChange={handleSearch} className={style.search} />

      <Filters
        filters={filtersList}
        onChange={handleFilters}
        onOpen={() => dispatch(getFiltersStudents())}
        loading={isLoadingFilters}
        onClose={() => setParams((prevState) => ({ ...prevState, filters: { ...prevState.filters, filters: {} } }))}
      />
      <Table<StudentsItemType>
        dataSource={students}
      //  rowSelection={{ selectedRowKeys, onChange: handleRowSelection }}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
        rowClassName={() => 'editable-row'}
        scroll={{ x: 'max-content' }}
        pagination={{
          total: paginationMeta.totalCount,
          current: paginationMeta.currentPage,
          pageSize: paginationMeta.perPage,
          showSizeChanger: false,
        }}
      />
      <div>
        {!!selectedRowKeys.length && (
          <Button
            onClick={handleDelete}
            size="small"
            icon={<DeleteOutlined />}
          >
            {t('paymentHistory.delete')}
          </Button>
        )}
      </div>
    </div>
  )
}
