import {
  FC, ForwardRefExoticComponent, useCallback, useMemo, useState,
  ReactNode,
} from 'react'
import { Input } from 'antd'
import { useField } from 'formik'
import cn from 'classnames'

import style from './FieldFormik.module.scss'

type FieldFormikProps = {
  name: string
  Prefix?: ForwardRefExoticComponent<any>
  placeholder?: string
  type?: string
  required?: boolean
  styleInline?: object
  autoSize?: {
    minRows: number
    maxRows: number
  }
  addonBefore?: ReactNode
  autoFocus?: boolean
  disabled?: boolean
  readonly?: boolean
  autoComplete?: string
  onChange?: (e: any) => void
}

export const FieldFormik: FC<FieldFormikProps> = ({
  Prefix,
  autoComplete,
  readonly,
  disabled,
  autoFocus,
  addonBefore,
  styleInline,
  placeholder,
  name,
  type = 'text',
  required,
  autoSize,
  onChange,
}) => {
  const [field, meta] = useField(name)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const isError = useMemo(() => !isFocused && meta.error && meta.touched, [isFocused, meta.error, meta.touched])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])
  const handleBlur = useCallback((e) => {
    setIsFocused(false)
    field.onBlur(e)
  }, [field])

  const handleChange = useCallback((e) => {
    field.onChange(e)

    if (onChange) {
      onChange(e)
    }
  }, [field, onChange])

  const fieldComponent = useMemo(() => {
    switch (type) {
      case 'password':
        return (
          <Input.Password
            {...field}
            prefix={Prefix && <Prefix style={{ color: '#6A1D51' }} />}
            type={type}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
            addonBefore={addonBefore}
            autoFocus={autoFocus}
            disabled={disabled || readonly}
            autoComplete={autoComplete}
          />
        )
      case 'textarea':
        return (
          <Input.TextArea
            {...field}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
            autoSize={autoSize}
            autoFocus={autoFocus}
            disabled={disabled || readonly}
            autoComplete={autoComplete}
          />
        )
      default:
        return (
          <Input
            {...field}
            prefix={Prefix && <Prefix style={{ color: '#6A1D51' }} />}
            type={type}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
            addonBefore={addonBefore}
            autoFocus={autoFocus}
            disabled={disabled || readonly}
            autoComplete={autoComplete}
            onChange={handleChange}
          />
        )
    }
  },
  [
    Prefix, addonBefore, autoComplete, autoFocus,
    autoSize, disabled, field, handleBlur, handleFocus,
    isError, readonly, styleInline, type,
  ])

  return (
    <div className={style.wrapper}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={cn(style.label, readonly && !disabled && style.readonly)}>
        {fieldComponent}
        <div
          className={
            cn(
              style.placeholder,
              style.placeholder__textarea,
              Prefix && style.placeholder_icon, meta.value && style.placeholder_active,
            )
          }
        >
          {placeholder}
          {required && <span className={style.placeholder_required}>*</span>}
        </div>

        {isError && <div className={style.error}>{meta.error}</div>}
      </label>
    </div>

  )
}
