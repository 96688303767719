import { api } from '@src/api/api'
import { User } from '@src/types/user'
import { DocumentsLinksType } from '@src/store/ducks/auth/reducer'

type loginResponse = {
  accessToken: string,
  tokenType: string,
  expiresIn: number,
  user: User
}

export const authApi = {
  login(email: string, password: string) {
    return api.post<loginResponse>('/v1/auth/login', { email, password })
  },
  documentsLinks() {
    return api.get<DocumentsLinksType>('/v1/links')
  },
  register(data: any) {
    return api.post('/v1/auth/register', data)
  },
  passwordEmail(email: string) {
    return api.post('/v1/password/email', { email })
  },
  checkToken(token: string, email: string) {
    return api.get('/v1/password/check_token', { token, email })
  },
  resetPassword(token: string, password: string, email: string) {
    return api.post('/v1/password/reset', { token, password, email })
  },
  logout() {
    return api.post('/v1/auth/logout')
  },
  changePassword(data: { oldPassword: string, newPassword: string }) {
    return api.post('/v1/users/profile/reset-password', data)
  },
  checkEmail(email: any) {
    return api.get<boolean>('/v1/check-email', { email })
  },
}
