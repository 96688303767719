import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { Form, Formik } from 'formik'
import {
  Button, Col, DatePicker, Popconfirm, Row,
} from 'antd'

import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { AppDispatch } from '@src/store/store'
import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { selectPayloadHistoryFormData, selectPayloadHistoryIsLoading } from '@src/store/ducks/paymentHistory/selectors'
import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import { createPayload, findPayloadInvoices } from '@src/store/ducks/paymentHistory/thunks'
import { resetPayload } from '@src/store/ducks/paymentHistory/reducer'
import * as Yup from 'yup'
import style from './PaymentCreate.module.scss'

export const PaymentCreate = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const params = useParams<{ id?: string }>()
  const formData = useSelector(selectPayloadHistoryFormData)
  const isLoading = useSelector(selectPayloadHistoryIsLoading)
  const history = useHistory()

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { url: '/', title: t('controlPanel.title') },
      { url: '/payment-history', title: t('paymentHistory.title') },
      { title: t('paymentCreate.title') },
    ]))
  }, [dispatch, t])

  useEffect(() => () => { dispatch(resetPayload()) }, [dispatch])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      invoiceId: Yup.number().nullable().required(t('form.errors.required')),
      amount: Yup.string().required(t('form.errors.required')),
      form: Yup.string().required(t('form.errors.required')),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    invoiceId: null,
    amount: '',
    form: '',
    status: null,
    paymentDate: null,
  }), [])

  const handleSearch = useMemo(() => _.debounce((value: string) => {
    if (value.length > 2) dispatch(findPayloadInvoices(value))
  }, 1000), [dispatch])

  const successCallback = useCallback(() => {
    history.push('/payment-history')
  }, [history])

  return (
    <div>
      <h1 className="page_title">
        {params.id ? t('paymentCreate.titleEdit') : t('paymentCreate.title')}
      </h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => { dispatch(createPayload(values, successCallback)) }}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values, submitForm }) => (
          <Form className={style.form}>
            <Row gutter={[20, 0]}>
              <Col xs={24} md={12}>
                <SelectFormik
                  name="invoiceId"
                  showSearch
                  onSearch={(e) => { handleSearch(`${e}`) }}
                  placeholder={t('paymentCreate.invoices')}
                  options={formData.invoices.map((item) => ({ value: item.id, label: item.number }))}
                />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="amount" placeholder={t('paymentCreate.amount')} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="form" placeholder={t('paymentCreate.form')} />
              </Col>
              <Col xs={24} md={12}>
                <DatePicker
                  placeholder={t('paymentCreate.date')}
                  value={values.paymentDate}
                  onChange={(e) => setFieldValue('paymentDate', e)}
                />
              </Col>
            </Row>

            <Popconfirm
              title={t('paymentCreate.popconfirm.title')}
              okText={t('paymentCreate.popconfirm.ok')}
              cancelText={t('paymentCreate.popconfirm.cancel')}
              onConfirm={submitForm}
              disabled={isLoading}
            >
              <Button type="primary" htmlType="button" loading={isLoading}>
                {t('paymentCreate.create')}
              </Button>
            </Popconfirm>
          </Form>
        )}
      </Formik>
    </div>
  )
}
