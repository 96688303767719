import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import 'antd/dist/antd.less'

import './style/index.scss'
import App from '@src/App'
import reportWebVitals from './reportWebVitals'
import { store } from './store/store'
import './locales'

ReactDOM.render(
  /* <React.StrictMode> */
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>, /* </React.StrictMode> */
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
