import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResponseMetaType } from '@src/api/api'
import { MaterialsItemType, MaterialsTypesType } from './types'

export const initialState = {
  list: [] as MaterialsItemType[],
  meta: {
    currentPage: 1,
    lastPage: 0,
    perPage: 0,
    totalCount: 0,
  },
  types: [] as MaterialsTypesType[],
  isLoading: false,
  isLoadingCreate: false,
  deleteLoading: [] as Array<number>,
  editData: null as MaterialsItemType | null,
  editNotFound: false,
}

const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setMaterialsList(state, { payload }: PayloadAction<MaterialsItemType[]>) {
      state.list = payload
    },
    setMaterialsMeta(state, { payload }: PayloadAction<ResponseMetaType>) {
      state.meta = payload
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload
    },
    setIsLoadingCreate(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingCreate = payload
    },
    setTypes(state, { payload }: PayloadAction<MaterialsTypesType[]>) {
      state.types = payload
    },
    addDeleteLoading(state, { payload }: PayloadAction<number>) {
      state.deleteLoading.push(payload)
    },
    removeDeleteLoading(state, { payload }: PayloadAction<number>) {
      state.deleteLoading = state.deleteLoading.filter((item) => item !== payload)
    },
    setEditData(state, { payload }: PayloadAction<MaterialsItemType>) {
      state.editData = payload
    },
    setEditNotFound(state, { payload }: PayloadAction<boolean>) {
      state.editNotFound = payload
    },
    resetCreate(state) {
      state.editData = null
      state.editNotFound = false
    },
  },
})

export type MaterialsStateType = typeof initialState

export const {
  setMaterialsList, setMaterialsMeta, setIsLoading, setTypes, setIsLoadingCreate,
  addDeleteLoading, removeDeleteLoading, setEditData, setEditNotFound, resetCreate,
} = materialsSlice.actions

export const materialsReducer = materialsSlice.reducer
