export enum LessonTypeEnum {
  INDIVIDUAL = 'individual',
  GROUP = 'group'
}

export type LessonType = {
  link?: string
  id: string
  type: LessonTypeEnum
  start: string
  teacher: string
  teacherId: number
  lessonDuration: string
  isFinished: boolean
}
