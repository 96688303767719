export enum CommunicationOptionsType {
  MESSENGER = 1,
  EMAIL = 2,
  PHONE = 3
}

export const communicationOptions: Array<{ value: CommunicationOptionsType, label: any }> = [
  {
    value: CommunicationOptionsType.MESSENGER,
    label: 'communications.messenger',
  },
  {
    value: CommunicationOptionsType.EMAIL,
    label: 'communications.email',
  },
  {
    value: CommunicationOptionsType.PHONE,
    label: 'communications.phone',
  },
]
