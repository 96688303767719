import { Checkbox } from 'antd'
import { FC, useCallback } from 'react'
import { useField } from 'formik'
import cn from 'classnames'

import './checkboxFormik.scss'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

type CheckboxFormikProps = {
  name: string
  className?: string
  onChange?: (e: CheckboxChangeEvent) => void
}

export const CheckboxFormik: FC<CheckboxFormikProps> = ({
  children, name, className, onChange,
}) => {
  const [field] = useField({ name, type: 'checkbox' })

  const handleChange = useCallback((e: CheckboxChangeEvent) => {
    field.onChange(e)
    if (onChange) onChange(e)
  }, [field, onChange])

  return (
    <Checkbox
      {...field}
      onChange={handleChange}
      name={name}
      className={cn('checkbox_formik', className)}
    >
      {children}
    </Checkbox>
  )
}
