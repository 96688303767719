import {
  FC, forwardRef, Ref, useMemo,
} from 'react'
import { FormikProps } from 'formik/dist/types'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'

import { selectProfileUser } from '@src/store/ducks/profile/selectors'
import style from '@src/components/Profile/ContactInfo/ContactInfo.module.scss'
import { useCountries } from '@src/hooks/useCountries'
import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { PhoneFieldFormik } from '@src/components/common/PhoneFieldFormik/PhoneFieldFormik'
import { saveContactInfo } from '@src/store/ducks/profile/thunks'
import { AppDispatch } from '@src/store/store'
import { CountrySelect } from '@src/components/common/CountrySelect/CountrySelect'
import { TimezoneSelect } from '@src/components/common/TimezoneSelect/TimezoneSelect'
import { useTimezones } from '@src/hooks/useTimezones'

type AdminProps = {
  isEdit: boolean
  ref: Ref<FormikProps<any>>
}

export const Admin: FC<AdminProps> = forwardRef(({ isEdit }, ref) => {
  const { t } = useTranslation()
  const user = useSelector(selectProfileUser)
  const countries = useCountries()
  const dispatch = useDispatch<AppDispatch>()
  const timezones = useTimezones()

  const initialValues = useMemo(() => ({
    baseInfo: {
      email: user.email,
      phone: user.phone,
    },
    fullInfo: {
      name: user.fullInfo.name,
      surname: user.fullInfo.surname,
      country: user.fullInfo.country,
      city: user.fullInfo.city,
      index: user.fullInfo.index,
      address: user.fullInfo.address,
      timezone: user.fullInfo.timezone,
    },
  }), [user])

  return (
    <div>
      {isEdit ? (
        <Formik
          initialValues={initialValues}
          onSubmit={(data) => {
            dispatch(saveContactInfo(data))
          }}
          innerRef={ref}
        >
          {() => (
            <Form>
              <Row gutter={[60, 0]}>
                <Col span={16}>
                  <h2>{t('profile.contactInfo.infoAdmin')}</h2>
                  <Row gutter={[20, 0]}>
                    <Col span={12}>
                      <FieldFormik name="fullInfo.name" placeholder={t('profile.contactInfo.name')} />
                    </Col>
                    <Col span={12}>
                      <FieldFormik name="fullInfo.surname" placeholder={t('profile.contactInfo.surname')} />
                    </Col>
                    <Col span={12}>
                      <FieldFormik name="baseInfo.email" placeholder="E-mail" />
                    </Col>
                    <Col span={12}>
                      <PhoneFieldFormik name="baseInfo.phone" placeholder={t('profile.contactInfo.phone')} />
                    </Col>
                    <Col span={12}>
                      <CountrySelect
                        name="fullInfo.country"
                        placeholder={t('profile.contactInfo.country')}
                      />
                    </Col>
                    <Col span={12}>
                      <FieldFormik name="fullInfo.city" placeholder={t('profile.contactInfo.city')} />
                    </Col>
                    <Col span={12}>
                      <FieldFormik name="fullInfo.index" placeholder={t('profile.contactInfo.index')} />
                    </Col>
                    <Col span={12}>
                      <FieldFormik name="fullInfo.address" placeholder={t('profile.contactInfo.address')} />
                    </Col>
                    <Col span={24}>
                      <TimezoneSelect name="fullInfo.timezone" placeholder={t('profile.contactInfo.timezone')} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      ) : (
        <Row gutter={[30, 30]}>
          <Col span={12}>
            <div className={style.section}>
              <Row gutter={[15, 36]}>
                <Col span={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.name')}</div>
                  <div className={style.item_content}>{user.fullInfo.name}</div>
                </Col>
                <Col span={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.surname')}</div>
                  <div className={style.item_content}>{user.fullInfo.surname}</div>
                </Col>
                <Col span={12}>
                  <div className={style.item_title}>E-mail</div>
                  <div className={style.item_content}>{user.email}</div>
                </Col>
                <Col span={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.phone')}</div>
                  <div className={style.item_content}>{user.phone}</div>
                </Col>
                <Col span={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.country')}</div>
                  <div className={style.item_content}>
                    {countries.find((item) => item.alpha3 === user.fullInfo.country)?.name || user.fullInfo.country}
                  </div>
                </Col>
                <Col span={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.city')}</div>
                  <div className={style.item_content}>{user.fullInfo.city}</div>
                </Col>
                <Col span={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.index')}</div>
                  <div className={style.item_content}>{user.fullInfo.index}</div>
                </Col>
                <Col span={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.address')}</div>
                  <div className={style.item_content}>{user.fullInfo.address}</div>
                </Col>
                <Col span={24}>
                  <div className={style.item_title}>{t('profile.contactInfo.timezone')}</div>
                  <div className={style.item_content}>
                    {timezones.find((item) => item.value === user.fullInfo.timezone)?.label}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
})
