import {
  FC, useMemo, forwardRef, Ref,
} from 'react'
import {
  Alert, Button, Col, Row,
} from 'antd'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { InfoCircleOutlined } from '@ant-design/icons'

import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { FormikProps } from 'formik/dist/types'
import { useDispatch, useSelector } from 'react-redux'
import { saveWorksheet } from '@src/store/ducks/profile/thunks'
import { selectProfileUser } from '@src/store/ducks/profile/selectors'
import style from './Worksheet.module.scss'

type WorksheetProps = {
  isEdit: boolean
  ref: Ref<FormikProps<any>>
  isMe: boolean
}

export const Worksheet: FC<WorksheetProps> = forwardRef(({ isEdit, isMe }, ref) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectProfileUser)

  const initialValues = useMemo(() => ({
    userId: user.id,
    isStudiedFrench: user.worksheet.isStudiedFrench,
    goals: user.worksheet.goals,
    oftenStudy: user.worksheet.oftenStudy,
    comment: user.worksheet.comment,
    vk: user.worksheet.vk,
    facebook: user.worksheet.facebook,
    viber: user.worksheet.viber,
    whatsApp: user.worksheet.whatsApp,
    telegram: user.worksheet.telegram,
    nativeLanguage: user.worksheet.nativeLanguage || [],
  }), [user])

  return (
    <div>
      <div>
        <Formik<typeof initialValues>
          onSubmit={(data) => { dispatch(saveWorksheet(data)) }}
          initialValues={initialValues}
          innerRef={ref}
        >
          {({ values, setFieldValue }) => (
            <>
              {isMe && (!values.comment || !values.goals || !values.oftenStudy || !values.nativeLanguage.length
                || (!values.facebook && !values.telegram && !values.vk && !values.viber && !values.whatsApp)) && (
                <Alert
                  message={t('profile.worksheet.info')}
                  type="info"
                  showIcon
                  icon={<InfoCircleOutlined />}
                  className={style.alert}
                />
              )}
              <Row gutter={[30, 0]} className={style.container}>
                <Col xs={24} md={24} xl={12}>
                  <div className={style.section}>
                    <h2 style={{ marginBottom: 28 }}>{t('profile.worksheet.nativeLang')}</h2>
                    {values.nativeLanguage.map((item, i) => (
                      <FieldFormik
                        autoFocus={!values.nativeLanguage[i]}
                      // eslint-disable-next-line react/no-array-index-key
                        key={`nativeLanguage[${i}]`}
                        name={`nativeLanguage[${i}]`}
                        readonly={!isEdit}
                      />
                    ))}
                    {isEdit && (
                    <button
                      className={style.add_native_lang}
                      type="button"
                      onClick={() => { setFieldValue('nativeLanguage', [...values.nativeLanguage, '']) }}
                    >
                      {t('profile.worksheet.addNativeLang')}
                    </button>
                    )}

                    <h2>{t('profile.worksheet.isStudiedFrench.title')}</h2>
                    <div className={style.studiedFrench}>
                      <Button
                        type={values.isStudiedFrench ? 'primary' : 'default'}
                        onClick={() => isEdit && setFieldValue('isStudiedFrench', true)}
                      >
                        {t('profile.worksheet.isStudiedFrench.yes')}
                      </Button>
                      <Button
                        type={!values.isStudiedFrench ? 'primary' : 'default'}
                        onClick={() => isEdit && setFieldValue('isStudiedFrench', false)}
                      >
                        {t('profile.worksheet.isStudiedFrench.no')}
                      </Button>
                    </div>

                    <h2>{t('profile.worksheet.goals')}</h2>
                    <FieldFormik
                      readonly={!isEdit}
                      name="goals"
                      type="textarea"
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      styleInline={{ margin: '18px 0' }}
                    />

                    <h2>{t('profile.worksheet.oftenStudy')}</h2>
                    <FieldFormik
                      readonly={!isEdit}
                      name="oftenStudy"
                      type="textarea"
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      styleInline={{ margin: '46px 0 0' }}
                    />
                  </div>
                </Col>
                <Col xs={24} md={24} xl={12}>
                  <div className={style.section}>
                    <h2 style={{ marginBottom: 35 }}>{t('profile.worksheet.social.title')}</h2>
                    <FieldFormik
                      readonly={!isEdit}
                      name="vk"
                      addonBefore={<div className={style.social_before}>{t('profile.worksheet.social.vk')}</div>}
                    />
                    <FieldFormik
                      readonly={!isEdit}
                      name="facebook"
                      addonBefore={(
                        <div className={style.social_before}>
                          {t('profile.worksheet.social.facebook')}
                        </div>
                      )}
                    />
                    <FieldFormik
                      readonly={!isEdit}
                      name="viber"
                      addonBefore={<div className={style.social_before}>{t('profile.worksheet.social.viber')}</div>}
                    />
                    <FieldFormik
                      readonly={!isEdit}
                      name="whatsApp"
                      addonBefore={(
                        <div className={style.social_before}>
                          {t('profile.worksheet.social.whatsApp')}
                        </div>
                      )}
                    />
                    <FieldFormik
                      readonly={!isEdit}
                      name="telegram"
                      addonBefore={(
                        <div className={style.social_before}>
                          {t('profile.worksheet.social.telegram')}
                        </div>
                      )}
                    />
                  </div>
                  <div className={style.section}>
                    <h2>{t('profile.worksheet.comment')}</h2>
                    <FieldFormik
                      readonly={!isEdit}
                      name="comment"
                      type="textarea"
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      styleInline={{ margin: '20px 0 0' }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </div>
    </div>
  )
})
