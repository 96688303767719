import {
  MaterialsCreateType,
  MaterialsItemType,
  MaterialsTypesType,
  MaterialsUpdateType,
} from '@src/store/ducks/materials/types'
import { api, GetItemsType } from './api'

export const materialsApi = {
  getList(page: number, term?:string, typeId?: number) {
    return api.get<GetItemsType<MaterialsItemType>>('/v1/materials', { page, term, typeId })
  },
  getOne(materialId: number) {
    return api.get<MaterialsItemType>('/v1/materials/show', { materialId })
  },
  getTypes() {
    return api.get<MaterialsTypesType[]>('/v1/materials/types')
  },
  create(data: MaterialsCreateType) {
    return api.post('/v1/materials/store', data)
  },
  delete(materialId: number) {
    return api.del('/v1/materials/delete', { materialId })
  },
  update(data: MaterialsUpdateType) {
    return api.put('/v1/materials/update', data)
  },
}
