import axios, { Method, ResponseType } from 'axios'
import moment from 'moment'
import { keysToCamelCase } from '@src/lib/utils'
import { store } from '@src/store/store'
import { logout } from '@src/store/ducks/auth/thunks'
import { formatMinute } from '@src/lib/time'

const getNewToken = async () => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/refresh`, {})
    return true
  } catch (err) {
    if (err?.response?.status === 401) {
      store.dispatch(logout())
    }
    return Promise.reject(err)
  }
}

type CallApiType = {
  url: string
  data?: object
  params?: object
  headers?: object
  responseType?: ResponseType
  method: Method
}

const callApi = async ({
  url, method, data, params = {}, headers = {}, responseType,
}:CallApiType) => {
  const config = {
    url,
    method,
    params,
    data,
    responseType,
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    headers: {
      ...axios.defaults.headers.common,
      'Content-Type': 'application/json',
      lang: document.documentElement.lang,
      timezone: formatMinute(moment.tz(moment.tz.guess(true)).format('Z')),
      ...headers,
    },
  }
  try {
    const response = await axios(config)

    if (responseType) {
      return response.data
    }

    return keysToCamelCase(response.data)
  } catch (err) {
    if (err?.response?.status === 401) {
      await getNewToken()

      return new Promise((resolve, reject) => {
        axios.request(err.config).then((response) => {
          resolve(keysToCamelCase(response.data))
        }).catch((error) => {
          if (err?.response?.status === 401) {
            store.dispatch(logout())
          }
          reject(error)
        })
      })
    }
    return Promise.reject(err)
  }
}

export const api = {
  async get<T = any>(url: string, params?: object, headers?: object, responseType?: ResponseType): Promise<T> {
    return callApi({
      url, params, headers, responseType, method: 'GET',
    })
  },
  async post<T = any>(url: string, data?: object, params?: object, headers?: object): Promise<T> {
    return callApi({
      url, data, params, headers, method: 'POST',
    })
  },
  async put<T = any>(url: string, data?: object, params?: object, headers?: object): Promise<T> {
    return callApi({
      url, data, params, headers, method: 'PUT',
    })
  },
  async del<T = any>(url: string, params?: object, headers?: object): Promise<T> {
    return callApi({
      url, params, headers, method: 'DELETE',
    })
  },
}

export type ResponseMetaType = {
  currentPage: number
  lastPage: number
  perPage: number
  totalCount: number
}

export type GetItemsType<I, C = {}> = {
  items: Array<I>
  meta: ResponseMetaType
} & C
