export const addQuery = (query: Record<string, string>) => {
  const strGET = window.location.search.replace('?', '')
  const searchParams = new URLSearchParams(strGET)
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      searchParams.set(key, query[key] || '')
    } else {
      searchParams.delete(key)
    }
  })
  return searchParams.toString()
}
