import { RootState } from '@src/store/store'
import { initialState, ScheduleStateType } from './reducer'

const selectSchedule = (state: RootState): ScheduleStateType => state.schedule || initialState

export const selectScheduleEvents = (state: RootState) => selectSchedule(state).events
export const selectScheduleIsLoadingDelete = (state: RootState) => selectSchedule(state).isLoadingDelete
export const selectScheduleIsLoadingCrateData = (state: RootState) => selectSchedule(state).isLoadingCreateData
export const selectScheduleCreateData = (state: RootState) => selectSchedule(state).create
export const selectScheduleCreateBackError = (state: RootState) => selectSchedule(state).createBackError
export const selectScheduleIsLoading = (state: RootState) => selectSchedule(state).isLoading
export const selectScheduleIsLoadingFilters = (state: RootState) => selectSchedule(state).isLoadingFilters
export const selectScheduleFilters = (state: RootState) => selectSchedule(state).filters
export const selectScheduleMeta = (state: RootState) => selectSchedule(state).meta
export const selectScheduleIsLoadingExport = (state: RootState) => selectSchedule(state).isLoadingExport
