import { FC, useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { Select } from 'antd'
import style from '@src/components/common/FieldFormik/FieldFormik.module.scss'
import { getObjectValue } from '@src/lib/utils'

const { Option } = Select

type SelectFormikProps = {
  name: string
  options: Array<{ value: string | number, label: string }>
  placeholder?: string
  disabled?: boolean
  showSearch?: boolean
  required?: boolean
  onChange?: (value: string | number) => void
  onSearch?: (value: string | number) => void
}

export const SelectFormik: FC<SelectFormikProps> = ({
  name, options, showSearch, placeholder, disabled, required, onChange, onSearch,
}) => {
  const {
    setFieldValue, errors, touched, values,
  } = useFormikContext<any>()

  const value = useMemo(() => getObjectValue(name.split('.'), values), [name, values])
  const error = useMemo(() => getObjectValue(name.split('.'), errors), [name, errors])
  const isTouched = useMemo(() => getObjectValue(name.split('.'), touched), [name, touched])
  const isError = useMemo(() => error && isTouched, [error, isTouched])

  const handleChange = useCallback((vt: string | number) => {
    setFieldValue(name, vt)
    if (onChange) onChange(vt)
  }, [name, onChange, setFieldValue])

  return (
    <div className={style.wrapper}>
      <Select
        showSearch={showSearch}
        onSearch={onSearch}
        style={{ width: '100%' }}
        optionFilterProp="children"
        placeholder={(
          <>
            {placeholder}
            {' '}
            {required && <span className={style.placeholder_required}>*</span>}
          </>
        )}
        disabled={disabled}
        onChange={handleChange}
        value={value || null}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>{option.label}</Option>
        ))}
      </Select>
      {isError && <div className={style.error}>{error}</div>}
    </div>
  )
}
