import {
  FC, forwardRef, Ref, useMemo,
} from 'react'
import { Form, Formik, yupToFormErrors } from 'formik'
import { Col, Row } from 'antd'
import cn from 'classnames'
import { FormikProps } from 'formik/dist/types'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import style from '@src/components/Profile/ContactInfo/ContactInfo.module.scss'
import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import { saveContactInfo } from '@src/store/ducks/profile/thunks'
import { selectProfileUser } from '@src/store/ducks/profile/selectors'
import { PhoneFieldFormik } from '@src/components/common/PhoneFieldFormik/PhoneFieldFormik'
import { communicationOptions } from '@src/constants/communication'
import { CountrySelect } from '@src/components/common/CountrySelect/CountrySelect'
import { useCountries } from '@src/hooks/useCountries'
import { AllowedTo } from '@src/components/common/AllowedTo/AllowedTo'
import { Role } from '@src/types/user'
import { selectUser } from '@src/store/ducks/user/selectors'
import { TimezoneSelect } from '@src/components/common/TimezoneSelect/TimezoneSelect'
import { useTimezones } from '@src/hooks/useTimezones'

type StudentProps = {
  isEdit: boolean
  ref: Ref<FormikProps<any>>
}

export const Student: FC<StudentProps> = forwardRef(({ isEdit }, ref) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectProfileUser)
  const countries = useCountries()
  const me = useSelector(selectUser)
  const timezones = useTimezones()

  const initialValues = useMemo(() => {
    const valuesData: any = {
      userId: user.id,
      baseInfo: {
        email: user.email,
        phone: user.phone,
      },
      fullInfo: {
        name: user.fullInfo.name,
        surname: user.fullInfo.surname,
        communication: user.fullInfo.communication,
        messengers: user.fullInfo.messengers,
        /* country: user.fullInfo.country,
        city: user.fullInfo.city,
        index: user.fullInfo.index,
        address: user.fullInfo.address, */
        adminComment: user.fullInfo.adminComment,
        timezone: user.fullInfo.timezone,
      },
      billingInfo: {
        country: user.fullInfo.billing?.country ?? '',
        city: user.fullInfo.billing?.city ?? '',
        index: user.fullInfo.billing?.index ?? '',
        address: user.fullInfo.billing?.address ?? '',
      },
    }

    if (user.fullInfo.entity && me.roleId === Role.ADMIN) {
      valuesData.entityInfo = {
        companyName: user.fullInfo.entity?.companyName || '',
        name: user.fullInfo.entity?.name || '',
        surname: user.fullInfo.entity?.surname || '',
        email: user.fullInfo.entity?.email || '',
        phone: user.fullInfo.entity?.phone || '',
        communication: user.fullInfo.entity?.communication || '',
        messengers: user.fullInfo.entity?.messengers || '',
        number: user.fullInfo.entity?.number || '',
        vat: user.fullInfo.entity?.vat || '',
        address: user.fullInfo.entity?.address || '',
        city: user.fullInfo.entity?.city || '',
        index: user.fullInfo.entity?.index || '',
        country: user.fullInfo.entity?.country || '',
      }
    }

    valuesData.payerInfo = {
      name: user.fullInfo.payer.name,
      surname: user.fullInfo.payer.surname,
      email: user.fullInfo.payer.email,
      phone: user.fullInfo.payer.phone,
      communication: user.fullInfo.payer.communication,
      messengers: user.fullInfo.payer.messengers,
      country: user.fullInfo.payer.country,
      city: user.fullInfo.payer.city,
      index: user.fullInfo.payer.index,
      address: user.fullInfo.payer.address,
    }

    return valuesData
  }, [me, user])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      baseInfo: Yup.object().shape({
        email: Yup.string()
          .email(t('form.errors.email'))
          .required(t('form.errors.required')),
        phone: Yup.string()
          .min(10, t('form.errors.min', '', { count: 10 }))
          .required(t('form.errors.required')),
      }),

      fullInfo: Yup.object().shape({
        name: Yup.string()
          .required(t('form.errors.required')),
        surname: Yup.string()
          .required(t('form.errors.required')),
        /* address: Yup.string().when('$isOtherStudent', {
           is: false,
           then: Yup.string()
             .required(t('form.errors.required')),
         }).nullable(),
          country: Yup.string().when('$isOtherStudent', {
           is: false,
           then: Yup.string()
             .required(t('form.errors.required')),
         }).nullable(),
         city: Yup.string().when('$isOtherStudent', {
           is: false,
           then: Yup.string()
             .required(t('form.errors.required')),
         }).nullable(),
         index: Yup.string().when('$isOtherStudent', {
           is: false,
           then: Yup.string()
             .max(10, t('form.errors.max')),
         }).nullable(), */
      }),

      billingInfo: Yup.object().shape({
        address: Yup.string()
          .required(t('form.errors.required')),
        country: Yup.string()
          .required(t('form.errors.required')),
        city: Yup.string()
          .required(t('form.errors.required')),
        index: Yup.string()
          .max(10, t('form.errors.max')),
      }),

      payerInfo: Yup.object().shape({
        name: Yup.string().when('$isOtherStudent', {
          is: true,
          then: Yup.string().required(t('form.errors.required')),
        }),
        surname: Yup.string().when('$isOtherStudent', {
          is: true,
          then: Yup.string().required(t('form.errors.required')),
        }),
        email: Yup.string().when('$isOtherStudent', {
          is: true,
          then: Yup.string().email(t('form.errors.email')).required(t('form.errors.required')),
        }),
        phone: Yup.string().when('$isOtherStudent', {
          is: true,
          then: Yup.string().min(10, t('form.errors.min', '', { count: 10 })).required(t('form.errors.required')),
        }),
        address: Yup.string().when('$isOtherStudent', {
          is: true,
          then: Yup.string().required(t('form.errors.required')),
        }),
        country: Yup.string().when('$isOtherStudent', {
          is: true,
          then: Yup.string().required(t('form.errors.required')),
        }),
        city: Yup.string().when('$isOtherStudent', {
          is: true,
          then: Yup.string().required(t('form.errors.required')),
        }),
        index: Yup.string().when('$isOtherStudent', {
          is: true,
          then: Yup.string().max(10, t('form.errors.max')),
        }),
      }),
    })
  ), [t])

  return (
    <div>
      {isEdit ? (
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const context = {
              isOtherStudent: true,
            }

            return validationSchema
              .validate(values, { abortEarly: false, context })
              .then(() => ({}))
              .catch((err) => yupToFormErrors(err))
          }}
          onSubmit={(data) => {
            dispatch(saveContactInfo(data))
          }}
          innerRef={ref}
        >
          {() => (
            <Form>
              <Row gutter={[{ xs: 20, md: 20, xl: 60 }, 0]}>
                <Col xs={24} md={24} xl={16} className={style.infoStudent}>
                  <h2>{t('profile.contactInfo.infoStudent')}</h2>
                  <Row gutter={[20, 0]}>
                    <Col xs={24} md={24} xl={12}>
                      <FieldFormik name="fullInfo.name" placeholder={t('profile.contactInfo.name')} />
                    </Col>
                    <Col xs={24} md={24} xl={12}>
                      <FieldFormik name="fullInfo.surname" placeholder={t('profile.contactInfo.surname')} />
                    </Col>
                    <Col xs={24} md={24} xl={12}>
                      <FieldFormik name="baseInfo.email" placeholder="E-mail" />
                    </Col>
                    <Col xs={24} md={24} xl={12}>
                      <PhoneFieldFormik name="baseInfo.phone" placeholder={t('profile.contactInfo.phone')} />
                    </Col>
                    <Col xs={24} md={24} xl={12}>
                      <SelectFormik
                        options={communicationOptions.map((item) => ({ ...item, label: t(item.label) }))}
                        name="fullInfo.communication"
                        placeholder={t('profile.contactInfo.communication')}
                      />
                    </Col>
                    <Col xs={24} md={24} xl={12}>
                      <FieldFormik name="fullInfo.messengers" placeholder={t('profile.contactInfo.messengers')} />
                    </Col>
                    <Col xs={24} md={24} xl={12}>
                      <TimezoneSelect name="fullInfo.timezone" placeholder={t('profile.contactInfo.timezone')} />
                    </Col>
                    {/* {!user.fullInfo.isOtherStudent && (
                      <>
                        <Col xs={24} md={24} xl={12}>
                          <CountrySelect
                            name="fullInfo.country"
                            placeholder={t('profile.contactInfo.country')}
                          />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="fullInfo.city" placeholder={t('profile.contactInfo.city')} />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="fullInfo.index" placeholder={t('profile.contactInfo.index')} />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="fullInfo.address" placeholder={t('profile.contactInfo.billingAddress')} />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <TimezoneSelect name="fullInfo.timezone" placeholder={t('profile.contactInfo.timezone')} />
                        </Col>
                      </>
                    )} */}
                  </Row>
                </Col>
                <Col xs={24} md={24} xl={8}>
                  <h2>{t('profile.contactInfo.billingAddressTitle')}</h2>
                  <CountrySelect
                    name="billingInfo.country"
                    placeholder={t('profile.contactInfo.country')}
                  />
                  <FieldFormik name="billingInfo.city" placeholder={t('profile.contactInfo.city')} />
                  <FieldFormik name="billingInfo.index" placeholder={t('profile.contactInfo.index')} />
                  <FieldFormik name="billingInfo.address" placeholder={t('profile.contactInfo.address')} />
                </Col>
                {[Role.ADMIN, Role.ORGANIZER, Role.SECRETARY, Role.TEACHER].includes(me.roleId) && (
                  <Col xs={24} md={24} xl={16} className={style.infoStudent}>
                    <FieldFormik
                      type="textarea"
                      name="fullInfo.adminComment"
                      placeholder={t('profile.contactInfo.adminComment')}
                    />
                  </Col>
                )}
                {user.fullInfo.entity && (
                  <AllowedTo userRoles={[Role.ADMIN]} userRole={me.roleId}>
                    <Col span={24}>
                      <h2>{t('profile.contactInfo.customer')}</h2>
                      <FieldFormik name="entityInfo.companyName" placeholder={t('profile.entity.companyName')} />
                      <Row gutter={[20, 0]}>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="entityInfo.name" placeholder={t('profile.entity.name')} />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="entityInfo.surname" placeholder={t('profile.entity.surname')} />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="entityInfo.email" placeholder="E-mail" />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="entityInfo.phone" placeholder={t('profile.entity.phone')} />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <SelectFormik
                            options={communicationOptions.map((item) => ({ ...item, label: t(item.label) }))}
                            name="entityInfo.communication"
                            placeholder={t('profile.entity.communication')}
                          />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="entityInfo.messengers" placeholder={t('profile.entity.messengers')} />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="entityInfo.number" placeholder={t('profile.entity.number')} />
                        </Col>
                        <Col xs={24} md={24} xl={12}>
                          <FieldFormik name="entityInfo.vat" placeholder={t('profile.entity.vat')} />
                        </Col>
                        <Col span={24}>
                          <FieldFormik name="entityInfo.entityForm" placeholder={t('profile.entity.entityForm')} />
                        </Col>
                        <Col span={24}>
                          <FieldFormik name="entityInfo.address" placeholder={t('profile.entity.address')} />
                        </Col>
                        <Col xs={24} md={24} xl={10}>
                          <CountrySelect
                            name="entityInfo.country"
                            placeholder={t('profile.entity.country')}
                          />
                        </Col>
                        <Col xs={24} md={24} xl={7}>
                          <FieldFormik name="entityInfo.city" placeholder={t('profile.entity.city')} />
                        </Col>
                        <Col xs={24} md={24} xl={7}>
                          <FieldFormik name="entityInfo.index" placeholder={t('profile.entity.index')} />
                        </Col>
                      </Row>
                    </Col>
                  </AllowedTo>
                )}
                {true && (// user.fullInfo.isOtherStudent && (
                  <Col xs={24} md={24} xl={16}>
                    <h2>{t('profile.contactInfo.payerTitle')}</h2>
                    <Row gutter={[20, 0]}>
                      <Col xs={24} md={24} xl={12}>
                        <FieldFormik name="payerInfo.name" placeholder={t('profile.contactInfo.name')} />
                      </Col>
                      <Col xs={24} md={24} xl={12}>
                        <FieldFormik name="payerInfo.surname" placeholder={t('profile.contactInfo.surname')} />
                      </Col>
                      <Col xs={24} md={24} xl={12}>
                        <FieldFormik name="payerInfo.email" placeholder="E-mail" />
                      </Col>
                      <Col xs={24} md={24} xl={12}>
                        <PhoneFieldFormik name="payerInfo.phone" placeholder={t('profile.contactInfo.phone')} />
                      </Col>
                      <Col xs={24} md={24} xl={12}>
                        <SelectFormik
                          options={communicationOptions.map((item) => ({ ...item, label: t(item.label) }))}
                          name="payerInfo.communication"
                          placeholder={t('profile.contactInfo.communication')}
                        />
                      </Col>
                      <Col xs={24} md={24} xl={12}>
                        <FieldFormik name="payerInfo.messengers" placeholder={t('profile.contactInfo.messengers')} />
                      </Col>
                      <Col span={12}>
                        <CountrySelect
                          name="payerInfo.country"
                          placeholder={t('profile.contactInfo.country')}
                        />
                      </Col>
                      <Col xs={24} md={24} xl={12}>
                        <FieldFormik name="payerInfo.city" placeholder={t('profile.contactInfo.city')} />
                      </Col>
                      <Col xs={24} md={24} xl={12}>
                        <FieldFormik name="payerInfo.index" placeholder={t('profile.contactInfo.index')} />
                      </Col>
                      <Col xs={24} md={24} xl={12}>
                        <FieldFormik name="payerInfo.address" placeholder={t('profile.contactInfo.billingAddress')} />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Form>
          )}
        </Formik>
      ) : (
        <Row gutter={[30, 30]}>
          <Col xs={24} md={24} xl={12}>
            <div className={style.section}>
              <h2>{t('profile.contactInfo.infoStudent')}</h2>
              <Row gutter={[15, 36]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.name')}</div>
                  <div className={style.item_content}>{user.fullInfo.name}</div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.surname')}</div>
                  <div className={style.item_content}>{user.fullInfo.surname}</div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>E-mail</div>
                  <div className={style.item_content}>{user.email}</div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.phone')}</div>
                  <div className={style.item_content}>{user.phone}</div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.communication')}</div>
                  <div className={style.item_content}>
                    {t(communicationOptions.find((item) => item.value === user.fullInfo.communication)?.label)}
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.messengers')}</div>
                  <div className={style.item_content}>{user.fullInfo.messengers}</div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.timezone')}</div>
                  <div className={style.item_content}>
                    {timezones.find((item) => item.value === user.fullInfo.timezone)?.label}
                  </div>
                </Col>
                {[Role.ADMIN, Role.ORGANIZER, Role.SECRETARY, Role.TEACHER].includes(me.roleId) && (
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.adminComment')}</div>
                    <div className={style.item_content}>{user.fullInfo.adminComment}</div>
                  </Col>
                )}
                {/* {!user.fullInfo.isOtherStudent && (
                  <>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <div className={style.item_title}>{t('profile.contactInfo.country')}</div>
                      <div className={style.item_content}>
                        {countries.find((item) => item.alpha3 === user.fullInfo.country)?.name || user.fullInfo.country}
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <div className={style.item_title}>{t('profile.contactInfo.city')}</div>
                      <div className={style.item_content}>{user.fullInfo.city}</div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <div className={style.item_title}>{t('profile.contactInfo.index')}</div>
                      <div className={style.item_content}>{user.fullInfo.index}</div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <div className={style.item_title}>{t('profile.contactInfo.address')}</div>
                      <div className={style.item_content}>{user.fullInfo.address}</div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <div className={style.item_title}>{t('profile.contactInfo.timezone')}</div>
                      <div className={style.item_content}>
                        {timezones.find((item) => item.value === user.fullInfo.timezone)?.label}
                      </div>
                    </Col>
                    {[Role.ADMIN, Role.ORGANIZER, Role.SECRETARY, Role.TEACHER].includes(me.roleId) && (
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div className={style.item_title}>{t('profile.contactInfo.adminComment')}</div>
                        <div className={style.item_content}>{user.fullInfo.adminComment}</div>
                      </Col>
                    )}
                  </>
                )} */}
              </Row>
            </div>
          </Col>
          <Col xs={24} md={24} xl={12}>
            <div className={style.section}>
              <h2>{t('profile.contactInfo.billingAddressTitle')}</h2>
              <Row gutter={[15, 36]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.country')}</div>
                  <div className={style.item_content}>
                    {countries.find((item) => item.alpha3 === user.fullInfo.billing?.country)?.name
                    || user.fullInfo.billing?.country}
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.city')}</div>
                  <div className={style.item_content}>{user.fullInfo.billing?.city}</div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.index')}</div>
                  <div className={style.item_content}>{user.fullInfo.billing?.index}</div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <div className={style.item_title}>{t('profile.contactInfo.billingAddress')}</div>
                  <div className={style.item_content}>{user.fullInfo.billing?.address}</div>
                </Col>
              </Row>
            </div>
            {true && (// user.fullInfo.isOtherStudent && (
              <div className={style.section}>
                <h2>{t('profile.contactInfo.payerTitle')}</h2>
                <Row gutter={[15, 36]}>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.name')}</div>
                    <div className={style.item_content}>
                      {user.fullInfo.payer.name}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.surname')}</div>
                    <div className={style.item_content}>
                      {user.fullInfo.payer.surname}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>E-mail</div>
                    <div className={style.item_content}>
                      {user.fullInfo.payer.email}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.phone')}</div>
                    <div className={style.item_content}>
                      {user.fullInfo.payer.phone}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.communication')}</div>
                    <div className={style.item_content}>
                      {t(communicationOptions.find((item) => item.value === user.fullInfo.payer.communication)?.label)}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.messengers')}</div>
                    <div className={style.item_content}>
                      {user.fullInfo.payer.messengers}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.country')}</div>
                    <div className={style.item_content}>
                      {countries.find((item) => item.alpha3 === user.fullInfo.payer?.country)?.name
                      || user.fullInfo.payer.country}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.city')}</div>
                    <div className={style.item_content}>
                      {user.fullInfo.payer.city}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.index')}</div>
                    <div className={style.item_content}>
                      {user.fullInfo.payer.index}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <div className={style.item_title}>{t('profile.contactInfo.address')}</div>
                    <div className={style.item_content}>
                      {user.fullInfo.payer.address}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </Col>

          {user.fullInfo.entity && (
            <Col xs={24} md={24} xl={12}>
              <div className={cn(style.section, style.section__disabled)}>
                <h2>{t('profile.contactInfo.customer')}</h2>
                <Row gutter={[15, 36]}>
                  <Col xs={24} md={24} xl={10}>
                    <div className={style.item_title}>{t('profile.entity.companyName')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.companyName}</div>
                  </Col>
                  <Col xs={24} md={24} xl={7}>
                    <div className={style.item_title}>{t('profile.entity.name')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.name}</div>
                  </Col>
                  <Col xs={24} md={24} xl={7}>
                    <div className={style.item_title}>{t('profile.entity.surname')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.surname}</div>
                  </Col>
                  <Col xs={24} md={24} xl={12}>
                    <div className={style.item_title}>E-mail</div>
                    <div className={style.item_content}>{user.fullInfo.entity.email}</div>
                  </Col>
                  <Col xs={24} md={24} xl={12}>
                    <div className={style.item_title}>{t('profile.entity.phone')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.phone}</div>
                  </Col>
                  <Col xs={24} md={24} xl={12}>
                    <div className={style.item_title}>{t('profile.entity.communication')}</div>
                    <div className={style.item_content}>
                      {t(communicationOptions.find((item) => item.value === user.fullInfo?.entity?.communication)?.label)}
                    </div>
                  </Col>
                  <Col xs={24} md={24} xl={12}>
                    <div className={style.item_title}>{t('profile.entity.messengers')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.messengers}</div>
                  </Col>
                  <Col span={24}>
                    <div className={style.item_title}>{t('profile.entity.number')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.number}</div>
                  </Col>
                  {user.fullInfo.entity.vat && (
                    <Col span={24}>
                      <div className={style.item_title}>{t('profile.entity.vat')}</div>
                      <div className={style.item_content}>{user.fullInfo.entity.vat}</div>
                    </Col>
                  )}
                  <Col span={24}>
                    <div className={style.item_title}>{t('profile.entity.entityForm')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.entityForm}</div>
                  </Col>
                  <Col span={24}>
                    <div className={style.item_title}>{t('profile.entity.address')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.address}</div>
                  </Col>
                  <Col xs={24} md={24} xl={8}>
                    <div className={style.item_title}>{t('profile.entity.city')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.city}</div>
                  </Col>
                  <Col xs={24} md={24} xl={8}>
                    <div className={style.item_title}>{t('profile.entity.index')}</div>
                    <div className={style.item_content}>{user.fullInfo.entity.index}</div>
                  </Col>
                  <Col xs={24} md={24} xl={8}>
                    <div className={style.item_title}>{t('profile.entity.country')}</div>
                    <div className={style.item_content}>
                      {countries.find((item) => item.alpha3 === user.fullInfo.entity?.country)?.name
                      || user.fullInfo.entity.country}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
        </Row>
      )}
    </div>
  )
})
