import { CommunicationOptionsType } from '@src/constants/communication'

export enum Role {
  GUEST = 0,
  ADMIN = 1,
  SECRETARY = 2,
  TEACHER = 3,
  STUDENT = 4,
  ORGANIZER = 5,
}

export type CuratorType = {
  id: number
  userId: number
  name: string
  surname: string
}

export type UserStatus = {
  id: number
  code: string
  title: string
}

export type Curator = {
  dialogId?: number
  email: string
  id: number
  name: string
  phone: string
  photo: string | null
}

export type UserFullInfo = {
  adminComment: string
  timezone: string
  id: number
  name: string
  surname: string
  photo: null | string
  communication: null | number
  messengers: null | string
  country: string
  city: string
  index: null | string
  address: string
  education: string
  experience: string
  description: string
  billingId: number
  entity: {
    entityForm: string;
    address: string
    city: string
    communication: CommunicationOptionsType
    companyName: string
    country: string
    email: string
    id: number
    index: string
    messengers: string
    name: string
    number: string
    phone: string
    studentId: number
    surname: string
    vat: string

  } | null
  billing: {
    address: string
    city: string
    country: string
    index: number
  }
  isOtherStudent?: boolean
  payer: {
    address: string
    city: string
    communication?: CommunicationOptionsType
    country: string
    email: string
    id: number
    index: string
    messengers?: string
    name: string
    phone: string
    studentId: number
    surname: string
  }
  curator?: Curator
}

export type UserWorksheet = {
  comment: string
  facebook: string
  goals: string
  id: number
  isStudiedFrench: boolean
  nativeLanguage: Array<string>
  oftenStudy: string
  telegram: string
  userId: number
  viber: string
  vk: string
  whatsApp: string
}

export type User = {
  email: string
  emailVerifiedAt: null
  id: number
  phone: string
  roleId: Role
  fullInfo: UserFullInfo
  interfaceLang: string
  worksheet: UserWorksheet
}

export enum UserType {
  INDIVIDUAL = 'student',
  ENTITY = 'entity_student',
  TEACHER = 'teacher',
  SECRETARY = 'secretary',
  ORGANIZER = 'organizer',
}
