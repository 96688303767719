import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { Button } from 'antd'
import * as Yup from 'yup'

import { AppDispatch } from '@src/store/store'
import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import {
  createMaterials, getMaterialsTypes, getOneMaterial, updateMaterials,
} from '@src/store/ducks/materials/thunks'
import { FieldFormik } from '@src/components/common/FieldFormik/FieldFormik'
import { SelectFormik } from '@src/components/common/SelectFormik/SelectFormik'
import {
  selectMaterialsEditData, selectMaterialsEditNotFound,
  selectMaterialsIsLoadingCreate,
  selectMaterialsTypes,
} from '@src/store/ducks/materials/selectors'
import { useHistory, useParams } from 'react-router-dom'
import { resetCreate } from '@src/store/ducks/materials/reducer'
import style from './CreateMaterials.module.scss'

const maxDescription = 255

export const CreateMaterials = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const materialsTypes = useSelector(selectMaterialsTypes)
  const isLoading = useSelector(selectMaterialsIsLoadingCreate)
  const editData = useSelector(selectMaterialsEditData)
  const editNotFound = useSelector(selectMaterialsEditNotFound)
  const history = useHistory()
  const params = useParams<{ id?: string }>()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      title: Yup.string().required(t('form.errors.required')),
      typeId: Yup.number().nullable().required(t('form.errors.required')),
      link: Yup.string().required(t('form.errors.required')),
      description: Yup.string().max(255, t('form.errors.max', '', { count: maxDescription })),
    })
  ), [t])

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { url: '/', title: t('controlPanel.title') },
      { url: '/materials', title: t('materials.title') },
      { title: params.id ? t('createMaterials.edit') : t('createMaterials.title') }]))
  }, [dispatch, params, t])

  useEffect(() => {
    dispatch(getMaterialsTypes())
    return () => {
      dispatch(resetCreate())
    }
  }, [dispatch])

  useEffect(() => {
    if (params.id) {
      dispatch(getOneMaterial(+params.id))
    }
  }, [dispatch, params.id])

  const initialValues = useMemo(() => ({
    title: editData ? editData.title : '',
    description: editData ? editData.description : '',
    link: editData ? editData.link : '',
    typeId: editData ? editData.typeId : undefined as undefined | number,
  }), [editData])

  if (editNotFound) {
    return <div>{t('createMaterials.notFound')}</div>
  }

  return (
    <div>
      <h1 className="page_title">
        {params.id ? t('createMaterials.edit') : t('createMaterials.title')}
      </h1>

      <Formik
        initialValues={initialValues}
        onSubmit={
          (values) => {
            if (params.id) {
              dispatch(updateMaterials({ materialId: +params.id, ...values }, history))
            } else {
              dispatch(createMaterials(values, history))
            }
          }
        }
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values }) => (
          <Form className={style.form}>
            <FieldFormik name="title" placeholder={t('createMaterials.name')} />
            <FieldFormik
              name="description"
              type="textarea"
              autoSize={{ minRows: 3, maxRows: 6 }}
              placeholder={
                t(
                  'createMaterials.description',
                  '',
                  {
                    count: maxDescription - values.description.length > 0
                      ? maxDescription - values.description.length : 0,
                  },
                )
              }
            />
            <SelectFormik
              name="typeId"
              placeholder={t('createMaterials.type')}
              options={materialsTypes.map((item) => ({ value: item.id, label: item.title }))}
            />
            <FieldFormik name="link" placeholder={t('createMaterials.link')} />

            <Button loading={isLoading} type="primary" htmlType="submit">{t('createMaterials.save')}</Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
