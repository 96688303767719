import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment-timezone'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import cn from 'classnames'
import lodash from 'lodash'

import { setBreadcrumbs } from '@src/store/ducks/app/reducer'
import { AppDispatch } from '@src/store/store'
import {
  getCalendarsFilter, getFiltersInvoices, getInvoicesAll, invoicesExport,
} from '@src/store/ducks/invoices/thunks'
import { resetInvoices } from '@src/store/ducks/invoices/reducer'
import {
  selectInvoicesFilters,
  selectInvoicesIsLoading, selectInvoicesIsLoadingExport,
  selectInvoicesIsLoadingFilters,
  selectInvoicesList,
  selectInvoicesMeta,
} from '@src/store/ducks/invoices/selectors'
import { InvoicesItemType } from '@src/store/ducks/invoices/types'
import { InvoiceStatusEnum, InvoicesTypeEnum } from '@src/types/orders'
import { AllowedTo } from '@src/components/common/AllowedTo/AllowedTo'
import { Role } from '@src/types/user'
import { NavLink } from 'react-router-dom'
import { Filters } from '@src/components/Table/Filters/Filters'
import { TableFiltersType } from '@src/types/tableFilters'
import { useCountries } from '@src/hooks/useCountries'
import style from './Invoices.module.scss'

export const Invoices = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const isLoading = useSelector(selectInvoicesIsLoading)
  const paginationMeta = useSelector(selectInvoicesMeta)
  const invoices = useSelector(selectInvoicesList)
  const isLoadingFilters = useSelector(selectInvoicesIsLoadingFilters)
  const filters = useSelector(selectInvoicesFilters)
  const isLoadingExport = useSelector(selectInvoicesIsLoadingExport)
  const selectOptions = useCountries()
  const [params, setParams] = useState({
    // searchValue: '',
    page: 1,
    filters: {},
  })

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { url: '/', title: t('controlPanel.title') }, { title: t('invoices.title') },
    ]))
  }, [dispatch, t])

  useEffect(() => {
    dispatch(getInvoicesAll(params.page, params.filters))
  }, [dispatch, params])

  useEffect(() => () => { dispatch(resetInvoices()) }, [dispatch])

  const columns = useMemo<ColumnProps<InvoicesItemType>[]>(() => [
    {
      title: t('invoices.columns.date'),
      dataIndex: 'date',
      key: 'date',
      width: 180,
      render: (date, record) => {
        if (record.key === 'result') return t('invoices.total')
        return moment(date).format('DD.MM.YYYY')
      },
    },
    {
      title: t('invoices.columns.type'),
      dataIndex: 'type',
      key: 'type',
      width: 200,
      render: (type, record) => {
        if (record.key === 'result') return '-'
        switch (type) {
          case InvoicesTypeEnum.PROFORMA:
            return (
              <div className={cn(style.type, style.type__proforma)}>
                {t('invoices.types.proforma')}
              </div>
            )
          case InvoicesTypeEnum.PREPAYMENT:
            return (
              <div className={cn(style.type, style.type__prepayment)}>
                {t('invoices.types.prepayment')}
              </div>
            )
          case InvoicesTypeEnum.CHECK:
            return (
              <div className={cn(style.type, style.type__check)}>
                {t('invoices.types.check')}
              </div>
            )
          case InvoicesTypeEnum.CREDIT:
            return (
              <div className={cn(style.type, style.type__credit)}>
                {t('invoices.types.credit')}
              </div>
            )
          default: return '-'
        }
      },
    },
    {
      title: t('invoices.columns.number'),
      dataIndex: 'number',
      key: 'number',
      width: 200,
      render: (number, record) => {
        if (record.key === 'result') return '-'
        return (
          <div>
            <a
              className={style.number}
              href={`${process.env.REACT_APP_SITE_URL}/invoices/get/${record.id}`}
              download
              target="_blank"
              rel="noreferrer"
            >
              {number}
            </a>
            {record.numberFactura && (
              <a
                className={style.number}
                href={`${process.env.REACT_APP_SITE_URL}/invoices/get/${record.id}`}
                download
                target="_blank"
                rel="noreferrer"
              >
                {record.numberFactura}
              </a>
            )}
            {record.numberNota && (
              <a
                className={style.number}
                href={`${process.env.REACT_APP_SITE_URL}${record.avoirFile}`}
                download
                target="_blank"
                rel="noreferrer"
              >
                {record.numberNota}
              </a>
            )}
          </div>
        )
      },
    },
    {
      title: t('invoices.columns.proforma'),
      dataIndex: 'proforma',
      key: 'proforma',
      width: 200,
      render: (proforma) => proforma,
    },
    {
      title: t('invoices.columns.totalPriceWithNds'),
      dataIndex: 'totalPriceWithNds',
      key: 'totalPriceWithNds',
      width: 150,
      render: (totalPriceWithNds) => `${totalPriceWithNds}€`,
    },
    {
      title: t('invoices.columns.saldo'),
      dataIndex: 'saldo',
      key: 'saldo',
      width: 200,
      render: (saldo, record) => {
        if (record.type === InvoicesTypeEnum.CREDIT) return '-'
        return `${saldo}€`
      },
    },
    {
      title: t('invoices.columns.alreadyPayed'),
      dataIndex: 'alreadyPayed',
      key: 'alreadyPayed',
      width: 100,
      render: (alreadyPayed) => `${alreadyPayed}€`,
    },
    {
      title: t('invoices.columns.priceWithoutNds'),
      dataIndex: 'priceWithoutNds',
      key: 'priceWithoutNds',
      width: 200,
      render: (priceWithoutNds) => `${priceWithoutNds}€`,
    },
    {
      title: t('invoices.columns.nds'),
      dataIndex: 'nds',
      key: 'nds',
      width: 80,
      render: (nds, record) => {
        if (record.key === 'result') return '-'
        return `${nds}%`
      },
    },
    {
      title: t('invoices.columns.payer'),
      dataIndex: 'payer',
      key: 'payer',
      width: 200,
      render: (payer, record) => {
        if (record.key === 'result') return '-'
        return (payer.companyName ? payer.companyName : `${payer.surname} ${payer.name}`)
      },
    },
    {
      title: t('invoices.columns.student'),
      dataIndex: 'student',
      key: 'student',
      width: 200,
      render: (payer, record) => {
        if (record.key === 'result') return '-'
        return `${payer.surname} ${payer.name}`
      },
    },
    {
      title: t('invoices.columns.course'),
      dataIndex: 'course',
      key: 'course',
      width: 400,
      render: (payer, record) => (record.key === 'result' ? '-' : payer.name),
    },
    {
      title: t('paymentHistory.columns.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: { multiple: 1 },
      render: (status, record) => {
        if (record.key === 'result') return '-'
        switch (status) {
          case InvoiceStatusEnum.PAID:
            return (
              <div className={cn(style.status, style.status__paid)}>
                {t('invoices.status.paid')}
              </div>
            )
          case InvoiceStatusEnum.PENDING:
            return (
              <div className={cn(style.status, style.status__pending)}>
                {t('invoices.status.pending')}
              </div>
            )
          case InvoiceStatusEnum.EXPIRED:
            return (
              <div className={cn(style.status, style.status__expired)}>
                {t('invoices.status.expired')}
              </div>
            )
          case InvoiceStatusEnum.ATTEMPTSLIMIT:
            return (
              <div className={cn(style.status, style.status__limit)}>
                {t('invoices.status.limit')}
              </div>
            )
          default:
            return null
        }
      },
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      // fixed: 'right',
      render: (_, record) => {
        if (record.key === 'result') return '-'
        return (
          <>

          </>
        )
      },
    },
  ], [t])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'rangePicker',
      placeholder: [t('invoices.filters.dateFrom'), t('invoices.filters.dateTo')],
      dataIndex: 'date',
    },
    {
      type: 'select',
      placeholder: t('invoices.filters.status'),
      dataIndex: 'status',
      width: '140px',
      options: [
        {
          value: InvoiceStatusEnum.PAID,
          label: t('invoices.status.paid'),
        },
        {
          value: InvoiceStatusEnum.PENDING,
          label: t('invoices.status.pending'),
        },
        {
          value: InvoiceStatusEnum.EXPIRED,
          label: t('invoices.status.expired'),
        },
        {
          value: InvoiceStatusEnum.ATTEMPTSLIMIT,
          label: t('invoices.status.limit'),
        },
      ],
    },
    {
      type: 'select',
      placeholder: t('invoices.filters.modules'),
      dataIndex: 'module',
      width: '130px',
      options: filters.modules.map((item) => ({ value: item.id, label: item.name })),
    },
    {
      type: 'select',
      placeholder: t('invoices.filters.types'),
      dataIndex: 'type',
      width: '170px',
      options: filters.types.map((item) => ({ value: item.id, label: item.title })),
    },
    {
      type: 'select',
      placeholder: t('invoices.filters.calendar'),
      dataIndex: 'calendar',
      showSearch: true,
      width: '250px',
      onSearch: lodash.debounce((value: string) => {
        if (value.length > 1) dispatch(getCalendarsFilter(value))
      }, 1000),
      options: filters.calendars.map((item) => (
        {
          value: item.id,
          // eslint-disable-next-line max-len
          label: `${item.course.name}, ${t('schedule.flow')}: ${item.title || item.id} - ${moment(item.startDate).format('DD.MM')}`,
        }
      )),
    },
    {
      type: 'switch',
      placeholder: t('invoices.filters.saldo'),
      dataIndex: 'saldo',
    },
    {
      type: 'select',
      placeholder: t('invoices.filters.country'),
      dataIndex: 'country',
      showSearch: true,
      width: '200px',
      options: selectOptions.map((item) => ({ value: item.alpha3, label: item.name })),
    },
    {
      type: 'select',
      placeholder: t('invoices.filters.entity'),
      dataIndex: 'entity',
      width: '150px',
      options: [
        {
          value: 'individual',
          label: t('invoices.filters.entityIndividual'),
        },
        {
          value: 'entity',
          label: t('invoices.filters.entityLegal'),
        },
      ],
    },

  ], [dispatch, filters, selectOptions, t])

  const handleTableChange = useCallback((
    pagination: TablePaginationConfig,
    // _: any,
    //   sorter: SorterResult<TeacherItemType> | SorterResult<TeacherItemType>[],
  ) => {
    setParams((prevState) => ({
      ...prevState,
      page: pagination.current || 1,
    }))
  }, [])

  // фильтрация
  const handleFilters = (e: Array<{ columns: string, value: any }>) => {
    console.log(e)
    setParams((prevState) => ({
      ...prevState,
      filters: { ...prevState.filters, filters: e },
    }))
  }

  return (
    <div>
      <div className={style.header}>
        <h1 className="page_title">{t('invoices.title')}</h1>
        <AllowedTo roles={[Role.ADMIN]}>
          <div>
            <NavLink to="/invoices/create">
              <Button type="primary">{t('invoices.create')}</Button>
            </NavLink>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                dispatch(invoicesExport())
              }}
              type="primary"
              loading={isLoadingExport}
            >
              Экспорт
            </Button>
          </div>
        </AllowedTo>
      </div>

      <div>
        <Filters
          filters={filtersList}
          onChange={handleFilters}
          onOpen={() => dispatch(getFiltersInvoices())}
          loading={isLoadingFilters}
          onClose={() => setParams((prevState) => ({ ...prevState, filters: { ...prevState.filters, filters: {} } }))}
        />

        <Table<InvoicesItemType>
          className={style.table}
          dataSource={invoices}
          columns={columns}
          scroll={{ x: 'max-content' }}
          loading={isLoading}
          onChange={handleTableChange}
          pagination={{
            total: paginationMeta.totalCount,
            current: paginationMeta.currentPage,
            pageSize: paginationMeta.perPage,
            showSizeChanger: false,
          }}
        />
      </div>

    </div>
  )
}
